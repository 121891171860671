import React, { FC, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Checkbox,
  message,
  Typography,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import PropTypes, { InferProps } from "prop-types";
import _ from "lodash";
import { RowItem } from "./physician-interfaces";
import { useMutation } from "@apollo/client";
import { ERROR_MESSAGES, getPhysicianName } from "../constants";
import { ApplicantPhysicianEncounterAddModalAddEncounterDocument } from "../../generated";
const { Title, Paragraph, Text, Link } = Typography;

const ComponentPropTypes = {
  onFormCancel: PropTypes.func.isRequired,
  saveNewEncounterCallback: PropTypes.func.isRequired,
  validEmailCheck: PropTypes.func.isRequired,
};

export interface IProps {
  __typename?: "AddCEXEncounter";
  onFormCancel: () => void;
  saveNewEncounterCallback: (data: any) => void;
  validEmailCheck: (email: string) => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const AddCEXEncounter: FC<ComponentProps> = ({
  onFormCancel,
  saveNewEncounterCallback,
  validEmailCheck,
}) => {
  const [form] = Form.useForm();
  const [physicianRestOfName, setPhysicianRestOfName] = useState<string>("");
  const [physicianLastName, setPhysicianLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [checkmarkHidden, setCheckmarkHidden] = useState<boolean>(true);
  const [errormarkHidden, setErrormarkHidden] = useState<boolean>(true);
  const [emailVerificationText, setEmailVerificationText] =
    useState<string>("");
  const [certifyCheckboxChecked, setCertifyCheckboxChecked] =
    useState<boolean>(false);
  const [consentCheckboxChecked, setConsentCheckboxChecked] = useState(false);
  const [showRestOfNameInput, setShowRestOfNameInput] = useState<boolean>(true);
  const [oneNameCheckboxChecked, setOneNameCheckboxChecked] =
    useState<boolean>(false);
  const [addEncounter, { loading: addEncounterLoading }] = useMutation(
    ApplicantPhysicianEncounterAddModalAddEncounterDocument
  );
  const [errorMessage, seterrorMessage] = useState<any>();

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };
  const tailLayout = {
    wrapperCol: { offset: 9, span: 15 },
  };

  const certifyCheckboxLayout = {
    wrapperCol: { span: 24 },
  };

  const onCancel = () => {
    clearInput();
    onFormCancel();
  };

  const onFinish = () => {
    message.destroy();
    const item = {
      physicianEmail: email,
      physicianLastNameProvidedByApplicant: physicianLastName,
      physicianRestOfNameProvidedByApplicant: physicianRestOfName,
    } as RowItem;
    saveNewEncounter(item);
  };

  const onEmailChange = (value: any) => {
    setEmail(value);
    message.destroy();
    //Reset onscreen email information
    setCheckmarkHidden(true);
    setErrormarkHidden(true);
    setEmailVerificationText("");

    form
      .validateFields(["email"])
      .then(async (result) => {
        //Ant eamil validation is valid
        var validationResult = await validEmailCheck(email);
        if (!validationResult.validateEmail.isValidated) {
          setErrormarkHidden(false);
          setEmailVerificationText("Invalid email address");
        }
      })
      .catch((error) => {
        //Ant eamil validation is invalid
      });
  };

  const onOneNameCheckboxChange = (e: any) => {
    setOneNameCheckboxChecked(e.target.checked);
    if (e.target.checked) {
      setShowRestOfNameInput(false);
      setPhysicianRestOfName("");
      form.resetFields(["restOfName"]);
    } else {
      setShowRestOfNameInput(true);
    }
  };

  const clearInput = () => {
    form.resetFields();
    setCheckmarkHidden(true);
    setErrormarkHidden(true);
    setEmailVerificationText("");
    setCertifyCheckboxChecked(false);
    setConsentCheckboxChecked(false);
    setOneNameCheckboxChecked(false);
    setShowRestOfNameInput(true);
    message.destroy();
  };

  const saveNewEncounter = async (formData: RowItem) => {
    await addEncounter({
      variables: {
        input: {
          physicianEmail: formData.physicianEmail,
          physicianLastNameProvidedByApplicant:
            formData.physicianLastNameProvidedByApplicant,
          physicianRestOfNameProvidedByApplicant:
            formData.physicianRestOfNameProvidedByApplicant,
        },
      },
    })
      .then((results) => {
        saveNewEncounterCallback(results?.data?.ApplicantPortal_MiniCEX_add);
        clearInput();
      })
      .catch((saveEncError) => {
        const graphQLErrorReference =
          saveEncError?.graphQLErrors[0]?.extensions?.referenceId || "";
        const errorArray = saveEncError?.graphQLErrors;
        errorArray.forEach((error: any) => {
          message.error(
            `${_.find(ERROR_MESSAGES, { key: error.message })?.value} ${
              graphQLErrorReference
                ? "- Reference ID : " + graphQLErrorReference
                : ""
            }`,
            0
          );
        });
      });
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        onFinish={() => onFinish()}
        onClick={() => message.destroy()}
        onFinishFailed={() =>
          message.error("Some required fields are missing.")
        }
      >
        <Row>
          <Paragraph>
            Enter the name and e-mail address of your physician evaluator for
            this encounter. Please use the full name of the physician as it
            appears on the physician’s license/registration to practice
            medicine. If the physician’s name consists of one name only, check
            the box below to indicate this, and enter the physician's name in
            the Last Name field. Do not enter any characters in the Rest of Name
            field.
          </Paragraph>
          <Checkbox
            checked={oneNameCheckboxChecked}
            onChange={onOneNameCheckboxChange}
            style={{ marginBottom: 20 }}
          >
            The physician’s name consists of one name only.
          </Checkbox>
          <Paragraph>
            <i>
              Note: The e-mail address that your physician evaluator will use to
              access the Clinical Skills Evaluation and Attestation Portal to
              complete your Mini-CEX evaluation must match the e-mail address
              that you enter here.{" "}
              <Text strong>
                It is essential that you confirm the e-mail address with the
                physician evaluator before entering it here.
              </Text>
            </i>
          </Paragraph>
        </Row>
        {/* <Row style={{ marginBottom: 10 }}>
          <Col>
            <label>
              <Text strong >Evaluator Information:</Text>
            </label>
          </Col>
        </Row> */}
        <Row>
          <Col span={24}>
            <Form.Item
              label="Last Name"
              labelAlign="left"
              name="lastName"
              style={{ marginLeft: 10, marginBottom: 3, width: "300px" }}
              rules={[
                {
                  required: true,
                  message: "Please enter last name for Physician Evaluator",
                },
              ]}
            >
              <Input
                value={physicianLastName}
                onChange={(e) => setPhysicianLastName(e.target.value)}
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {showRestOfNameInput && (
              <Form.Item
                label="Rest of Name"
                labelAlign="left"
                name="restOfName"
                style={{ marginLeft: 10, marginBottom: 3, width: "300px" }}
                rules={[
                  {
                    required: showRestOfNameInput,
                    message:
                      "Please enter rest of name for Physician Evaluator",
                  },
                ]}
              >
                <Input
                  value={physicianRestOfName}
                  onChange={(e) => setPhysicianRestOfName(e.target.value)}
                ></Input>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="E-mail"
              labelAlign="left"
              name="email"
              style={{ marginLeft: 10, marginBottom: 0, width: "300px" }}
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid e-mail address",
                },
                {
                  required: true,
                  message: "Please enter a valid e-mail address",
                },
              ]}
            >
              <Input
                value={email}
                onChange={(e) => onEmailChange(e.target.value)}
              ></Input>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <CheckOutlined
                hidden={checkmarkHidden}
                style={{ color: "green", marginRight: "3px" }}
              />
              <CloseOutlined
                hidden={errormarkHidden}
                style={{ color: "red", marginRight: "3px" }}
              >
                This is it
              </CloseOutlined>
              <Text>{emailVerificationText}</Text>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Text strong>
            Certification by Applicant of Physician Evaluator and Clinical
            Encounter
          </Text>
        </Row>
        <Paragraph>
          Physician Evaluator:{" "}
          {getPhysicianName(physicianLastName, physicianRestOfName)}
        </Paragraph>
        <Paragraph>Evaluator E-mail Address: {email}</Paragraph>
        <Row>
          <Paragraph>
            I certify that:
            <ul style={{ listStyleType: "disc" }}>
              <li>
                The individual named here has agreed to observe me in an
                in-person clinical encounter and to provide an assessment of my
                clinical skills as demonstrated in that encounter to ECFMG using
                ECFMG’s Mini-Clinical Evaluation Exercise (Mini-CEX) for Pathway
                6.
              </li>
              <li>
                This individual has given approval for me to provide his/her
                name and e-mail address to ECFMG.
              </li>
              <li>
                The information I have provided about this individual is true
                and correct to the best of my knowledge.
              </li>
              <li>
                To the best of my knowledge, this individual currently holds a
                full, unrestricted license to practice medicine without
                supervision in the jurisdiction where the encounter will take
                place.
              </li>
              <li>
                To the best of my knowledge, this individual has held a full,
                unrestricted license to practice medicine without supervision
                for a minimum of five years.
              </li>
              <li>
                This individual has not and will not receive compensation from
                me to perform this evaluation.
              </li>
              <li>
                This individual has not and will not receive compensation from
                any third party to perform this evaluation other than what is
                provided as the individual’s salary or wage from the institution
                where the individual is employed and where the clinical
                encounter will take place.
              </li>
              <li>
                This individual is not my relative. For the purposes of this
                evaluation, “relative” is defined as a spouse, child,
                grandchild, parent, grandparent, sibling, uncle, aunt, nephew,
                niece, and/or cousin.
              </li>
              <li>
                I understand that the responses/information provided by this
                individual through ECFMG’s on-line Mini-CEX for Pathway 6 will
                be used to evaluate my application to meet the clinical and
                communication skills requirements for ECFMG Certification. I
                agree ECFMG may contact this individual to confirm or clarify
                any information provided through the Mini-CEX.
              </li>
              <li>
                I agree that I will take all steps necessary to ensure that that
                the clinical encounter is conducted in compliance with all
                applicable licensure, regulatory, and/or legal requirements,
                including obtaining permission from the patient for the
                encounter to be observed.
              </li>
              <li>
                I understand that the clinical encounter in which I am observed
                must meet ECFMG’s{" "}
                <Link
                  target="_blank"
                  href="https://www.ecfmg.org/certification-pathways/pathway-6.html#clinical-encounters"
                  rel="noreferrer"
                >
                  Criteria for Acceptable Clinical Encounters
                </Link>
                , including that the encounter must:
                <ul style={{ listStyleType: "circle", marginBottom: 0 }}>
                  <li>
                    Be a real, in-person clinical encounter. A telemedicine,
                    virtual, or standardized patient encounter will not be
                    accepted.
                  </li>
                  <li>
                    Take place in a formal clinical setting, such as an
                    outpatient office, an urgent care clinic, an emergency room,
                    or a hospital.
                  </li>
                  <li>
                    Be primary care/general practice in nature and not
                    subspecialized.
                  </li>
                  <li>
                    Focus on the diagnosis and treatment of acute and/or chronic
                    illness in a primary care setting, such as general internal
                    medicine, family medicine, pediatrics, or obstetrics and
                    gynecology, in which each of the four components of the
                    Mini-CEX (Medical Interviewing Skills, Physical Examination
                    Skills, Professionalism/Communication Skills, Clinical
                    Reasoning &amp; Judgment) can be assessed.
                  </li>
                  <li>
                    Be one for which the patient has granted permission to be
                    observed.
                  </li>
                  <li>
                    Occur after I have submitted my on-line application to
                    Pathway 6 and after the physician evaluator has accepted
                    this request for evaluation through the on-line application
                    system.
                  </li>
                </ul>
              </li>
              <li>
                I extend absolute immunity to ECFMG, a division of Intealth, and
                indemnify and hold harmless ECFMG for any of my actions, and the
                actions of my physician evaluator, whether intentional or not,
                that are a part of this clinical evaluation/Mini-CEX process.
              </li>
              <li>
                I have read and understand the ECFMG{" "}
                <Link
                  href="https://www.ecfmg.org/resources/irregular-behavior.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Policies and Procedures Regarding Irregular Behavior
                </Link>{" "}
                and agree to abide by these policies and procedures. I
                understand that, as provided in the ECFMG Policies and
                Procedures Regarding Irregular Behavior, ECFMG may find that
                submission of false information to ECFMG constitutes irregular
                behavior, which could result in actions including suspension or
                revocation of, or permanent bar to ECFMG Certification;
                suspension or removal of J-1 Visa sponsorship; and/or a report
                of a determination of irregular behavior to any organization or
                individual who, in the judgment of ECFMG, has a legitimate
                interest in such information. {"("}Organizations with a
                legitimate interest in such information include the USMLE
                Committee for Individualized Review; Federation of State Medical
                Boards of the United States; any applicable federal, state,
                local, or other relevant governmental or regulatory department
                or agency; U.S. state and international medical licensing
                authorities; and graduate medical education programs{")"}.
              </li>
            </ul>
          </Paragraph>
        </Row>
        <Row>
          <Form.Item
            {...certifyCheckboxLayout}
            name="certifyCheckbox"
            rules={[
              {
                required: !certifyCheckboxChecked,
                message:
                  "You must certify that you agree to the statements above in order to continue. ",
              },
            ]}
          >
            <Checkbox
              checked={certifyCheckboxChecked}
              onChange={(e: any) => setCertifyCheckboxChecked(e.target.checked)}
            >
              By checking this box, I certify that I have read, understood, and
              agree to the terms of the Certification by Applicant of Physician
              Evaluator and Clinical Encounter.
            </Checkbox>
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            {...certifyCheckboxLayout}
            name="consentCheckbox"
            rules={[
              {
                required: !consentCheckboxChecked,
                message:
                  "You must consent to the disclosure of certain personally identifiable information in order to continue.",
              },
            ]}
          >
            <Checkbox
              checked={consentCheckboxChecked}
              onChange={(e: any) => setConsentCheckboxChecked(e.target.checked)}
            >
              By checking this box, I consent to ECFMG’s disclosure of certain
              personally identifiable information to the physician evaluator I
              have named (name and USMLE/ECFMG ID number) to facilitate the
              physician evaluator’s ability to complete a Mini-CEX in support of
              my Pathway 6 application.
            </Checkbox>
          </Form.Item>
        </Row>
        <Row justify="end">
          <Col>
            <Button
              size="large"
              style={{ margin: 3, width: "80px" }}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <Button
              size="large"
              style={{ margin: 3 }}
              type="primary"
              htmlType="submit"
              loading={addEncounterLoading}
            >
              Save Encounter
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
