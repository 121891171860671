import React from "react";
import { Row, Col, Collapse, Result, Typography } from "antd";
const { Title, Paragraph, Text } = Typography;

const { Panel } = Collapse;

function SystemMaintenace(props: any) {
  return (
    <>
      <Row>
        <Col span={24}>
          <Result status="warning" title={""}></Result>
        </Col>
      </Row>
      <Paragraph style={{ textAlign: "center" }}>
        <Paragraph
          style={{ maxWidth: "400px", display: "inline-block" }}
          type="danger"
          // dangerouslySetInnerHTML={{ __html: props.maintenanceMessage }}
        >
          {props.maintenanceMessage}
        </Paragraph>
      </Paragraph>
    </>
  );
}

export default SystemMaintenace;
