import React, { useState, FC, useEffect } from "react";
import { Row, Col, Button, Form, message, Typography } from "antd";
import { useNavigate, useNavigationType, useLocation } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import { useQuery, useMutation } from "@apollo/client/react";
import { UploadDocument } from "../upload-document";
import { Helmet } from "react-helmet";
import _ from "lodash";
import {
  ApplicantQualification2BGraduationDocument,
  ApplicantQualification2BApplicantPortal_Pathway2bDocument,
  Pathway2,
} from "../../generated";
const { Title, Paragraph, Text, Link } = Typography;
const ComponentPropTypes = {
  pathway2: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasPassedCSExamInRecentPast: PropTypes.bool,
      hasPassedFromQualifiedSchoolInRecentPast: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        data: PropTypes.shape({
          examDate: PropTypes.string,
          schoolRef: PropTypes.shape({
            _id: PropTypes.number,
            name: PropTypes.string,
            countryName: PropTypes.string,
          }),
          oscExamRef: PropTypes.shape({
            _id: PropTypes.string,
            examCode: PropTypes.string,
            examFullName: PropTypes.string,
            examShortName: PropTypes.string,
          }),
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
  refetch: PropTypes.func.isRequired,
};

export interface IProps {
  __typename?: "Qualification2b";
  pathway2?: Pathway2;
  refetch: () => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;
const Qualification2b: FC<ComponentProps> = ({ pathway2, refetch }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const location = useLocation();
  const [pathway2DocName, setPathway2DocName] =
    useState<string | undefined | null>(undefined);
  const [pathway2TranslatedDocName, setPathway2TranslatedDocName] =
    useState<string | undefined | null>(undefined);
  const [pathwayBreakoutScreen, setPathwayBreakoutScreen] =
    useState<string>("");
  const [graduationDetails, setGraduationDetails] = useState<any>(undefined);

  const { data: graduationData } = useQuery(
    ApplicantQualification2BGraduationDocument
  );

  const BREAKOUT_OPTIONS = {
    PATHWAY2B: "PATHWAY2B",
    PATHWAY6: "PATHWAY6",
    PATHWAY345: "PATHWAY345",
  };

  let path2DocNameHack: string | undefined = undefined;
  let path2DocTranslatedNameHack: string | undefined = undefined;
  let currentUploadingDocType: string | undefined = undefined;
  let currentUploadingFilename: string | undefined = undefined;
  const [documents, setDocuments] = useState<any>(undefined);

  const [UpdateRecentExam, { loading: updateRecentExamLoading }] = useMutation(
    ApplicantQualification2BApplicantPortal_Pathway2bDocument
  );

  //initialize data with saved data
  useEffect(() => {
    //Handles if back button is pushed. Reload the page
    if (navigationType === "POP") {
      navigate(location.pathname);
      refetch();
    }
    if (
      pathway2?.applicantProvidedData &&
      pathway2?.applicantProvidedData.length > 0
    ) {
      const applicantProvidedData = pathway2?.applicantProvidedData;

      const uploadedDocs = applicantProvidedData
        ? applicantProvidedData[0]?.data?.documents
        : undefined;
      const copyDocuments = JSON.parse(JSON.stringify(uploadedDocs));
      copyDocuments.map((doc: any) => {
        delete doc.__typename;
      });
      const uploadedDocName = uploadedDocs
        ? uploadedDocs.find((doc: any) => doc.docType === "Pathway2Document")
            ?.title
        : undefined;
      const uploadedTranslatedDocName = uploadedDocs
        ? uploadedDocs.find(
            (doc: any) => doc.docType === "Pathway2TranslatedDocument"
          )?.title
        : undefined;

      setPathway2DocName(uploadedDocName);
      //translated doc name
      setPathway2TranslatedDocName(uploadedTranslatedDocName);
      //documents
      setDocuments(copyDocuments);
      form.setFieldsValue({
        //doc filename
        pathway2Doc: uploadedDocName,
        //translated doc filename
        pathway2TranslatedDocName: uploadedTranslatedDocName,
      });
    }
  }, [pathway2]);

  useEffect(() => {
    //Set which screens to show depending on school pathway and eligble year
    setGraduationDetails(graduationData?.applicantPortal?.graduation);

    if (
      graduationData?.applicantPortal?.graduation?.schoolEligibility
        ?.schoolPathway === "PATHWAY2"
    ) {
      if (graduationData?.applicantPortal?.graduation?.isYearEligible) {
        setPathwayBreakoutScreen(BREAKOUT_OPTIONS.PATHWAY2B);
      } else {
        setPathwayBreakoutScreen(BREAKOUT_OPTIONS.PATHWAY6);
      }
    } else {
      setPathwayBreakoutScreen(BREAKOUT_OPTIONS.PATHWAY345);
    }
  }, [graduationData]);

  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of screen on page load
  }, []);

  const continueQualification = async (formValues: any) => {
    const supportingData =
      pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY2B
        ? {
            data: {
              documents: documents,
            },
          }
        : null;

    await UpdateRecentExam({
      variables: {
        supportingData: supportingData,
      },
    })
      .then((data) => {
        if (pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY2B) {
          navigate(`/Qualification-review`);
        } else if (pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY6) {
          navigate(`/Qualification6`);
        } else {
          navigate("/QualificationF");
        }
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference =
          error?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${error.toString()} - Reference ID : ${graphQLErrorReference}`
        );
      });
  };

  //Get the blob name for azure storage by saving in mongo
  async function getBlobname(filename: string, docType: string) {
    currentUploadingDocType = docType;
    currentUploadingFilename = filename;
    const document = { docType: docType, title: filename };
    var copyDocuments = [];
    if (documents) {
      copyDocuments = JSON.parse(JSON.stringify(documents));
    }

    const sameTypeDoc = _.find(copyDocuments, { docType: docType });
    if (sameTypeDoc) {
      copyDocuments[_.values(copyDocuments).indexOf(sameTypeDoc)] = document;
    } else {
      copyDocuments.push(document);
    }

    const supportingData = {
      data: {
        documents: copyDocuments,
      },
    };

    return UpdateRecentExam({
      variables: {
        supportingData: supportingData,
      },
    })
      .then((data) => {
        var docs =
          data?.data?.ApplicantPortal_pathway2b?.data?.pathway2
            ?.applicantProvidedData?.[0]?.data?.documents!;
        let doc = docs?.find((x: any) => x.docType === docType);
        var guid = doc ? doc.docId : "";
        //We don't want typename
        docs.map((doc: any) => {
          delete doc.__typename;
        });
        setDocuments(docs);

        return guid;
      })
      .catch((error) => {
        return "error";
      });
  }

  const onUploadSuccess = async () => {
    if (currentUploadingDocType === "Pathway2Document") {
      setPathway2DocName(currentUploadingFilename);
      form.setFieldsValue({ pathway2Doc: currentUploadingFilename });
      path2DocNameHack = currentUploadingFilename;
    } else if (currentUploadingDocType === "Pathway2TranslatedDocument") {
      setPathway2TranslatedDocName(currentUploadingFilename);
      form.setFieldsValue({
        pathway2TranslatedDocName: currentUploadingFilename,
      });
      path2DocTranslatedNameHack = currentUploadingFilename;
    }
  };

  const qualifies2b = () => {
    return (
      <>
        <Paragraph>
          <Row>
            <Col>
              <Form.Item>
                <>
                  <Text strong>Final Medical Diploma</Text>
                  <Paragraph>
                    If you are a medical school graduate, please upload your
                    Final Medical Diploma. If you are a medical school student
                    or if your Final Medical Diploma has not been issued, your
                    medical school will be required to confirm your eligibility
                    by completing the ECFMG Objective Structured Clinical
                    Examination (OSCE) Attestation Form. The form will be
                    available for you to download once you have submitted your
                    application.
                  </Paragraph>
                </>
              </Form.Item>
              <Form.Item name="pathway2Doc" valuePropName="uploadedFileName">
                <UploadDocument
                  uploadId="med-school-document-upload"
                  getGuid={(filename: string) =>
                    getBlobname(filename, "Pathway2Document")
                  }
                  label="Select File to Upload"
                  uploadedFileName={pathway2DocName || path2DocNameHack}
                  onUploadSuccessCallback={onUploadSuccess}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Text strong>English Translation (If Needed)</Text>
              </Form.Item>
              <Form.Item
                name="pathway2TranslatedDocName"
                valuePropName="uploadedFileName"
              >
                <UploadDocument
                  uploadId="med-school-translation-upload"
                  getGuid={(filename: string) =>
                    getBlobname(filename, "Pathway2TranslatedDocument")
                  }
                  label="Select File to Upload"
                  uploadedFileName={
                    pathway2TranslatedDocName || path2DocTranslatedNameHack
                  }
                  onUploadSuccessCallback={onUploadSuccess}
                />
              </Form.Item>
            </Col>
          </Row>
        </Paragraph>
      </>
    );
  };

  const QualificationText = () => {
    if (pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY2B) {
      return (
        <Paragraph>
          According to our records, your medical school,{" "}
          {graduationDetails?.school}, administers an OSCE specifically required
          by the medical regulatory authority (MRA) in that country, and your
          graduation date is on or after January 1, 2023. Therefore, you may
          apply to Pathway 2.
        </Paragraph>
      );
    }
    if (pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY345) {
      return (
        <Paragraph>
          According to our records, your medical school,{" "}
          {graduationDetails?.school}, does not meet the eligibility
          requirements for Pathway 2. If you believe this information is
          incorrect, please{" "}
          <Link
            href="https://www.ecfmg.org/contact.html"
            target="_blank"
            rel="noreferrer"
          >
            contact us
          </Link>
          .
        </Paragraph>
      );
    }
    return (
      <>
        <Paragraph>
          According to our records, your medical school,{" "}
          {graduationDetails?.school}, administers an OSCE specifically required
          by the medical regulatory authority (MRA) in that country; however,
          you graduated prior to January 1, 2023. Therefore, you are not
          eligible to apply to Pathway 2. Your medical school and graduation
          date also do not meet the requirements for you to apply to Pathway 3,
          4, or, 5. If you believe this information is incorrect, please{" "}
          <Link
            href="https://www.ecfmg.org/contact.html"
            target="_blank"
            rel="noreferrer"
          >
            contact us
          </Link>
          .
        </Paragraph>
        <Paragraph>
          In order to meet the clinical and communication skills requirements
          for ECFMG Certification, you are <Text strong>required</Text> to apply
          to Pathway 6: Evaluation of Clinical Patient Encounters by Licensed
          Physicians. Before proceeding with this application, you must review
          the{" "}
          <Link
            href="https://www.ecfmg.org/certification-pathways/pathway-6.html"
            target="_blank"
            rel="noreferrer"
          >
            eligibility requirements and application information for Pathway 6
          </Link>
          .
        </Paragraph>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Pathway 2b</title>
      </Helmet>
      <Row>
        <Col span={24}>
          <Title level={4}>
            Pathway 2: Already Passed an Objective Structured Clinical Examination for 
            Medical Licensure Administered by an Acceptable Medical School
          </Title>
        </Col>
      </Row>

      <QualificationText />
      <Form form={form} onFinish={continueQualification}>
        {pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY2B ? (
          <>
            <Form.Item
              name="userResponse"
              initialValue={undefined}
              style={{ width: "100%" }}
            >
              <Row>
                <Col span={23} offset={1}>
                  {qualifies2b()}
                </Col>
              </Row>
            </Form.Item>
          </>
        ) : null}
        <>
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              {pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY2B ||
              pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY345 ? (
                <Button
                  size="large"
                  id="qd-continue-button"
                  type="primary"
                  htmlType="submit"
                  loading={updateRecentExamLoading}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  size='large'
                  id="qd-continue-button-to-pathway6"
                  type="primary"
                  htmlType="submit"
                  loading={updateRecentExamLoading}
                >
                  Continue
                </Button>
              )}
            </Col>
          </Row>
        </>
      </Form>
    </>
  );
};

export default {
  Component: Qualification2b,
};
