import { FC, useEffect, useState } from "react";
import { PATHWAYS } from "./constants";
import { Drawer, Layout, Menu, Row, Col } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "./auth/use-auth";
import { MenuOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import PropTypes, { InferProps } from "prop-types";
// import IntealthLogo from "../logos/Intealth_NO-Tag_White_forSmall.svg";
import ECFMGLogo from "../logos/ECFMG_NoTag_WHITE_TM_forSmall.svg";

const { Header } = Layout;

const ComponentPropTypes = {
  caseState: PropTypes.string,
  currentPathway: PropTypes.string,
};

export interface IProps {
  __typename?: "HeaderContent";
  caseState: string | undefined | null;
  submittedPathway?: string | null;
  fakeValidTokenForStorybook?: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const HeaderContent: FC<ComponentProps> = ({
  caseState,
  currentPathway,
  fakeValidTokenForStorybook,
}) => {
  const { userHasValidToken, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState("1");
  const screens = useBreakpoint();
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    switch (location.pathname) {
      case "/loggedOut":
      case "/login":
        setSelectedMenu("1");
        break;
      case "/qualification-complete":
        setSelectedMenu("2");
        break;
      case "/miniCEXRequest":
        setSelectedMenu("3");
        break;
    }
  }, [location]);

  const menuItemSelected = async (e: any) => {
    setSelectedMenu(e.key);
    setDrawerVisible(false);

    switch (e.key) {
      case "1":
        if (userHasValidToken()) {
          logout();
          navigate(`/loggedOut`);
        } else {
          navigate(`/login`);
        }
        break;
      // case '2':
      //     props.history.push(`/qualificationA`)
      //     break;

      case "2":
        navigate(`/qualification-complete`);
        break;

      case "3":
        navigate(`/miniCEXRequest`);
        break;
    }
  };

  const getEncounterManagementMenuItem = () => {
    if (
      currentPathway === PATHWAYS.Pathway6 &&
      caseState !== "ApplicationCreation"
    ) {
      return (
        <>
          <Menu.Item key="3">Clinical Encounters</Menu.Item>
        </>
      );
    }
    return <></>;
  };
  const onDrawerClose = () => {
    setDrawerVisible(false);
  };

  const getMenu = (mode: any) => {
    return (
      <Menu
        onClick={(e) => menuItemSelected(e)}
        theme={mode === "vertical" ? "light" : "dark"}
        mode={mode}
        selectedKeys={[selectedMenu]}
        defaultSelectedKeys={[selectedMenu]}
      >
        {location.pathname === "/qualification-complete" ||
        location.pathname === "/miniCEXRequest" ? (
          <>
            <Menu.Item key="2">Summary</Menu.Item>
            {getEncounterManagementMenuItem()}
          </>
        ) : null}
        <Menu.Item key="1" style={{ marginLeft: "auto" }}>
          {userHasValidToken() || fakeValidTokenForStorybook
            ? "Logout"
            : "Login"}
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <Header style={{ zIndex: 1, width: "100%" }}>
      {screens.xs ? (
        <>
          <Row>
            <Col span={8}>
              <MenuOutlined
                style={{ fontSize: "16px", color: "white" }}
                onClick={() => {
                  setDrawerVisible(true);
                }}
              />
              <Drawer
                placement="left"
                closable={false}
                onClose={onDrawerClose}
                visible={drawerVisible}
                height="auto"
              >
                {getMenu("vertical")}
              </Drawer>
            </Col>
            {userHasValidToken() || fakeValidTokenForStorybook ? (
              <Col span={8}>
                <img className="logo" src={ECFMGLogo} />
              </Col>
            ) : (
              <Col span={8}></Col>
            )}
            <Col span={8}></Col>
          </Row>
        </>
      ) : (
        <>
          {userHasValidToken() || fakeValidTokenForStorybook ? (
            <img className="logo" src={ECFMGLogo} />
          ) : null}
          {getMenu("horizontal")}
        </>
      )}
    </Header>
  );
};

export default HeaderContent;
