export const ResetPasswordLink = () => {
    return process.env.REACT_APP_RESET_PASSWORD !== null &&
      typeof process.env.REACT_APP_RESET_PASSWORD !== "undefined"
      ? `${process.env.REACT_APP_RESET_PASSWORD}`
      : "https://tssecure2.ecfmg.org/auth.asp";
  };
  
  export const GetFeatureFlagEnvironment = () => {
    return process.env.REACT_APP_FEATURE_FLAG_URL !== null &&
      typeof process.env.REACT_APP_FEATURE_FLAG_URL !== "undefined"
      ? `${process.env.REACT_APP_FEATURE_FLAG_URL}`
      : "https://pwpblobcontainer.blob.core.windows.net/featureflags/Pathways_Feature_Flag.json";
  };