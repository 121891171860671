import PropTypes from "prop-types";

export const ProgressFieldsPropTypes = {
  progress: PropTypes.shape({
    pathwayEligibility: PropTypes.arrayOf(
      PropTypes.shape({
        checklistRecord: PropTypes.string.isRequired,
        checklistState: PropTypes.string.isRequired,
      })
    ),
    englishEligibility: PropTypes.arrayOf(
      PropTypes.shape({
        checklistRecord: PropTypes.string.isRequired,
        checklistState: PropTypes.string.isRequired,
      })
    ),
    generalEligibility: PropTypes.arrayOf(
      PropTypes.shape({
        checklistRecord: PropTypes.string.isRequired,
        checklistState: PropTypes.string.isRequired,
      })
    ),
  }),
};

export const FinancePropTypes = {
  finance: PropTypes.shape({
    payment: PropTypes.shape({
      amt: PropTypes.string.isRequired,
      transTime: PropTypes.string.isRequired,
    }),
    refund: PropTypes.shape({
      amt: PropTypes.number.isRequired,
      transTime: PropTypes.string.isRequired,
    }),
  }),
};

export const GraduationPropTypes = {
  graduation: PropTypes.shape({
    schoolEligibility: PropTypes.shape({
      isSchoolEMSWPMember: PropTypes.bool.isRequired,
    }),
  }),
};

export interface IAuthority {
  regulatoryAuthorityRef: {
    _id: number;
    orgName: string;
    countryName: string;
  };
  licenseIssueDate: string;
  licenseExpiryDate?: string;
  hasDisciplinaryAction: boolean;
  isCurrentlyLicensed: boolean;
  licensureDocumentStatus: string;
  documents?: {
    docType: string;
    title: string;
    docId: string;
  }[];
}
