import React, { FC, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Modal,
  Card,
  Descriptions,
  Divider,
  Button,
  Space,
  Typography,
} from "antd";
import ViewPDF from "../ViewPDF";
import _, { toUpper } from "lodash";
import { useQuery } from "@apollo/client";
import PropTypes, { InferProps } from "prop-types";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { LICENSURE_DOCUMENT_STATUS } from "../constants";
import { useFeatureFlags } from "../feature-flag-react-lite";
import {
  ApplicantQualificationReviewLicenseRegulatoryAuthorityDocument,
  Pathway1,
} from "../../generated";
const { Title, Paragraph, Text } = Typography;
const ComponentPropTypes = {
  onEditLicenseCallback: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    userResponse: PropTypes.shape({
      isCurrentlyLicensed: PropTypes.bool,
      wasLicensedInRecentPast: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        data: PropTypes.shape({
          regulatoryAuthorityRef: PropTypes.shape({
            _id: PropTypes.number,
            orgName: PropTypes.string,
            countryName: PropTypes.string,
          }),
          licenseIssueDate: PropTypes.string,
          licenseExpiryDate: PropTypes.string,
          hasDisciplinaryAction: PropTypes.bool,
          isCurrentlyLicensed: PropTypes.bool,
          licensureDocumentStatus: PropTypes.string,
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
};

export interface IProps {
  __typename?: "ReviewLicenseInformation";
  onEditLicenseCallback: () => void;
  initialValues?: Pathway1;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const ReviewLicenseInformation: FC<ComponentProps> = ({
  onEditLicenseCallback,
  initialValues,
}) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const {
    loading,
    error,
    data: regulatoryAuthorityData,
  } = useQuery(ApplicantQualificationReviewLicenseRegulatoryAuthorityDocument);
  const { GetFeatureFlagByName } = useFeatureFlags();

  const supportingData = initialValues?.applicantProvidedData as any;
  const userResponse = initialValues?.userResponse;

  var questionList = <Paragraph></Paragraph>;
  var licenseList = <Paragraph></Paragraph>;

  const parseLicensureDocStatus = (status: string) => {
    switch (toUpper(status)) {
      case "REQUESTEDSENTTOECFMG":
        return "Authority will send Certificate of Good Standing";
      case "ISSUEDINLAST90DAYS":
        return "Upload Letter or Certificate of Good Standing";
      case "DONOTHAVECANNOTOBTAIN":
        return "Cannot Provide Letter of Good Standing";
    }
  };

  //Check user responses
  if (userResponse !== undefined && userResponse !== null) {
    if (userResponse.isCurrentlyLicensed) {
      //Pathway 1a
      questionList = (
        <Paragraph>
          Do you currently hold a license/registration (full or provisional) to
          practice medicine without supervision in any country/jurisdiction?{" "}
          <Text strong>{userResponse.isCurrentlyLicensed ? "Yes" : "No"}</Text>
        </Paragraph>
      );
    } else {
      //Pathway 1b
      questionList = (
        <Paragraph>
          <Paragraph>
            Do you currently hold a license/registration (full or provisional)
            to practice medicine without supervision in any
            country/jurisdiction?{" "}
            <Text strong>
              {userResponse.isCurrentlyLicensed ? "Yes" : "No"}
            </Text>
          </Paragraph>
          <Paragraph>
            Have you held a license/registration (full or provisional) to
            practice medicine without supervision in any country/jurisdiction at
            any time on or after January 1, 2020?{" "}
            <Text strong>
              {userResponse.wasLicensedInRecentPast ? "Yes" : "No"}
            </Text>
          </Paragraph>
        </Paragraph>
      );
    }
  }

  //Get all license data
  var licenseNum = 1;
  if (
    supportingData !== undefined &&
    supportingData !== null &&
    (userResponse?.isCurrentlyLicensed || userResponse?.wasLicensedInRecentPast)
  ) {
    licenseList = supportingData.map((data: any) => {
      var licensureDocStatus = parseLicensureDocStatus(
        data.data.licensureDocumentStatus
      );
      var regulatoryAuthority = data.data.regulatoryAuthorityRef._id;
      const country = data?.data?.regulatoryAuthorityRef?.countryName;

      var docList = <Paragraph></Paragraph>;

      if (!error) {
        regulatoryAuthority = _.find(
          regulatoryAuthorityData?.regulatoryAuthority,
          {
            _id: regulatoryAuthority,
          }
        )?.orgName;
      }
      //Did the applicant upload documents? and submit yes answers?
      if (
        data?.data?.documents.length > 0 &&
        (userResponse?.isCurrentlyLicensed ||
          userResponse?.wasLicensedInRecentPast)
      ) {
        docList = data.data.documents.map((document: any) => (
          <Paragraph key={document.docId}>
            {document.docType === "LicenseDocument" &&
            data.data.licensureDocumentStatus ===
              LICENSURE_DOCUMENT_STATUS.issuedInLast90Days
              ? "Certificate or Letter of Good Standing:"
              : null}
            {document.docType === "TranslatedLicenseDocument" &&
            data.data.licensureDocumentStatus ===
              LICENSURE_DOCUMENT_STATUS.issuedInLast90Days
              ? "Translated Certificate of Good Standing:"
              : null}
            {document.docType === "LicenseDocument" &&
            data.data.licensureDocumentStatus ===
              LICENSURE_DOCUMENT_STATUS.doNotHaveCannotObtain
              ? "License or Registration:"
              : null}
            {document.docType === "TranslatedLicenseDocument" &&
            data.data.licensureDocumentStatus ===
              LICENSURE_DOCUMENT_STATUS.doNotHaveCannotObtain
              ? "Translated License or Registration:"
              : null}{" "}
            <Button
              icon={<SearchOutlined />}
              shape="round"
              size="small"
              onClick={() => previewDocument(document)}
            >
              {document.title}
            </Button>
            <br />
            <br />
            <Modal
              visible={showPreviewPdfModal}
              width="80vw"
              okText="Continue"
              footer={null}
              onCancel={() => viewPdfClosed()}
              destroyOnClose
            >
              {showPreviewPdfModal ? (
                <ViewPDF
                  filename={selectedDocument.title}
                  docId={selectedDocument.docId}
                ></ViewPDF>
              ) : (
                ""
              )}
            </Modal>
          </Paragraph>
        ));
      }

      return (
        <Card size="small">
          <Title level={4}>License {licenseNum++}</Title>
          <Descriptions
            className={"ecfmg-small-descriptions"}
            layout={"vertical"}
            bordered={false}
            column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
            size="small"
          >
            <Descriptions.Item label="License Issue Date">
              {data.data.licenseIssueDate &&
                moment(data.data.licenseIssueDate).format("MM/DD/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="License Expiry Date">
              {data.data.licenseExpiryDate
                ? moment(data.data.licenseExpiryDate).format("MM/DD/YYYY")
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Licensure Document Status">
              {licensureDocStatus}
            </Descriptions.Item>
            <Descriptions.Item label="License Country">
              {country}
            </Descriptions.Item>
            <Descriptions.Item label="Currently Licensed">
              {data.data.isCurrentlyLicensed ? "Yes" : "No"}
            </Descriptions.Item>
            <Descriptions.Item label="Disciplinary Action">
              {data.data.hasDisciplinaryAction ? "Yes" : "No"}
            </Descriptions.Item>
            <Descriptions.Item label="Regulatory Authority">
              {regulatoryAuthority}
            </Descriptions.Item>
          </Descriptions>

          {docList}
        </Card>
      );
    });
  }

  const previewDocument = async (document: any) => {
    setSelectedDocument(document);
    setShowPreviewPdfModal(true);
  };

  const viewPdfClosed = async () => {
    setShowPreviewPdfModal(false);
  };

  /*_.find(authority_list, { _id: initialValues?.regulatoryAuthorityId })
            ?.countryName*/
  return (
    <>
      {userResponse ? (
        <Paragraph style={{ marginBottom: 5 }}>
          <Divider plain orientation="left">
            <Space size="middle">
              <Text strong>Pathway 1</Text>
              <Button onClick={onEditLicenseCallback}>
                Edit
              </Button>
            </Space>
          </Divider>
          {questionList}
          <br />
          {licenseList}
        </Paragraph>
      ) : (
        <Paragraph></Paragraph>
      )}
    </>
  );
};

export default {
  Component: ReviewLicenseInformation,
};
