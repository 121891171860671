import React, { FC } from "react";
import {
  Descriptions,
  Card,
  Row,
  Col,
  Tooltip,
  Button,
  Space,
  Popconfirm,
  Typography,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import PropTypes, { InferProps } from "prop-types";
import moment from "moment";
import { DATE_FORMAT } from "./constants";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  displayValues: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    data: PropTypes.shape({
      regulatoryAuthorityRef: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        orgName: PropTypes.string.isRequired,
        countryName: PropTypes.string.isRequired,
      }).isRequired,
      licenseIssueDate: PropTypes.instanceOf(Date).isRequired,
      licenseExpiryDate: PropTypes.instanceOf(Date),
      isCurrentlyLicensed: PropTypes.bool.isRequired,
      hasDisciplinaryAction: PropTypes.bool.isRequired,
      licensureDocumentStatus: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export interface IProps {
  __typename?: "AuthorityCard";
  onDelete?: (regulatoryAuthorityId: string) => void;
  onEdit?: (regulatoryAuthorityId: string) => void;
  displayValues: {
    _id: string;
    data: {
      regulatoryAuthorityRef: {
        _id: string;
        orgName: string;
        countryName: string;
      };
      licenseIssueDate: Date;
      licenseExpiryDate?: Date | null;
      isCurrentlyLicensed: boolean;
      hasDisciplinaryAction: boolean;
      licensureDocumentStatus?: string | null;
    };
  };
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const AuthorityCard: FC<ComponentProps> = ({
  onDelete,
  onEdit,
  displayValues,
}) => {
  const countryName = displayValues.data.regulatoryAuthorityRef.countryName;
  const orgName = displayValues.data.regulatoryAuthorityRef.orgName;

  return (
    <Paragraph className="space-align-block">
      <Card size="small" className={"authorityCard"} id={displayValues._id}>
        {/* org name */}
        <Descriptions
          className={"ecfmg-small-descriptions"}
          title={orgName}
          size="small"
          layout="vertical"
          bordered={false}
          column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        >
          {/* country Name */}
          <Descriptions.Item label="Country">{countryName}</Descriptions.Item>
          <Descriptions.Item label="Date">
            {moment(displayValues.data.licenseIssueDate).format(DATE_FORMAT)}
          </Descriptions.Item>
          <Descriptions.Item label="Current">
            {displayValues.data.isCurrentlyLicensed ? "Yes" : "No"}
          </Descriptions.Item>
          <Descriptions.Item label="Disciplinary Action">
            {displayValues.data.hasDisciplinaryAction ? "Yes" : "No"}
          </Descriptions.Item>
        </Descriptions>
        <Row>
          <Col span="24" style={{ textAlign: "right" }}>
            <Space>
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => onEdit && onEdit()}
                />
              </Tooltip>

              <Popconfirm
                title="Are you sure you want to delete this?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDelete && onDelete()}
              >
                <Tooltip title="Delete">
                  <Button shape="circle" danger icon={<DeleteOutlined />} />
                </Tooltip>
              </Popconfirm>
            </Space>
          </Col>
        </Row>
      </Card>
    </Paragraph>
  );
};

AuthorityCard.defaultProps = {
  onDelete: () => {},
  onEdit: () => {},
};
