import PropTypes, { InferProps } from "prop-types";
import React, { FC, useState } from "react";
import { Button, Form, Upload, message, Tooltip, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import axios from "axios";
import { ApplicantUploadDocumentUploadDocumentDocument } from "../generated";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  onUploadSuccessCallback: PropTypes.func,
  getGuid: PropTypes.func.isRequired,
  GuidValue: PropTypes.string,
  showIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  uploadedFileName: PropTypes.string,
  label: PropTypes.string,
  uploadId: PropTypes.string,
};

export interface IProps {
  __typename?: "UploadDocument";
  onUploadSuccessCallback?: () => void;
  getGuid: (filename: string) => void;
  GuidValue?: string;
  showIcon?: boolean;
  disabled?: boolean;
  uploadedFileName?: string;
  label?: string;
  uploadId?: string;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const UploadDocument: FC<ComponentProps> = ({
  onUploadSuccessCallback,
  getGuid,
  disabled,
  showIcon,
  uploadedFileName,
  label,
  uploadId,
}) => {
  const [form] = Form.useForm();
  const [documentName, setDocumentName] = useState("");
  const [uploadDocument] = useMutation(ApplicantUploadDocumentUploadDocumentDocument);
  const [isProcessingUpload, setIsProcessingUpload] = useState(false);
  const [isUploadDone, setIsUploadDone] = useState(false);
  const [isUploadFailed, setisUploadFailed] = useState(false);
  async function beforeUpload(file: any) {
    setIsProcessingUpload(true);
    setIsUploadDone(false);
    const documentName = file.name;

    //TODO: apollo call to update database -> filename, documentType, DocID (GUID)
    //Returns the blobName
    var documentGuid = await getGuid(documentName);
    if (documentGuid?.toLowerCase().includes("error")) {
      message.error("There are errors while uploading. Please try again!");
      setisUploadFailed(true);
      setIsProcessingUpload(false);
      setIsUploadDone(true);
    } else {
      //Generate SAS with blobName
      const results = await uploadDocument({
        variables: {
          fileName: documentGuid,
        },
      })
        .then(async (res) => {
          console.log(res);
          const sasToken = res.data?.uploadDocument?.token?.uri;
          uploadToAzure(file, sasToken);
        })
        .catch((error) => {
          message.error("There are errors while uploading. Please try again!");
          setisUploadFailed(true);
          setIsProcessingUpload(false);
          setIsUploadDone(true);
        });
    }
  }

  const uploadProps = {
    showUploadList: false,
    beforeUpload(file: any, fileList: any) {
      console.log("BEFORE UPLOAD");

      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        message.error("File must smaller than 10MB");
        return false;
      }
      if (file.type !== "application/pdf") {
        message.error("File must be a pdf");
        return false;
      }

      //overloaded facade since UPLOAD cannot take async functions
      beforeUpload(file);

      return false;
    },
    accept: ".pdf",
  };

  const uploadToAzure = async (file: any, SASToken: any) => {
    await axios
      .put(SASToken, new Blob([file], { type: "application/pdf" }), {
        headers: {
          "x-ms-blob-type": "BlockBlob",
        },
      })
      .then((result: any) => {
        if (result.status === 201) {
          setisUploadFailed(false);
        } else {
          setisUploadFailed(true);
        }
        setIsProcessingUpload(false);
        setIsUploadDone(true);
        if (onUploadSuccessCallback) {
          onUploadSuccessCallback(file.name, SASToken);
        }
        message.success("Upload successfully!");
      })
      .catch((error) => {
        message.error("There are errors while uploading. Please try again!");
        setisUploadFailed(true);
        setIsProcessingUpload(false);
        setIsUploadDone(true);
      });
  };

  return (
    <>
      <Upload {...uploadProps} id={uploadId ?? undefined} accept={"application/pdf"}>
        <Paragraph style={{ display: "inline" }}>
          <Button
            loading={isProcessingUpload}
            type="default"
            disabled={disabled || isProcessingUpload ? true : false}
            size="large"
          >
            {showIcon ? <UploadOutlined /> : ""}
            {label ? label : ""}
          </Button>
          <Paragraph>
            <i>(PDF only, max size 10MB)</i>
          </Paragraph>

          {uploadedFileName !== "" ? (
            <>
              {isUploadDone || uploadedFileName ? (
                !isUploadFailed ? (
                  <>
                    <Paragraph
                      style={{
                        visibility: isProcessingUpload ? "hidden" : "visible",
                      }}
                    >
                      Uploaded file:
                    </Paragraph>
                    <Tooltip title={`${uploadedFileName}`}>
                      <Paragraph
                        id={uploadId ? `${uploadId}_filename` : undefined}
                        style={{
                          width: "200px",
                          paddingLeft: "10px",
                          visibility: isProcessingUpload ? "hidden" : "visible",
                        }}
                        ellipsis={{ rows: 1 }}
                      >
                        {`- ${uploadedFileName}`}
                      </Paragraph>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Paragraph>Upload failed</Paragraph>
                  </>
                )
              ) : (
                <></>
              )}

              {/* <Input
                  style={{ borderColor: "white", paddingLeft: "0" }}
                  type="text"
                  value={uploadedFileName!}
                  readOnly
                  disabled={disabled || isProcessingUpload ? true : false}
                /> */}
            </>
          ) : (
            <></>
          )}
        </Paragraph>
      </Upload>
    </>
  );
};

UploadDocument.defaultProps = {
  onUploadSuccessCallback: () => {},
  getGuid: () => {},
  showIcon: true,
  disabled: false,
  uploadedFileName: "",
  label: "",
};
