import React, { FC } from "react";
import { AuthorityCard } from "./authority-card";
import PropTypes, { InferProps } from "prop-types";
import { Typography } from "antd";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  authorities: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      data: PropTypes.shape({
        regulatoryAuthorityRef: PropTypes.shape({
          _id: PropTypes.string.isRequired,
          orgName: PropTypes.string.isRequired,
          countryName: PropTypes.string.isRequired,
        }).isRequired,
        licenseIssueDate: PropTypes.instanceOf(Date).isRequired,
        licenseExpiryDate: PropTypes.instanceOf(Date),
        isCurrentlyLicensed: PropTypes.bool.isRequired,
        hasDisciplinaryAction: PropTypes.bool.isRequired,
        licensureDocumentStatus: PropTypes.string,
      }).isRequired,
    }).isRequired
  ).isRequired,
};

export interface IProps {
  __typename?: "AuthorityCardList";
  onDelete?: (regulatoryAuthorityId: string) => void;
  onEdit?: (regulatoryAuthorityId: string) => void;
  authorities: {
    _id: string;
    data: {
      regulatoryAuthorityRef: {
        _id: string;
        orgName: string;
        countryName: string;
      };
      licenseIssueDate: Date;
      licenseExpiryDate?: Date | null;
      isCurrentlyLicensed: boolean;
      hasDisciplinaryAction: boolean;
      licensureDocumentStatus?: string | null;
    };
  }[];
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const AuthorityCardList: FC<ComponentProps> = ({
  onDelete,
  onEdit,
  authorities,
}) => {
  const cardList = authorities.map((authority) => (
    <AuthorityCard
      key={authority._id}
      displayValues={authority}
      onEdit={() => onEdit && onEdit(authority._id)}
      onDelete={() => onDelete && onDelete(authority._id)}
    />
  ));

  return (
    <Paragraph
      className="space-align-container"
      style={{ minHeight: "200px", backgroundColor: "#f7f7f7" }}
    >
      {cardList}
    </Paragraph>
  );
};

AuthorityCard.defaultProps = {
  onDelete: () => {},
  onEdit: () => {},
};
