import React, { FC, useState, useEffect } from "react";
import { Modal, Grid, Tabs, message, Spin, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client/react";
import PhysicianEncounterListTable from "./physician-encounter-list-table";
import PhysicianEncounterHistoryListTable from "./physician-encounter-history-list-table";
import PhysicianEncounterListCard from "./physician-encounter-list-card";
import PhysicianEncounterHistoryListCard from "./physician-encounter-history-list-card";
import { AddCEXEncounter } from "./physician-encounter-add-modal";
import { RemoveCEXEncounter } from "./physician-encounter-remove-modal";
import { RowItem } from "./physician-interfaces";
import {
  PHYSICIAN_ENC_ALLOWED_ACTIONS,
  RESPONSE_TYPE,
  TAB_STATES,
} from "../constants";
import { Helmet } from "react-helmet";
import {
  ApplicantPhysicianRequestLayoutCseEncountersDocument,
  A_CaseDetails,
  ApplicantPhysicianRequestLayoutValidateEmailDocument,
  ApplicantPhysicianRequestLayoutRemoveEncounterDocument,
} from "../../generated";
const { Title, Paragraph, Text, Link } = Typography;
const { TabPane } = Tabs;

const { useBreakpoint } = Grid;

const PhysicianRequestLayout: FC<any> = () => {
  const screens = useBreakpoint();
  const [tableData, setTableData] = useState<RowItem[]>([]);
  const [showAddEncounterModal, setShowAddEncounterModal] =
    useState<boolean>(false);
  const [showRemoveEncounterModal, setShowRemoveEncounterModal] =
    useState<boolean>(false);
  const [deleteEncounterIndex, setDeleteEncounterIndex] = useState<number>(-1);
  const [activeTab, setActiveTab] = useState<string>(TAB_STATES.ACTIVE);
  const [responseType, setResponseType] = useState<string>(
    RESPONSE_TYPE.ACTIVE
  );

  const { loading, data, refetch } = useQuery(
    ApplicantPhysicianRequestLayoutCseEncountersDocument,
    {
      variables: {
        responseType: responseType,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [removeEncounter, { loading: removeEncounterLoading }] = useMutation(
    ApplicantPhysicianRequestLayoutRemoveEncounterDocument
  );
  const [validateEmail] = useMutation(
    ApplicantPhysicianRequestLayoutValidateEmailDocument
  );

  useEffect(() => {
    if (data) {
      updateTableData(data?.applicantPortal?.minicexList, responseType);
    }
  }, [data]);

  //Show the add CEX encounter modal
  const addCEXEncounter = () => {
    setShowAddEncounterModal(true);
  };

  //Show the remove CEX encounter modal
  const removeCEXEncounter = (index: any) => {
    setDeleteEncounterIndex(index);
    setShowRemoveEncounterModal(true);
  };

  const closeAddEncounterModal = () => {
    setShowAddEncounterModal(false);
    message.destroy();
  };

  const closeRemoveEncounterModal = () => {
    //Reset variable
    setDeleteEncounterIndex(-1);
    setShowRemoveEncounterModal(false);
  };

  const updateTableData = (rows: any, resType: any) => {
    let tableRows = rows?.map((item: any) => ({
      ...item,
    }));

    if (resType === RESPONSE_TYPE.ACTIVE) {
      while (
        tableRows?.length < 6 &&
        data?.applicantPortal?.caseDetails?.state !== "ApplicationCompleted"
      ) {
        tableRows?.push({
          allowedActions: [PHYSICIAN_ENC_ALLOWED_ACTIONS.ADD] as string[],
        } as RowItem);
      }
    }

    setTableData(tableRows);
    setShowAddEncounterModal(false);
  };

  const modalWidth = () => {
    if (screens.lg) {
      return "60vw";
    } else {
      return "90vw";
    }
  };

  const deleteEncounter = async () => {
    //Get data by index
    const row = tableData[deleteEncounterIndex];

    //Do delete
    await removeEncounter({
      variables: {
        encounterId: row.encounterId,
        dataVersion: row.dataVersion,
      },
    })
      .then((result: any) => {
        updateTableData(
          result?.data?.ApplicantPortal_MiniCEX_remove,
          RESPONSE_TYPE.ACTIVE
        );
        setShowRemoveEncounterModal(false);
      })
      .catch((err) => {
        //only grab the first error
        const graphQLErrorReference =
          err?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${err.toString()} ${
            graphQLErrorReference
              ? "- Reference ID : " + graphQLErrorReference
              : ""
          }`,
          10
        );
      });
  };

  const validEmailCheck = async (email: string) => {
    let results = await validateEmail({
      variables: {
        email: email,
      },
    });

    return results.data;
  };

  const onTabChange = (e: any) => {
    setTableData([]);
    setActiveTab(e);
    switch (e) {
      case TAB_STATES.ACTIVE: {
        setResponseType(RESPONSE_TYPE.ACTIVE);
        refetch({ responseType: RESPONSE_TYPE.ACTIVE }).then((result: any) => {
          updateTableData(
            result?.data?.applicantPortal?.minicexList,
            RESPONSE_TYPE.ACTIVE
          );
        });
        break;
      }
      case TAB_STATES.INACTIVE: {
        setResponseType(RESPONSE_TYPE.ARCHIVED);
        refetch({ responseType: RESPONSE_TYPE.ARCHIVED }).then(
          (result: any) => {
            updateTableData(
              result?.data?.applicantPortal?.minicexList,
              RESPONSE_TYPE.ARCHIVED
            );
          }
        );
        break;
      }
    }
  };

  const tabPaneTable = () => {
    return !!data?.applicantPortal?.caseDetails ? (
      <TabPane tab={TAB_STATES.ACTIVE} key={TAB_STATES.ACTIVE}>
        <PhysicianEncounterListTable
          minicex={tableData}
          caseDetails={data.applicantPortal.caseDetails as A_CaseDetails}
          addCEXEncounter={() => addCEXEncounter()}
          removeCEXEncounter={(index: any) => removeCEXEncounter(index)}
          loading={loading}
        />
      </TabPane>
    ) : null;
  };

  const tabPaneCard = () => {
    return !!data?.applicantPortal?.caseDetails ? (
      <TabPane tab={TAB_STATES.ACTIVE} key={TAB_STATES.ACTIVE}>
        <PhysicianEncounterListCard
          minicex={tableData}
          caseDetails={data.applicantPortal.caseDetails as A_CaseDetails}
          addCEXEncounter={() => addCEXEncounter()}
          removeCEXEncounter={(index: any) => removeCEXEncounter(index)}
        />
      </TabPane>
    ) : null;
  };

  if (loading) {
    return <Spin></Spin>;
  }

  return (
    <>
      <Helmet>
        <title>Clinical Encounters</title>
      </Helmet>
      <Title level={3}>Clinical Encounters Dashboard</Title>
      <Paragraph>
        For each in-person clinical encounter, please provide the name and
        e-mail address of the licensed physician who has agreed to observe and
        evaluate you during the encounter. You must obtain each physician’s
        approval to participate in this process, and to provide their names and
        e-mail addresses to ECFMG, <Text strong>before</Text> submitting the
        information to ECFMG. Each physician evaluator must meet the{" "}
        <Link
          target="_blank"
          href="https://www.ecfmg.org/certification-pathways/pathway-6.html#physician-evals"
          rel="noreferrer"
        >
          Criteria for Acceptable Physician Evaluators
        </Link>{" "}
        listed on the Pathway 6 page of the ECFMG website. It is your responsibility 
        to ensure that the physician evaluators you select meet ECFMG’s criteria.
      </Paragraph>
      <Paragraph>
        <Text strong>NOTE:</Text> For each encounter, the physician evaluator must accept your request through 
        the Clinical Skills Evaluation and Attestation Portal <Text strong>before the clinical encounter 
        takes place.</Text> The system will not allow the physician evaluator to enter a Mini-CEX evaluation 
        for an encounter that took place prior to acceptance of your request. It is your responsibility to 
        ensure that the physician evaluator has accepted your request before participating in the encounter.
        You can track the status of the request below; the status must indicate "Request Accepted" before you
        participate in the encounter.
      </Paragraph>
      <ul style={{ listStyleType: "disc"}}>
        <li>
          <Text strong>To enter a new physician evaluator:</Text> Click Add in
          the Action column to enter the name and e-mail address of a physician
          evaluator for each of the six clinical encounters. 
          <ul style={{ listStyleType: "circle" }}>
            <li>
              A physician may observe you in up to two encounters. If a physician will be observing
              you in two encounters, you must enter that physician’s information for
              both encounters.
            </li>
            <li>
              After you enter a physician’s name and e-mail address
              for an encounter, your request for a Mini-CEX evaluation will be sent
              automatically to the physician.
            </li>
            <li>
              Each physician evaluator is limited to evaluating no more than 10 applicants for the 2025 Pathways season. 
              If the evaluator you select has already met this limit or has opted out of participating in the Mini-CEX process 
              for Pathway 6, the system will indicate this when you enter the physician’s e-mail address, and you will not be 
              able to request this physician evaluator.  
            </li>
          </ul>
          
        </li>
        <li>
          <Text strong>To remove a physician evaluator:</Text> After you enter a
          physician evaluator, you will have the option to remove that physician
          by clicking Remove until the physician accepts your request. In
          addition, please note:
          <ul style={{ listStyleType: "circle" }}>
            <li>
              If the physician rejects your request, or does not accept your
              request within 15 days of the initial invitation, the request will
              be withdrawn, and you will be required to remove that physician and
              add a new physician for that encounter. You will be informed by
              e-mail if the request is withdrawn.
            </li>
            <li>
              If the physician does not accept your request within the 15 days
              but you still expect the physician to accept your request and
              perform the evaluation, you may re-enter the physician for that
              encounter.
            </li>
            <li>
              If the physician accepts your request but does not complete the
              Mini-CEX evaluation within 15 days of accepting the request, or if the
              physician rejects your request after accepting it, the request
              will be withdrawn, and you will be required to remove the physician
              and add a new physician for that encounter. You will be informed
              by e-mail if the request is withdrawn.
            </li>
            <li>
              If the physician does not complete the Mini-CEX evaluation within
              the 15 days, you may re-enter the physician and complete a new 
              encounter for that physician to evaluate. 
            </li>
            <li>
              Encounters that have been removed will be displayed under the
              Inactive tab.
            </li>
          </ul>
        </li>
      </ul>
      {/*Anything bigger than lg will also be part of lg*/}
      {screens.lg ? (
        <>
          <Tabs
            activeKey={activeTab}
            onChange={(e: any) => {
              onTabChange(e);
            }}
          >
            {tabPaneTable()}
            <TabPane tab={TAB_STATES.INACTIVE} key={TAB_STATES.INACTIVE}>
              <PhysicianEncounterHistoryListTable
                minicex={tableData}
                loading={loading}
              />
            </TabPane>
          </Tabs>
        </>
      ) : (
        <>
          <Tabs
            defaultActiveKey={TAB_STATES.ACTIVE}
            onChange={(e) => {
              onTabChange(e);
            }}
          >
            {tabPaneCard()}
            <TabPane tab={TAB_STATES.INACTIVE} key={TAB_STATES.INACTIVE}>
              <PhysicianEncounterHistoryListCard minicex={tableData} />
            </TabPane>
          </Tabs>
        </>
      )}
      <Paragraph>
        All times are calculated using Coordinated Universal Time (UTC).
      </Paragraph>

      <Modal
        title="Add Evaluator"
        visible={showAddEncounterModal}
        width={modalWidth()}
        footer={null}
        onCancel={() => closeAddEncounterModal()}
        closable={false}
        destroyOnClose
      >
        {!!data ? (
          <AddCEXEncounter
            onFormCancel={() => closeAddEncounterModal()}
            saveNewEncounterCallback={(data: any) =>
              updateTableData(data, RESPONSE_TYPE.ACTIVE)
            }
            validEmailCheck={(email: string) => validEmailCheck(email)}
          ></AddCEXEncounter>
        ) : null}
      </Modal>
      <Modal
        title="Confirm Removal"
        visible={showRemoveEncounterModal}
        footer={null}
        closable={false}
      >
        <RemoveCEXEncounter
          onFormCancel={() => closeRemoveEncounterModal()}
          deleteEncounter={() => deleteEncounter()}
          removeEncounterLoading={removeEncounterLoading}
        ></RemoveCEXEncounter>
      </Modal>
    </>
  );
};
export default PhysicianRequestLayout;
