import React, { FC } from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const LoggedOut: FC<any> = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Logged Out</title>
      </Helmet>
      <Result
        status="success"
        title="Successfully Logged Out"
        subTitle="Please log in again if you want to access your application"
        extra={[
          <Button size="large" type="primary" key="console" onClick={() => navigate(`/`)}>
            Return to Main Page
          </Button>,
        ]}
      />
    </>
  );
};
export default LoggedOut;
