import React, { FC, useEffect } from "react";
import PropTypes, { InferProps } from "prop-types";
import { pageNavigation } from "../util/navigation";
import { Spin, Typography } from "antd";
import { FinancePropTypes } from "./component-types/common-types";
import { useNavigate } from "react-router-dom";
const { Title, Paragraph, Text } = Typography;

const LimboMessages = [
  "LIMBO - STEP 1",
  "LIMBO - STEP 2 CK",
  "LIMBO - STEP 1 and STEP 2 CK",
  "NOT PASSED OR REGISTERED FOR ECFMG EXAM",
];

const RedirectAfterLoginPropTypes = {
  applicantPortal: {
    FinancePropTypes,
    caseDetails: PropTypes.shape({
      state: PropTypes.string.isRequired,
      isExceptionCase: PropTypes.string.isRequired,
    }),
    eligibilityPreCheck: PropTypes.shape({
      eligibility: PropTypes.shape({
        isEligibile: PropTypes.bool.isRequired,
        eligibilityCheckTimestamp: PropTypes.string.isRequired,
        responseCode: PropTypes.string.isRequired,
        responseMsg: PropTypes.string.isRequired,
      }),
      lastScreenName: PropTypes.string.isRequired,
      isStep2CSFailed: PropTypes.bool.isRequired,
    }),
    pathway1: PropTypes.shape({
      userResponse: PropTypes.shape({
        isCurrentlyLicensed: PropTypes.string.isRequired,
        wasLicensedInRecentPast: PropTypes.string.isRequired,
      }),
    }),
    pathway2: PropTypes.shape({
      userResponse: PropTypes.shape({
        hasPassedCSExamInRecentPast: PropTypes.string.isRequired,
      }),
      applicantProvidedData: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            schoolRef: PropTypes.shape({
              name: PropTypes.string.isRequired,
            }),
          }),
        })
      ),
    }),
    pathway345: PropTypes.shape({
      applicantProvidedData: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            schoolRef: PropTypes.shape({
              name: PropTypes.string.isRequired,
            }),
          }),
        })
      ),
    }),
    graduation: PropTypes.shape({
      isYearEligible: PropTypes.string.isRequired,
    }),
  },
};

export type RedirectAfterLoginProp = InferProps<
  typeof RedirectAfterLoginPropTypes
>;

const RedirectAfterLogin: FC<RedirectAfterLoginProp> = ({
  applicantPortal,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof applicantPortal !== "undefined") {
      const isException = applicantPortal?.caseDetails.isExceptionCase;
      if (applicantPortal?.finance?.payment) {
        //User has submitted payment
        if (applicantPortal?.finance?.payment?.transTime) {
          navigate("/qualification-complete");
        } else {
          navigate("/payment");
        }
      } else if (isException) {
        navigate(`/QualificationI`);
      } else {
        checkElgibile();
      }
    }
  }, []);

  const checkElgibile = async () => {
    const eligibilityPreCheck = applicantPortal.eligibilityPreCheck;
    if (eligibilityPreCheck.eligibility?.isEligible) {
      if (eligibilityPreCheck.isStep2CSFailed) {
        navigate(`/verification-eligible-step2cs-failed`);
      } else if (
        LimboMessages.includes(eligibilityPreCheck.eligibility?.responseCode)
      ) {
        navigate(`/verification-ineligible`);
      } else if (eligibilityPreCheck.lastScreenName) {
        navigate(
          pageNavigation(eligibilityPreCheck.lastScreenName, applicantPortal)
        );
      } else {
        navigate(`/verification-eligible`);
      }
    } else {
      navigate(`/verification-ineligible`);
    }
  };

  return (
    <Paragraph style={{ textAlign: "center", margin: "80px 40px" }}>
      <Spin size="large" />
      <Title level={2}>Checking Eligibility</Title>
    </Paragraph>
  );
};

export default RedirectAfterLogin;
