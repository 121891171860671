import React, { FC, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Input,
  Row,
  Col,
  Form,
  DatePicker,
  Checkbox,
  Alert,
  Select,
  Typography,
} from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useAuth from "./auth/use-auth";
import { useFeatureFlags } from "../components/feature-flag-react-lite";
import { Helmet } from "react-helmet";
import {
  ApplicantBillingInformationCaseFeeDocument,
  ApplicantBillingInformationApplicationValidationDocument,
  ApplicantBillingInformationCountriesDocument,
  ApplicantBillingInformationAuthTokenDocument,
  ApplicantBillingInformationCheckPaymentExistsDocument,
} from "../generated";
const { Title, Paragraph, Text } = Typography;

const YearPicker = DatePicker.YearPicker;
const { Option } = Select;
var amount: number | null | undefined = 0;
var usmleID: string | null | undefined = "";
var showMessageOnFail = true;

let makePaymentDisabled = false;

function GetCaseFee() {
  const { loading, error, data } = useQuery(
    ApplicantBillingInformationCaseFeeDocument
  );
  console.log("data", data);

  if (error) {
    console.log("ERROR --> Billinginformation", error);
    makePaymentDisabled = true;
    //Alert, Type Error "Pink/Red"
    //Alert backend
    return (
      <Paragraph>
        <Paragraph style={{ marginTop: 10 }} type="danger">
          Error retrieving payment amount. Please contact ECFMG.
        </Paragraph>
      </Paragraph>
    );
  } else if (data !== undefined) {
    makePaymentDisabled = false;
    amount = data?.applicantPortal?.finance?.caseFee;
    usmleID = data?.applicantPortal?.applicant?.usmleId;
    return (
      <Paragraph>
        <Paragraph style={{ marginTop: 10 }}>
          You will be charged:{" "}
          <Text strong>{"$" + data?.applicantPortal?.finance?.caseFee}</Text>{" "}
          USD
        </Paragraph>
      </Paragraph>
    );
  } else {
    return <Paragraph></Paragraph>;
  }
}

function GetURLParams() {
  var urlParams = window.location.search;
  if (urlParams.includes("FAIL") && showMessageOnFail) {
    //showMessageOnFail = false;

    return (
      <Paragraph>
        <Alert
          message="Error processing payment"
          type="error"
          closable
          showIcon
        ></Alert>
      </Paragraph>
    );
  }

  return <Paragraph></Paragraph>;
}

const BillingInformation: FC<any> = (props) => {
  const { logout } = useAuth();
  const { GetFeatureFlagByName } = useFeatureFlags();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(
    ApplicantBillingInformationCountriesDocument
  );
  const {
    loading: loadingApplicationValidation,
    error: errorApplicationValidation,
    data: dataApplicationValidation,
  } = useQuery(ApplicantBillingInformationApplicationValidationDocument);

  //START TEST DATA
  //const [firstName, setFirstName] = useState("John");
  //const [middleName, setMiddleName] = useState("David");
  //const [lastName, setLastName] = useState("Doe");
  //const [billToStreet, setBillToStreet] = useState("123 Main");
  //const [billToState, setBillToState] = useState("PA");
  //const [billToZip, setBillToZip] = useState("12345");
  //const [billToCity, setBillToCity] = useState("Philadelphia");
  //const [amount, setAmount] = useState(123.45);
  //const [usmleID, setUsmleID] = useState("00123461");
  const [payPalSubmitMode] = useState("TEST");
  //END TEST DATA

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [billToStreet, setBillToStreet] = useState("");
  const [billToStreet2, setBillToStreet2] = useState("");
  const [billToCity, setBillToCity] = useState("");
  const [billToState, setBillToState] = useState("");
  const [billToZip, setBillToZip] = useState("");
  const [billToCountry, setBillToCountry] = useState("");
  const [billToCardNumber, setbillToCardNumber] = useState("");
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [billToCVV, setBillToCVV] = useState("");
  const [agreeToPay, setAgreeToPay] = useState(false);
  const [nonRefundCheckboxChecked, setNonRefundCheckboxChecked] =
    useState<boolean>(false);
  const [nonRefundCheckboxTimestamp, setNonRefundCheckboxTimestamp] =
    useState<string>("0");
  const [pleaseConfirmHidden, setPleaseConfirmHidden] = useState<boolean>(true);
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
  //const [amount, setAmount] = useState(0);

  const [secureToken, setSecureToken] = useState("");
  const [secureTokenID, setSecureTokenID] = useState("");

  const [isSent, setIsSent] = React.useState<boolean>(false);
  const [tokenReceived, setTokenReceived] = React.useState<boolean>(false);
  const [showAleadyPaidAlert, setShowAleadyPaidAlert] =
    useState<boolean>(false);

  const [getAuthToken, { loading: getAuthTokenLoaoing }] = useMutation(
    ApplicantBillingInformationAuthTokenDocument
  );

  const [checkPaymentExists, { loading: loadingCheckPaymentExists }] =
    useMutation(ApplicantBillingInformationCheckPaymentExistsDocument);

  const paypalEndpoint =
    process.env.REACT_APP_PAYPAL_URL !== null &&
    typeof process.env.REACT_APP_PAYPAL_URL !== "undefined"
      ? `${process.env.REACT_APP_PAYPAL_URL}`
      : "https://payflowlink.paypal.com";

  const paypalMode =
    process.env.REACT_APP_PAYPAL_MODE !== null &&
    typeof process.env.REACT_APP_PAYPAL_MODE !== "undefined"
      ? `${process.env.REACT_APP_PAYPAL_MODE}`
      : "TEST";

  useEffect(() => {
    console.log("LAST TO BE CALLED");

    var result = checkPaymentExists({})
      .then((res) => {
        //User has already paid, send them to the complete screen
        if (res?.data?.ApplicantPortal_checkPaymentExists) {
          navigate(`/qualification-complete`);
        }
      })
      .catch((error) => {
        //Do nothing. Keep user on billing page
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of screen on page load
  }, []);

  const missingRequiredData = () => {
    return (
      <Paragraph>
        <Paragraph>
          Looks like you haven't finished your application yet. Please log out
          and log back in.
        </Paragraph>
        <Button
          size="large"
          type="primary"
          key="console"
          onClick={() => {
            logout();
            navigate(`/`);
          }}
        >
          Log Out
        </Button>
      </Paragraph>
    );
  };

  const makePayment = async () => {
    if (nonRefundCheckboxChecked) {
      setPaymentLoading(true);
      setPleaseConfirmHidden(true);
      // A PCI Compliant means of credit card transaction

      //Step1: Send Everything but the sensitive payment information in this request
      // Server will send transaction information to payment gateway and get token to accept payment
      var results = await getAuthToken({
        variables: {
          amount: amount,
          billToFirstName: firstName,
          billToMiddleName: middleName,
          billToLastName: lastName,
          billToStreet: billToStreet, //valid
          billToStreet2: "",
          billToCity: billToCity,
          billToState: billToState,
          billToZip: billToZip, //valid
          usmleID: usmleID,
          acknowledgeTimestamp: nonRefundCheckboxTimestamp,
        },
      });

      if (
        results.data?.requestSecureToken?.alreadyPaid !== null &&
        results.data?.requestSecureToken?.alreadyPaid !== ""
      ) {
        console.log(
          "ERR MESSAGE ",
          results.data?.requestSecureToken?.alreadyPaid
        );
        setPaymentLoading(false);
        setShowAleadyPaidAlert(true);
        //Do stuff for people that already paid
      } else {
        console.log(
          `The PayFlowProSecureToken is: ${results.data?.requestSecureToken.secureToken}`
        );
        //  console.log(results.data?.requestSecureToken.secureToken);
        let token: string = results.data?.requestSecureToken.secureToken!;
        let tokenID: string = results.data?.requestSecureToken.secureTokenId!;

        setSecureToken(token);
        setSecureTokenID(tokenID);

        //Step2: After gettting token charge card
        setTokenReceived(true);

        //TODO CALL PAYFLOWPRO API
      }
    } else {
      setPleaseConfirmHidden(false);
    }
  };

  const SendConsoleAlert = (e: HTMLIFrameElement) => {
    console.log("iFRAME SRC CHANGE");
  };

  const submitOnMount = React.useCallback(
    (form?: HTMLFormElement) => {
      if (!form) {
        return;
      }
      form.submit();
      setIsSent(true);
    },
    [setIsSent]
  );

  const onFinish = (values: any) => {
    console.log("Success:", values);
    makePayment();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeExpYear = (date: moment.Moment | null) => {
    if (date !== null) {
      setExpirationYear(date.toDate().getFullYear().toString().slice(2, 4));
    } else {
      setExpirationYear("");
    }
  };

  const onChangeNonRefundCheckbox = (checked: any) => {
    setNonRefundCheckboxChecked(checked);

    if (checked) {
      setPleaseConfirmHidden(true);
      setNonRefundCheckboxTimestamp(
        moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss").toString()
      );
    }
  };

  const billingCountrySelect = () => {
    return !!data?.country ? (
      <Select
        id="billing-country-select"
        style={{ margin: "3px" }}
        placeholder={"Select Country"}
        onChange={(e: any) => setBillToCountry(e.toString())}
        filterOption={(input, option: any) =>
          option?.children
            ?.toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {data.country.map((country: any) => {
          return (
            <Option key={country._id} value={country.countryName}>
              {country.countryName}
            </Option>
          );
        })}
      </Select>
    ) : null;
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 },
    },
  };

  return (
    <Paragraph>
      <Helmet>
        <title>Billing Information</title>
      </Helmet>
      {GetFeatureFlagByName("BillingPreCheck") === "true" &&
      !dataApplicationValidation?.applicantPortal?.caseDetails
        ?.isRequiredDataProvided ? (
        missingRequiredData()
      ) : (
        <>
          <Row>
            <Col span={24}>
              <Title level={3}>Billing Information</Title>
            </Col>
          </Row>
          <GetURLParams></GetURLParams>
          {showAleadyPaidAlert ? (
            <Paragraph>
              <Alert
                message="You have already paid"
                type="error"
                closable
                showIcon
              ></Alert>
            </Paragraph>
          ) : (
            <Paragraph></Paragraph>
          )}
          <Form
            {...formItemLayout}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    { required: true, message: "Please enter first name" },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ margin: "3px" }}
                    type="text"
                    value={firstName}
                    name="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label="Middle Name"
                  name="middleName"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ margin: "3px" }}
                    type="text"
                    value={middleName}
                    name="middleName"
                    onChange={(e) => setMiddleName(e.target.value)}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    { required: true, message: "Please enter last name" },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ margin: "3px" }}
                    type="text"
                    value={lastName}
                    name="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label="Street Address 1"
                  name="billToStreet"
                  rules={[
                    { required: true, message: "Please enter street address" },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ margin: "3px" }}
                    type="text"
                    value={billToStreet}
                    name="billToStreet"
                    onChange={(e) => setBillToStreet(e.target.value)}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label="Street Address 2"
                  name="billToStreet2"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ margin: "3px" }}
                    type="text"
                    value={billToStreet2}
                    name="billToStreet2"
                    onChange={(e) => setBillToStreet2(e.target.value)}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label="City"
                  name="billToCity"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ margin: "3px" }}
                    type="text"
                    value={billToCity}
                    name="billToCity"
                    onChange={(e) => setBillToCity(e.target.value)}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label="State/Province"
                  name="billToState"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ margin: "3px" }}
                    type="text"
                    value={billToState}
                    name="billToState"
                    onChange={(e) => setBillToState(e.target.value)}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label="ZIP"
                  name="billToZip"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ margin: "3px" }}
                    type="text"
                    value={billToZip}
                    name="billToZip"
                    onChange={(e) => setBillToZip(e.target.value)}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label={"Country:"}
                  name="billToCountry"
                  rules={[{ required: true, message: "Please enter country" }]}
                  style={{ marginBottom: 0 }}
                >
                  {billingCountrySelect()}
                </Form.Item>

                <Form.Item
                  label={"Credit Card Number"}
                  name="billToCardNumber"
                  rules={[
                    { required: true, message: "Please enter credit card" },
                    {
                      pattern: new RegExp("^[0-9]+$"),
                      message: "Card number must be numerical",
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ margin: "3px" }}
                    type="text"
                    value={billToCardNumber}
                    name="billToCardNumber"
                    maxLength={19}
                    onChange={(e) => setbillToCardNumber(e.target.value)}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label={"Expiration Date:"}
                  required={true}
                  style={{ marginBottom: 0 }}
                >
                  <Input.Group compact style={{ margin: "3px" }}>
                    <Form.Item
                      name="expirationMonth"
                      rules={[
                        { required: true, message: "Please enter month" },
                        {
                          pattern: new RegExp("^[1-9]$|^0[1-9]$|^1[0-2]$"),
                          message: "Enter valid month",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        id="expirationMonth"
                        style={{ width: "100%" }}
                        type="text"
                        value={expirationYear}
                        name="expirationMonth"
                        placeholder="Month"
                        maxLength={2}
                        onChange={(e) => setExpirationMonth(e.target.value)}
                      ></Input>
                    </Form.Item>
                    <Form.Item
                      name="expirationYear"
                      rules={[{ required: true, message: "Please enter year" }]}
                      style={{ marginBottom: 0 }}
                    >
                      <YearPicker
                        format={"YY"}
                        placeholder="Year"
                        onChange={(date: any) => onChangeExpYear(date)}
                      ></YearPicker>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>

                <Form.Item
                  label="CVV Code"
                  name="billToCVV"
                  rules={[
                    { required: true, message: "Please enter valid CVV Code" },
                    {
                      pattern: new RegExp("^[0-9]+$"),
                      message: "CVV Code must be numerical",
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ margin: "3px" }}
                    type="text"
                    value={billToCVV}
                    name="billToCVV"
                    maxLength={4}
                    onChange={(e) => setBillToCVV(e.target.value)}
                  ></Input>
                </Form.Item>

                <Row>
                  <Col xs={24} sm={5}></Col>
                  <Col xs={24} sm={19}>
                    <GetCaseFee />
                    {/*<Checkbox value={agreeToPay} onChange={(e) => setAgreeToPay(e.target.checked)}>
                  I understand this fee is non-refundable, regardless of the outcome of my application, and I agree to pay the charge.
                </Checkbox>*/}
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={5}></Col>
                  <Col xs={24} sm={19}>
                    <Checkbox
                      checked={nonRefundCheckboxChecked}
                      onChange={(e) =>
                        onChangeNonRefundCheckbox(e.target.checked)
                      }
                    >
                      <Text strong>
                      I understand this fee is non-refundable, regardless of the
                      outcome of my application, and I agree to pay the charge.
                      </Text>
                    </Checkbox>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={5}></Col>
                  <Col xs={24} sm={19}>
                    <label
                      hidden={pleaseConfirmHidden}
                      style={{ color: "red" }}
                    >
                      Please confirm you understand this fee is non-refundable
                    </label>
                  </Col>
                </Row>
                <Paragraph style={{ textAlign: "right" }}>
                  <Button
                    id="payment-continue-button"
                    style={{ marginTop: "10px" }}
                    type="primary"
                    htmlType="submit"
                    disabled={makePaymentDisabled}
                    loading={paymentLoading}
                    size="large"
                  >
                    Make Payment
                  </Button>
                </Paragraph>
              </Col>
            </Row>
          </Form>
          {/*Go to this link to learn more about the code below: https://developer.paypal.com/docs/payflow/integration-guide/secure-token/?mark=transp#http-form-post-examples*/}
          <>
            {/*TEST DATA*/}
            {/*<Paragraph>
        <iframe
          name={"payPalForm"}
          title="payPalTitle"
          onLoad={(e) =>
            SendConsoleAlert(e.currentTarget as HTMLIFrameElement)
          }
        />
        {!isSent && tokenReceived && (
          <form
            id={"formTest"}
            method={"post"}
            action={"https://payflowlink.paypal.com"}
            ref={(e) => submitOnMount(e as HTMLFormElement)}
          >
            <Title>This is some text</Title>
            <input type={"hidden"} value={"TEST"} name={"MODE"} />
            <input type={"hidden"} value={secureToken} name={"SECURETOKEN"} />
            <input type={"hidden"} value={secureTokenID} name={"SECURETOKENID"} />
            <input type={"hidden"} value={"S"} name={"TRXTYPE"} />
            <input type={"hidden"} value={"C"} name={"TENDER"} />
            <input type={"hidden"} value={"378282246310005"} name={"ACCT"} />
            <input type={"hidden"} value={amount} name={"AMT"} />
            <input type={"hidden"} value={"1220"} name={"EXPDATE"} />
            <input type={"hidden"} value={"123"} name={"CVV2"} />
            <input type={"hidden"} value={"true"} name={"SILENTTRAN"} />
            <input type={"hidden"} value={"HIGH"} name={"VERBOSITY"} />
            <input type={"hidden"} value={"TRUE"} name={"CSCREQUIRED"} />
            <input type={"hidden"} value={"TRUE"} name={"DISABLERECEIPT"} />
            <input type={"submit"} value={"Submit"} />
          </form>
        )}
      </Paragraph>*/}
          </>
          <>
            <Paragraph>
              <iframe
                name={"payPalForm"}
                title="payPalTitle"
                style={{ width: 0, height: 0 }}
                onLoad={(e) =>
                  SendConsoleAlert(e.currentTarget as HTMLIFrameElement)
                }
              />
              {!isSent && tokenReceived && (
                <form
                  id={"formTest"}
                  method={"post"}
                  action={paypalEndpoint}
                  ref={(e) => submitOnMount(e as HTMLFormElement)}
                >
                  <Title>This is some text</Title>
                  <input type={"hidden"} value={paypalMode} name={"MODE"} />
                  <input
                    type={"hidden"}
                    value={secureToken}
                    name={"SECURETOKEN"}
                  />
                  <input
                    type={"hidden"}
                    value={secureTokenID}
                    name={"SECURETOKENID"}
                  />
                  <input type={"hidden"} value={"S"} name={"TRXTYPE"} />
                  <input type={"hidden"} value={"C"} name={"TENDER"} />
                  <input
                    type={"hidden"}
                    value={billToCardNumber}
                    name={"ACCT"}
                  />
                  <input type={"hidden"} value={amount ?? 0} name={"AMT"} />
                  <input
                    type={"hidden"}
                    value={expirationMonth.padStart(2, "0") + expirationYear}
                    name={"EXPDATE"}
                  />
                  <input type={"hidden"} value={billToCVV} name={"CVV2"} />
                  <input type={"hidden"} value={"true"} name={"SILENTTRAN"} />
                  <input type={"hidden"} value={"HIGH"} name={"VERBOSITY"} />
                  <input type={"hidden"} value={"TRUE"} name={"CSCREQUIRED"} />
                  <input
                    type={"hidden"}
                    value={"TRUE"}
                    name={"DISABLERECEIPT"}
                  />
                  <input type={"submit"} value={"Submit"} />
                </form>
              )}
            </Paragraph>
          </>
        </>
      )}
    </Paragraph>
  );
};

export default BillingInformation;
