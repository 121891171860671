import { FC } from "react";
import { Modal, Button, Typography, Table, Grid } from "antd";
import { getPhysicianName } from "../constants";
const { useBreakpoint } = Grid;
const { Text, Paragraph } = Typography;
interface IProps {
  showEncounterHistoryModal: boolean;
  setShowEncounterHistoryModal: (value: boolean) => void;
  statusHistoryColumns: any;
  statusHistory: any;
  selectedRecord: any;
}
export const PhysicianStatusHistoryModal: FC<IProps> = (props) => {
  const screen = useBreakpoint();
  return (
    <Modal
      title={"Status History"}
      visible={props.showEncounterHistoryModal}
      closable={false}
      // width={ screen.sm ? "60vw" : "90vw" }
      footer={[
        <Button
          size="large"
          key="close"
          onClick={() => props.setShowEncounterHistoryModal(false)}
        >
          Close
        </Button>,
      ]}
    >
      <Paragraph>
        <Text>Physician:</Text>{" "}
        <Text strong>
          {getPhysicianName(
            props.selectedRecord?.physicianLastNameProvidedByApplicant,
            props.selectedRecord?.physicianRestOfNameProvidedByApplicant
          )}
        </Text>
        <br />
        <Text>Encounter ID:</Text>{" "}
        <Text strong>{props.selectedRecord?.encounterId}</Text>
        <br />
        <br />
        <Table
          columns={props.statusHistoryColumns}
          dataSource={props.statusHistory}
        ></Table>
        <Paragraph>
          All times are calculated using Coordinated Universal Time (UTC).
        </Paragraph>
      </Paragraph>
    </Modal>
  );
};
