import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, Col, Row, Typography, Grid } from "antd";
import { useNavigate } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  CEX_STATUS,
  CEX_STATUS_DETAIL,
  getPhysicianName,
  PATHWAY6_DATE_FORMAT,
} from "../constants";
import moment from "moment";
import { PhysicianStatusHistoryModal } from "./physician-status-history-modal";
const { Title, Paragraph, Text } = Typography;
const { useBreakpoint } = Grid;

const ComponentPropTypes = {
  minicex: PropTypes.arrayOf(
    PropTypes.shape({
      minicexId: PropTypes.string.isRequired,
      encounterId: PropTypes.string.isRequired,
      physicianEmail: PropTypes.string.isRequired,
      physicianLastNameProvidedByApplicant: PropTypes.string.isRequired,
      physicianRestOfNameProvidedByApplicant: PropTypes.string.isRequired,
      physicianLastNameProvidedByPhysician: PropTypes.string.isRequired,
      physicianRestOfNameProvidedByPhysician: PropTypes.string.isRequired,
      usmleId: PropTypes.string.isRequired,
      applicantLastName: PropTypes.string.isRequired,
      applicantRestOfName: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      completedAt: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      statusDetail: PropTypes.string.isRequired,
      stateModel: PropTypes.arrayOf(
        PropTypes.shape({
          state: PropTypes.string.isRequired,
          timestamp: PropTypes.string.isRequired,
        })
      ),
      allowedActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    })
  ),
  loading: PropTypes.bool.isRequired,
};

export interface IProps {
  __typename?: "PhysicianEncounterHistoryListTable";
  minicex?: {
    minicexId: string;
    encounterId: string;
    physicianEmail: string;
    physicianLastNameProvidedByApplicant: string;
    physicianRestOfNameProvidedByApplicant: string;
    physicianLastNameProvidedByPhysician: string;
    physicianRestOfNameProvidedByPhysician: string;
    usmleId: string;
    applicantLastName: string;
    applicantRestOfName: string;
    createdAt: string;
    completedAt: string;
    state: string;
    statusDetail: string;
    stateModel?: {
      state: string;
      timestamp: string;
    }[];
    allowedActions: string[];
  }[];
  loading: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const PhysicianEncounterHistoryListTable: FC<ComponentProps> = ({
  minicex,
  loading,
}) => {
  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  const [stateModel, setStateModel] = useState<any>();
  const [showEncounterHistoryModal, setShowEncounterHistoryModal] =
    useState<boolean>(false);

  useEffect(() => {
    var filteredStateModel = selectedRow?.stateModel.filter(
      (item: { state: string }) =>
        item.state !== "MINICEX_CREATED_PHYSICIAN_INVITE_PENDING"
    );
    setStateModel(filteredStateModel);
  }, [selectedRow]);

  const columns = [
    {
      title: "ID",
      dataIndex: "encounterId",
      key: "encounterId",
      width: "auto",
    },
    {
      title: "Name from Applicant",
      key: "physicianNameProvidedByApplicant",
      width: "auto",
      render: (physicianNameProvidedByApplicant: any, record: any) => (
        <Text>
          {getPhysicianName(
            record?.physicianLastNameProvidedByApplicant,
            record?.physicianRestOfNameProvidedByApplicant
          )}
        </Text>
      ),
    },
    {
      title: "Name from Physician",
      key: "physicianNameProvidedByPhysician",
      width: "auto",
      render: (physicianNameProvidedByPhysician: any, record: any) => (
        <Text>
          {getPhysicianName(
            record?.physicianLastNameProvidedByPhysician,
            record?.physicianRestOfNameProvidedByPhysician
          )}
        </Text>
      ),
    },
    {
      title: "E-mail",
      dataIndex: "physicianEmail",
      key: "physicianEmail",
      width: "auto",
    },
    {
      title: "Last Update",
      dataIndex: "completedAt",
      key: "completedAt",
      width: "auto",
      render: (completedAt: any, record: any) => (
        <>
          {record.completedAt !== undefined ? (
            <Text style={{ whiteSpace: 'nowrap'}}>
              {moment(record.completedAt).utc().format(PATHWAY6_DATE_FORMAT)}
            </Text>
          ) : (
            <Text></Text>
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      width: "auto",
      render: (status: any, record: any) => (
        <>
          {record.state !== undefined ? (
            <Row wrap={false}>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <InfoCircleOutlined
                  style={{ fontSize: "18px" }}
                  onClick={(event: any) => {
                    setSelectedRow(record);
                    setShowEncounterHistoryModal(true);
                  }}
                />
              </Col>
              <Col offset={1}>
                <Text>{getStatus(CEX_STATUS, record.state)}</Text>
              </Col>
            </Row>
          ) : (
            <Text></Text>
          )}
        </>
      ),
    },
    {
      title: "Status Detail",
      dataIndex: "statusDetail",
      key: "statusDetail",
      width: "auto",
      render: (statusDetail: any, record: any) => (
        <>
          {record.state !== undefined ? (
            <Text>{getStatusDetail(CEX_STATUS_DETAIL, record)}</Text>
          ) : (
            <Text></Text>
          )}
        </>
      ),
    },
  ];

  const screen = useBreakpoint();
  const historyColumns = [
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      width: screen.sm ? 250 : 300,
      render: (state: any, record: any) => (
        <>
          {record.state !== undefined ? (
            <Text>{getStatus(CEX_STATUS, record.state) + " "}</Text>
          ) : (
            <Text></Text>
          )}
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "timestamp",
      width: "auto",
      render: (timestamp: any, record: any) => (
        <>
          {record.timestamp !== undefined ? (
            <Text style={{ whiteSpace: 'nowrap'}}>
              {moment(record.timestamp)
                .utc()
                .format(`${PATHWAY6_DATE_FORMAT}, h:mm:ss a`)}
            </Text>
          ) : (
            <Text></Text>
          )}
        </>
      ),
    },
  ];

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  const getStatusDetail = (obj: any, record: any) => {
    switch (record.state) {
      case "MINICEX_CREATED_PHYSICIAN_INVITE_SENT":
      case "MINICEX_CREATED_PHYSICIAN_REGISTERED": {
        //Calculate difference in days from now to expiration
        var timeDifferenceInDays = moment(record.expiresAt)
          .utc()
          .diff(moment().utc().toDate().getTime(), "days");

        var textWithInviteDate = obj[record.state].replace(
          "*InviteDate*",
          moment(record.createdAt).utc().format(PATHWAY6_DATE_FORMAT)
        );

        if (timeDifferenceInDays <= 0) {
          return textWithInviteDate.replace("*NumberOfDays*", "less than one");
        } else {
          return textWithInviteDate.replace(
            "*NumberOfDays*",
            timeDifferenceInDays
          );
        }
      }
      case "MINICEX_ACCEPTED": {
        //Calculate how many milliseconds between today and expiration data.
        var expiresAtInMilliseconds =
          +record.expiresAt - moment().toDate().getTime();
        //Convert milliseconds to full days
        var expiresAtInDays = Math.trunc(
          expiresAtInMilliseconds / (60 * 60 * 24 * 1000)
        );
        if (expiresAtInDays < 0) {
          expiresAtInDays = 0;
        }

        return obj[record.state].replace("*NumberOfDays*", expiresAtInDays);
      }
      default: {
        return obj[record.state];
      }
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={minicex as any}
        size="middle"
        loading={loading}
      ></Table>

      <PhysicianStatusHistoryModal
        selectedRecord={selectedRow}
        setShowEncounterHistoryModal={setShowEncounterHistoryModal}
        showEncounterHistoryModal={showEncounterHistoryModal}
        statusHistory={stateModel}
        statusHistoryColumns={historyColumns}
      />
      {/* <Modal
        title={"Status History"}
        visible={showEncounterHistoryModal}
        closable={false}
        footer={[
          <Button
            size="large"
            key="close"
            onClick={() => setShowEncounterHistoryModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <Paragraph>
          <Text strong>Physician</Text>:{" "}
          {getPhysicianName(
            selectedRow?.physicianLastNameProvidedByApplicant,
            selectedRow?.physicianRestOfNameProvidedByApplicant
          )}
          <br />
          <Text strong>Encounter ID</Text>: {selectedRow?.encounterId}
          <br />
          <br />
          <Table columns={historyColumns} dataSource={stateModel}></Table>
          <Paragraph>
            All times are calculated using Coordinated Universal Time (UTC).
          </Paragraph>
        </Paragraph>
      </Modal> */}
    </>
  );
};
export default PhysicianEncounterHistoryListTable;
