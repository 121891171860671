import { FC } from "react";
import { Result, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { PastAttemptsType } from "../generated";
import { InferProps } from "prop-types";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { UserOutlined } from "@ant-design/icons";
import RejectionHistory from "./rejection-history";
const { Title, Paragraph, Text, Link } = Typography;

const ComponentPropTypes = {};

export interface IProps {
  __typename?: "VerificationEligibleStep2CSFailed";
  pastAttempts?: PastAttemptsType[];
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const VerificationEligibleStep2CSFailed: FC<ComponentProps> = ({
  pastAttempts,
}) => {
  const navigate = useNavigate();
  const screens = useBreakpoint();

  let getMessage = () => {
    let title: string | JSX.Element =
      "You Must Apply to Pathway 6 to Meet ECFMG’s Requirements";
    let subTitle = "";
    let extra = (
      <Paragraph
        style={{
          margin: "auto",
          fontSize: "14px",
          color: "rgba(0, 0, 0, 1)",
          width: screens.lg ? "50%" : "100%",
          alignItems: "center",
        }}
      >
        <Paragraph>
          You have failed Step 2 CS one or more times. In order to meet the
          clinical and communication skills requirements for ECFMG
          Certification, you are <Text strong>required</Text> to apply to
          Pathway 6: Evaluation of Clinical Patient Encounters by Licensed
          Physicians. Before proceeding with this application, you must review
          the{" "}
          <Link
            href={
              "https://www.ecfmg.org/certification-pathways/pathway-6.html"
            }
            target="_blank"
            rel="noreferrer"
          >
            eligibility requirements and application information for Pathway 6
          </Link>
          .
        </Paragraph>
        <Paragraph style={{ textAlign: "center" }}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              navigate(`/Qualification6`);
            }}
          >
            Continue
          </Button>
        </Paragraph>
      </Paragraph>
    );

    return { title: title, subtitle: subTitle, extra: extra };
  };

  return (
    <>
      <Result
        icon={<UserOutlined style={{ color: "#61C8D7" }} />}
        title={
          <Paragraph style={{ display: "inline-block", margin: "auto", color: "#333333" }}>
            {getMessage().title}
          </Paragraph>
        }
        subTitle={
          
          getMessage().subtitle ? (
            <Paragraph style={{ display: "inline-block", margin: "auto" }}>
              <Paragraph
                style={{
                  margin: "auto",
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 1)",
                  width: screens.lg ? "40%" : "",
                }}
              >
                <RejectionHistory pastAttempts={pastAttempts}/>
                {getMessage().subtitle}
              </Paragraph>
            </Paragraph>
          ) : (
            <></>
          )
        }
        extra={
          <>
            <Paragraph style={{ display: "inline-block" }}>
              <Paragraph
                style={{
                  textAlign: "initial",
                  margin: "auto",
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 1)",
                  width: "75%",
                }}
              >
                {getMessage().extra}
              </Paragraph>
            </Paragraph>
          </>
        }
      />
    </>
  );
};

const exportVerificationEligibleStep2CSFailed = {
  Component: VerificationEligibleStep2CSFailed,
};

export default exportVerificationEligibleStep2CSFailed;
