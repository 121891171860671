import React, { useState, useEffect, FC } from "react";
import {
  Radio,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Collapse,
  message,
  Typography,
} from "antd";
import { AuthorityCardList } from "../authority-card-list";
import { QualificationC1 } from "./qualification-c1";
import { QualificationC2 } from "./qualification-c2";
import moment from "moment-timezone";
import { useMutation } from "@apollo/client/react";
import _ from "lodash";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import { DATE_FORMAT } from "../constants";
import { useFeatureFlags } from "../feature-flag-react-lite";
import { Helmet } from "react-helmet";
import {
  ApplicantQualificationAApplicantPortal_Pathway1aDocument,
  Pathway1,
} from "../../generated";
const { Title, Paragraph, Link, Text } = Typography;

const { Panel } = Collapse;
const screenName = "Pathway-1a";

//use to store value after c1 is submitted when adding new
var tempNewAuthorities: any;

const ComponentPropTypes = {
  pathway1: PropTypes.shape({
    userResponse: PropTypes.shape({
      isCurrentlyLicensed: PropTypes.bool,
      wasLicensedInRecentPast: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        data: PropTypes.shape({
          licenseIssueDate: PropTypes.string,
          licenseExpiryDate: PropTypes.string,
          hasDisciplinaryAction: PropTypes.bool,
          isCurrentlyLicensed: PropTypes.bool,
          licensureDocumentStatus: PropTypes.string,

          regulatoryAuthorityRef: PropTypes.shape({
            _id: PropTypes.number,
            orgName: PropTypes.string,
            countryName: PropTypes.string,
          }),

          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
  refetch: PropTypes.func.isRequired,
};

export interface IProps {
  __typename?: "QualificationA";
  pathway1?: Pathway1;

  refetch: () => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const QualificationA: FC<ComponentProps> = ({ pathway1, refetch }) => {
  const [isLicensed, setIsLicensed] = useState<boolean | undefined>(undefined);
  const [editMode, setEditMode] = useState<string>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalPartTwo, setShowModalPartTwo] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [authorities, setAuthorities] = useState<any>([]);

  const { GetFeatureFlagByName } = useFeatureFlags();

  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of screen on page load
    if (pathway1?.userResponse?.isCurrentlyLicensed) {
      setAuthorities(pathway1?.applicantProvidedData);
    }
    authorityRadioOnChange(pathway1?.userResponse?.isCurrentlyLicensed);
    //Handles if back button is pushed. Reload the page
    if (navigationType === "POP") {
      navigate(location.pathname);
      refetch();
    }
    refetch();
  }, []);

  const [UpdateAuthorities, { loading: updateAuthoritiesLoading }] =
    useMutation(ApplicantQualificationAApplicantPortal_Pathway1aDocument);

  const [selectedAuthority, setSelectedAuthority] = useState<any>();

  const deleteAuthority = async (authorityId: string) => {
    setEditMode(undefined);
    setAuthorities(
      authorities.filter(
        (authority: { _id: any }) => authority._id !== authorityId
      )
    );
  };

  const editAuthority = async (authorityId: string) => {
    setEditMode("edit");
    const auth = authorities.find(
      (authority: { _id: string }) => authority._id === authorityId
    );
    setSelectedAuthority(auth);
    setShowModal(true);
  };

  const addAuthority = async () => {
    setSelectedAuthority(undefined);
    setEditMode("add");
    setShowModal(true);
  };

  const modalPartOneSubmitted = async (formValues: any) => {
    if (editMode === "add") {
      var newAuthority = {
        _id: _.uniqueId(),
        data: {
          regulatoryAuthorityRef: formValues.regulatoryAuthorityRef,
          licenseIssueDate: formValues.licenseIssueDate,
          isCurrentlyLicensed: formValues.isCurrentlyLicensed,
          licenseExpiryDate: formValues.licenseExpiryDate,
          hasDisciplinaryAction: formValues.hasDisciplinaryAction,
          licensureDocumentStatus: undefined,
        },
      };
      tempNewAuthorities = authorities.concat(newAuthority);
      setSelectedAuthority(newAuthority);
      setShowModalPartTwo(true);
    } else if (editMode === "edit") {
      //reflect the changes on C1 onto Q-A screen after editing
      //deep copy
      const editedAuthority = JSON.parse(
        JSON.stringify(
          authorities.find(
            (authority: { _id: string }) => authority._id === formValues._id
          )
        )
      );

      editedAuthority.data.licenseIssueDate = moment(
        convertDate(formValues.licenseIssueDate),
        DATE_FORMAT
      ).toString(); //moment(displayValues.dateIssued).format('MM/DD/YYYY')
      editedAuthority.data.regulatoryAuthorityRef =
        formValues.regulatoryAuthorityRef;
      editedAuthority.data.isCurrentlyLicensed = formValues.isCurrentlyLicensed;
      editedAuthority.data.licenseExpiryDate =
        formValues.licenseExpiryDate ?? undefined;
      editedAuthority.data.hasDisciplinaryAction =
        formValues.hasDisciplinaryAction;

      setSelectedAuthority(editedAuthority);
      //update authorities
      setAuthorities(
        authorities.map((auth: any) =>
          editedAuthority._id === auth._id ? editedAuthority : auth
        )
      );

      setShowModalPartTwo(true);
    }
  };

  const modalPartTwoSubmitted = async (formValues: any) => {
    form.resetFields(["authorityList"]);

    selectedAuthority.data.licensureDocumentStatus =
      formValues.licensureDocumentStatus;

    selectedAuthority.data.documents = formValues.documents;

    if (editMode === "add") {
      setAuthorities(authorities.concat(selectedAuthority));
    } else if (editMode === "edit") {
      setAuthorities(
        authorities.map((auth: any) =>
          selectedAuthority._id === auth._id ? selectedAuthority : auth
        )
      );
    }

    setShowModal(false);
    setShowModalPartTwo(false);
    setEditMode(undefined);
  };

  const convertDate = (dateLicesnsed: string) => {
    var date = new Date(dateLicesnsed);
    var parsedDate = "";
    parsedDate += (date.getMonth() + 1).toString().padStart(2, "0"); // Month
    parsedDate += date.getDate().toString().padStart(2, "0"); // Day
    parsedDate += date.getFullYear().toString(); // Year

    return parsedDate;
  };

  const handleUpdateAuthorities = async () => {
    var supportingData = JSON.parse(JSON.stringify(authorities));

    if (supportingData.length > 0) {
      supportingData.map((datum: any) => {
        delete datum._id;
        delete datum.__typename;
        delete datum.data.__typename;

        if (datum.data.regulatoryAuthorityRef) {
          delete datum.data.regulatoryAuthorityRef.__typename;
        }

        if (
          datum.data.licensureDocumentStatus === "requestedSentToECFMG" ||
          datum.data.licensureDocumentStatus === "requestedWillSend"
        ) {
          datum.data.documents = [];
        }
        // if (datum.data.documents && datum.data.documents.length > 0)
        else {
          if (_.isEmpty(datum.data.documents[1])) {
            datum.data.documents = [datum.data.documents[0]]; //remove the translated document element if it is not provided
          }
          datum.data.documents.map((doc: any) => {
            delete doc.__typename;
          });
        }

        if (GetFeatureFlagByName("LicenseDateTimeConversionFix") === "true") {
          if (datum.data.licenseIssueDate) {
            datum.data.licenseIssueDate = moment(
              datum.data.licenseIssueDate
            ).format("MM/DD/YYYY");
          }
          if (datum.data.licenseExpiryDate) {
            datum.data.licenseExpiryDate = moment(
              datum.data.licenseExpiryDate
            ).format("MM/DD/YYYY");
          }
        }
      });
    }

    await UpdateAuthorities({
      variables: {
        isCurrentlyLicensed: isLicensed!,
        supportingData: supportingData,
      },
    })
      .then((data) => {
        if (isLicensed) {
          navigate(`/Qualification-review`);
        } else {
          navigate(`/QualificationB`);
        }
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference =
          error?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${error.toString()} - Reference ID : ${graphQLErrorReference}`
        );
      });
  };

  const modalPartOneCancelled = async () => {
    setShowModal(false);
  };

  const modalPartTwoCancelled = async () => {
    setShowModalPartTwo(false);
    setSelectedAuthority(undefined);
    setShowModal(false);
  };

  const authorityRadioOnChange = (value: any) => {
    setIsLicensed(value);
    form.setFieldsValue({
      isLicensed: value,
    });
  };

  const [form] = Form.useForm();

  const checkEmptyAuthorityList = (rule: any, value: any) => {
    if (authorities.length === 0) {
      return Promise.reject("Please add at least one authority");
    }
    return Promise.resolve();
  };

  return (
    <>
      <Helmet>
        <title>Pathway 1</title>
      </Helmet>
      <Row>
        <Col span={24}>
          <Title level={4}>
            Pathway 1: Already Licensed to Practice Medicine in Another Country
          </Title>
        </Col>
      </Row>

      <Form form={form} onFinish={handleUpdateAuthorities}>
        <Row>
          <Col span={24}>
            <Paragraph>
              If you held a license/registration (full or provisional) to
              practice medicine without supervision in any country/jurisdiction
              at any time on or after January 1, 2020, you are{" "}
              <Text strong>required</Text> to apply to Pathway 1 in order to
              meet the clinical and communication skills requirements for ECFMG
              Certification.
            </Paragraph>
            <Paragraph>
              Before proceeding, you must review the{" "}
              <Link
                href={
                  "https://www.ecfmg.org/certification-pathways/pathway-1.html"
                }
                target="_blank"
                rel="noreferrer"
              >
                eligibility requirements and application information{" "}
              </Link>{" "}
              for this Pathway.
            </Paragraph>
            <Paragraph>
              Do you currently hold a license/registration (full or provisional)
              to practice medicine without supervision in any
              country/jurisdiction?{" "}
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="isLicensed"
              rules={[{ required: true, message: "Please select an answer" }]}
            >
              <Radio.Group
                onChange={(e) => authorityRadioOnChange(e.target.value)}
                value={isLicensed}
                style={{ display: "block" }}
              >
                <Radio
                  id="yes-option"
                  style={{ display: "block" }}
                  value={true}
                >
                  <Text strong>Yes</Text>
                  {isLicensed === true && (
                    <>
                      <Paragraph
                        style={{
                          whiteSpace: "normal",
                          paddingLeft: "25px",
                          marginTop: "20px",
                        }}
                      >
                        <Title level={4}>Authorities</Title>
                        <Paragraph>
                          Please provide the requested information for{" "}
                          <Text strong>all</Text> authorities with which you
                          have been licensed/registered to practice medicine
                          without supervision since January 1, 2020. The
                          licenses do not need to be currently valid, and your
                          date of initial registration can be before January 1,
                          2020; however, the licenses/registrations must still
                          have been valid on or after January 1, 2020. Please
                          note that failure to provide complete and correct
                          information on all such licenses/registrations could
                          result in rejection of your application and/or a
                          finding of{" "}
                          <Link
                            href={
                              "https://www.ecfmg.org/resources/irregular-behavior.html"
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            irregular behavior
                          </Link>
                          .
                        </Paragraph>
                        <Form.Item
                          name="authorityList"
                          rules={[{ validator: checkEmptyAuthorityList }]}
                        >
                          <AuthorityCardList
                            onEdit={(regulatoryAuthorityId: string) =>
                              editAuthority(regulatoryAuthorityId)
                            }
                            onDelete={(regulatoryAuthorityId: string) =>
                              deleteAuthority(regulatoryAuthorityId)
                            }
                            authorities={authorities}
                          />
                        </Form.Item>

                        <Paragraph
                          style={{ textAlign: "right", marginTop: "20px" }}
                        >
                          <Button
                            id="add-authority-button"
                            onClick={() => addAuthority()}
                            disabled={!isLicensed}
                            size='large'
                          >
                            Add Authority
                          </Button>
                        </Paragraph>
                      </Paragraph>
                    </>
                  )}
                </Radio>
                <Radio
                  id="no-option"
                  style={{ display: "block" }}
                  value={false}
                >
                  <Text strong>No</Text>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              id="qa-continue-button"
              type="primary"
              size='large'
              htmlType="submit"
              loading={updateAuthoritiesLoading}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal
        title="Add/Edit Registration/License"
        visible={showModal}
        // width="80vw"
        okText="Continue"
        footer={null}
        closable={false}
      >
        <Paragraph>
          {!showModalPartTwo ? (
            <QualificationC1
              key={selectedAuthority?._id ?? undefined}
              initialValues={selectedAuthority}
              onFormSubmitSuccess={(formValues: any) =>
                modalPartOneSubmitted(formValues)
              }
              onFormCancel={() => modalPartOneCancelled()}
            />
          ) : (
            <QualificationC2
              screenName={screenName}
              isCurrentlyLicensed={isLicensed}
              wasLicensedInRecentPast={false} //false because this is license screen
              savedAuthorities={
                editMode === "edit" ? authorities : tempNewAuthorities
              }
              currentID={selectedAuthority._id}
              key={selectedAuthority?._id ?? undefined}
              initialValues={selectedAuthority}
              onFormSubmitSuccess={(formValues: any) =>
                modalPartTwoSubmitted(formValues)
              }
              onFormCancel={() => modalPartTwoCancelled()}
            />
          )}
        </Paragraph>
      </Modal>
    </>
  );
};

export default {
  Component: QualificationA,
};
