import { useState, useEffect, FC } from "react";
import { Row, Col, Card, Steps, Spin, Typography } from "antd";
import Meta from "antd/lib/card/Meta";
import { InferProps } from "prop-types";
import moment from "moment";
import { CaseDetailsStates, DATE_FORMAT, PATHWAYS } from "./constants";
import _ from "lodash";
import { Helmet } from "react-helmet";
import {
  ProgressFieldsPropTypes,
  FinancePropTypes,
  GraduationPropTypes,
} from "./component-types/common-types";
import {
  CaseDetailsPropTypes,
  Pathway2PropTypes,
} from "./component-types/qualification-complete-types";
import RejectionReasonsFactory from "../util/rejection-reason/rejection-reasons-factory";
import { ApplicantPortalResponseType } from "../generated";
import StatusMappingsFactory from "../util/status-mappings/status-mappings-factory";
const { Title, Paragraph, Text, Link } = Typography;

const { Step } = Steps;

const ComponentPropTypes = {
  applicantPortal: {
    ProgressFieldsPropTypes,
    FinancePropTypes,
    GraduationPropTypes,
    CaseDetailsPropTypes,
    Pathway2PropTypes,
  },
};

export interface IProps {
  __typename?: "QualificationComplete";
  applicantPortal: ApplicantPortalResponseType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;
const QualificationComplete: FC<ComponentProps> = ({ applicantPortal }) => {
  const oetStatus = applicantPortal?.progress?.englishEligibility;
  const pathwayStatus = applicantPortal?.progress?.pathwayEligibility;
  const generalEligibilityStatus =
    applicantPortal?.progress?.generalEligibility;
  const caseDetails = applicantPortal?.caseDetails;
  const finance = applicantPortal?.finance;
  const hasPassedCSExamInRecentPast =
    applicantPortal?.pathway2?.userResponse?.hasPassedCSExamInRecentPast;
  const isSchoolEMSWPMember =
    applicantPortal?.graduation?.schoolEligibility?.isSchoolEMSWPMember;
  const steps = {
    Submission: 0,
    Processing: 1,
    Decision: 2,
  };

  type REJECTION_REASON_TYPE = {
    key: string;
    value: string;
  };

  let rejectReasons: any = [];
  _.forEach(caseDetails?.outputReason, (reason: any) => {
    var reasonText;
    if (reason.includes("Applicant failed more than once")) {
      reason = "Applicant failed more than once";
    }
    reasonText = _.find(RejectionReasonsFactory(applicantPortal.uiConfig), {
      key: reason,
    }) as REJECTION_REASON_TYPE;

    if (!rejectReasons.includes(reasonText?.value)) {
      rejectReasons.push(reasonText?.value);
    }
  });

  let pathwaySubmissionText = () => {
    let text = <></>;

    switch (caseDetails?.currentPathway) {
      case PATHWAYS.Pathway2:
        if (
          hasPassedCSExamInRecentPast !== undefined &&
          !hasPassedCSExamInRecentPast
        ) {
          text = (
            <Paragraph>
              <Paragraph>
                <Text strong>
                  Pathway 2{" "}
                  <Link
                    href="https://www.ecfmg.org/certification-pathways/osce.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Objective Structured Clinical Examination (OSCE) Attestation
                  </Link>
                </Text>
              </Paragraph>
              <Paragraph>
                If you are a student or if you are a graduate and your Final
                Medical Diploma has not yet been issued, you must demonstrate
                that you have successfully completed the OSCE by having your
                medical school complete the{" "}
                <Link
                  href="https://www.ecfmg.org/certification-pathways/osce.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Objective Structured Clinical Examination (OSCE) Attestation
                  form
                </Link>
                . The form must be completed by an authorized official of your
                medical school and sent directly to ECFMG by the medical school.
              </Paragraph>
              <Paragraph>
                <Text strong>
                  Please note that it is your responsibility to ensure that your
                  medical school completes the OSCE Attestation form in support
                  of your application; your application cannot be approved until
                  the completed attestation is received and accepted by ECFMG.
                  The form must be received by ECFMG no later than January 31,
                  2025.
                </Text>
              </Paragraph>
              <Paragraph>
                If you have submitted your Final Medical Diploma to ECFMG, your
                school does not need to complete this form. Submission of the{" "}
                <Link
                  href="https://www.ecfmg.org/certification-pathways/osce.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Objective Structured Clinical Examination (OSCE) Attestation
                  form
                </Link>{" "}
                does not guarantee that it will be accepted by ECFMG.
              </Paragraph>
              <Paragraph>
                <Link
                  href="https://www.ecfmg.org/certification-pathways/osce.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to download the Objective Structured Clinical
                  Examination (OSCE) Attestation form.
                </Link>
              </Paragraph>
            </Paragraph>
          );
        }
        break;
      case PATHWAYS.Pathway3:
      case PATHWAYS.Pathway4:
      case PATHWAYS.Pathway5:
        var title = (
          <Paragraph>
            <Text strong>
              Pathway 3-5{" "}
              <Link
                href="https://www.ecfmg.org/certification-requirements-2023-match/csa.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Clinical Skills Attestation by Medical School
              </Link>
            </Text>
          </Paragraph>
        );
        if (isSchoolEMSWPMember !== undefined && isSchoolEMSWPMember) {
          text = (
            <Paragraph>
              <Paragraph>
                <Text strong>
                  Pathways 3-5 Clinical Skills Attestation by Medical School
                </Text>
              </Paragraph>
              <Paragraph>
                In order to meet the requirements for Pathways 3-5, your medical
                school must attest to your clinical skills, following the steps
                outlined on the{" "}
                <Link
                  href="https://www.ecfmg.org/certification-pathways/pathways-3-5.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  ECFMG website
                </Link>
                . Because your medical school uses the ECMFG Medical School Web
                Portal (EMSWP), an official of your medical school will complete
                this attestation electronically. A request has been posted to
                your school through the Clinical Skills Evaluation and
                Attestation Portal.
              </Paragraph>
              <Paragraph>
                <Text strong>
                  Please note that it is your responsibility to ensure that your
                  medical school completes the electronic Clinical Skills
                  Attestation in support of your application; your application
                  cannot be approved until the completed attestation is received
                  and accepted by ECFMG. The electronic attestation must be
                  received by ECFMG no later than February 15, 2025.
                </Text>
              </Paragraph>
              <Paragraph>
                Submission of the electronic Clinical Skills Attestation does
                not guarantee that it will be accepted by ECFMG.
              </Paragraph>
            </Paragraph>
          );
        } else {
          text = (
            <Paragraph>
              <Paragraph>
                <Text strong>
                  Pathways 3-5{" "}
                  <Link
                    href="https://www.ecfmg.org/certification-pathways/csa.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Clinical Skills Attestation by Medical School
                  </Link>
                </Text>
              </Paragraph>
              <Paragraph>
                In order to meet the requirements for Pathways 3-5, your medical
                school must attest to your clinical skills, following the steps
                outlined on the{" "}
                <Link
                  href="https://www.ecfmg.org/certification-pathways/pathways-3-5.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  ECFMG website
                </Link>
                . Because your school does not use the ECFMG Medical School Web
                Portal, your school must attest to your clinical skills using
                the{" "}
                <Link
                  href="https://www.ecfmg.org/certification-pathways/csa.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Clinical Skills Attestation form
                </Link>
                . The form must be completed by an authorized official of your
                medical school and sent directly to ECFMG by the medical school.
              </Paragraph>
              <Paragraph>
                <Text strong>
                  Please note that it is your responsibility to ensure that your
                  medical school completes a Clinical Skills Attestation form in
                  support of your application; your application cannot be
                  approved until the completed attestation is received and
                  accepted by ECFMG. The form must be received by ECFMG no later
                  than January 31, 2025.
                </Text>
              </Paragraph>
              <Paragraph>
                Submission of the{" "}
                <Link
                  href="https://www.ecfmg.org/certification-pathways/csa.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Clinical Skills Attestation form
                </Link>{" "}
                does not guarantee that it will be accepted by ECFMG.
              </Paragraph>
              <Paragraph>
                <Link
                  href="https://www.ecfmg.org/certification-pathways/csa.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to download the Clinical Skills Attestation form.
                </Link>
              </Paragraph>
            </Paragraph>
          );
        }
        break;

      case PATHWAYS.Pathway6:
        text = (
          <Paragraph>
            <Paragraph>
              <Text strong>Pathway 6 Physician Evaluators</Text>
            </Paragraph>
            <Paragraph>
              To meet the eligibility requirements for Pathway 6, your clinical
              skills must be observed and evaluated by licensed physicians using
              ECFMG’s{" "}
              <Link
                href="https://www.ecfmg.org/certification-pathways/minicex-p6.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Mini-Clinical Evaluation Exercise (Mini-CEX) for Pathway 6
              </Link>
              . To manage your list of physician evaluators and view the status
              of your Mini-CEX requests, see the{" "}
              <Link href="/miniCEXRequest">Clinical Encounters Dashboard</Link>.
            </Paragraph>
          </Paragraph>
        );
        break;
    }

    return { text: text };
  };

  const cardText = {
    step: [
      {
        value: steps.Submission,
        submission: {
          text: (
            <Paragraph>
              <Paragraph>
                You submitted your Pathways application on{" "}
                {moment(caseDetails?.submittedAt).format(DATE_FORMAT)}.
              </Paragraph>
              {pathwaySubmissionText().text}
            </Paragraph>
          ),
        },
        processing: {
          text: (
            <>
              <Paragraph>
                Processing of your application has not begun. The time needed to
                complete the processing of your application is contingent upon
                receipt of all necessary supporting documentation and
                verifications.
              </Paragraph>
              <Paragraph>
                Please visit our{" "}
                <Link
                  href="https://www.ecfmg.org/certification-pathways/"
                  target="_blank"
                  rel="noreferrer"
                >
                  website
                </Link>{" "}
                for current application processing times.
              </Paragraph>
              <Paragraph>
                Your satisfactory Occupational English Test (OET) Medicine
                results will be posted to this portal. Visit the{" "}
                <Link
                  href="https://www.ecfmg.org/certification-pathways/oet.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Assessment of Communication Skills, Including English Language
                  Proficiency
                </Link>{" "}
                page on the ECFMG website for information on timing of release
                of OET Medicine scores. Please be aware you must take steps to
                release your results to ECFMG as described on the{" "}
                <Link
                  href="https://support.occupationalenglishtest.org/s/article/How-do-I-give-verification-institutions-permission-to-view-my-results"
                  target="_blank"
                  rel="noreferrer"
                >
                  OET website
                </Link>
                .
              </Paragraph>
              <Paragraph>
                ECFMG may contact you at your e-mail address of record if
                additional information or action is required.
              </Paragraph>
              {caseDetails?.currentPathway === PATHWAYS.Pathway6 ? (
                <Paragraph>
                  To manage your list of physician evaluators and view the
                  statuses of your Mini-CEX requests, see the{" "}
                  <Link href="/miniCEXRequest">
                    Clinical Encounters Dashboard
                  </Link>
                  .
                </Paragraph>
              ) : (
                <></>
              )}
            </>
          ),
        },
        decision: {
          text: (
            <>
              <Paragraph>
                ECFMG will not determine the outcome of your Pathways
                application until all supporting documentation and verifications
                have been received. ECFMG will send an e-mail to your e-mail
                address of record once a determination has been made.
              </Paragraph>
            </>
          ),
        },
      },
      {
        value: steps.Processing,
        submission: {
          text: (
            <Paragraph>
              <Paragraph>
                You submitted your Pathways application on{" "}
                {moment(caseDetails?.submittedAt).format(DATE_FORMAT)}.
              </Paragraph>
              {pathwaySubmissionText().text}
            </Paragraph>
          ),
        },
        processing: {
          text: (
            <>
              <Paragraph>
                Processing of your application has begun. The time needed to
                complete the processing of your application is contingent upon
                receipt of all supporting documentation and verifications, when
                applicable.
              </Paragraph>
              <Paragraph>
                Please visit our{" "}
                <Link
                  href="https://www.ecfmg.org/certification-pathways/"
                  target="_blank"
                  rel="noreferrer"
                >
                  website
                </Link>{" "}
                for current application processing times.
              </Paragraph>
              <Paragraph>
                Your satisfactory Occupational English Test (OET) Medicine
                results will be posted to this portal. Visit the{" "}
                <Link
                  href="https://www.ecfmg.org/certification-pathways/oet.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Assessment of Communication Skills, Including English Language
                  Proficiency
                </Link>{" "}
                page on the ECFMG website for information on timing of release
                of OET Medicine scores. Please be aware you must take steps to
                release your results to ECFMG as described on the{" "}
                <Link
                  href="https://support.occupationalenglishtest.org/s/article/How-do-I-give-verification-institutions-permission-to-view-my-results"
                  target="_blank"
                  rel="noreferrer"
                >
                  OET website
                </Link>
                .
              </Paragraph>
              <Paragraph>
                ECFMG may contact you at your e-mail address of record if
                additional information or action is required.
              </Paragraph>
              {caseDetails?.currentPathway === PATHWAYS.Pathway6 ? (
                <Paragraph>
                  To manage your list of physician evaluators and view the
                  statuses of your Mini-CEX requests, see the{" "}
                  <Link href="/miniCEXRequest">
                    Clinical Encounters Dashboard
                  </Link>
                  .
                </Paragraph>
              ) : (
                <></>
              )}
            </>
          ),
        },
        decision: {
          text: (
            <>
              <Paragraph>
                ECFMG will not determine the outcome of your Pathways
                application until all supporting documentation and verifications
                have been received. ECFMG will send an e-mail to your e-mail
                address of record once a determination has been made.
              </Paragraph>
            </>
          ),
        },
      },
      {
        value: steps.Decision,
        submission: {
          text: (
            <Paragraph>
              <Paragraph>
                You submitted your Pathways application on{" "}
                {moment(caseDetails?.submittedAt).format(DATE_FORMAT)}.
              </Paragraph>
              {pathwaySubmissionText().text}
            </Paragraph>
          ),
        },
        processing: {
          text: (
            <>
              <Paragraph>Processing of your application is complete.</Paragraph>
            </>
          ),
        },
        decision: {
          text: (
            <>
              {caseDetails?.output?.toUpperCase() === "REJECTED" ? (
                <Paragraph>
                  ECFMG has completed its review of your Application for 2025
                  Pathways for ECFMG Certification, and it has been rejected.
                  This means that you have not met the clinical and communication
                  skills requirements for ECFMG Certification.
                </Paragraph>
              ) : (
                <Paragraph>
                  ECFMG has completed its review of your Application for 2025
                  Pathways for ECFMG Certification, and it has been approved.
                  This means that you have met the clinical and communication
                  skills requirements for ECFMG Certification.
                </Paragraph>
              )}
            </>
          ),
        },
      },
    ],
  };

  const getStep = () => {
    var step = steps.Submission;
    if (caseDetails?.state) {
      const caseState = caseDetails.state;
      if (caseState.toUpperCase() === CaseDetailsStates.APPLICATION_SUBMITTED) {
        step = steps.Submission;
      } else if (
        caseState.toUpperCase() === CaseDetailsStates.ELIGIBILITY_REVIEW ||
        caseState.toUpperCase() === CaseDetailsStates.QA_READY ||
        caseState.toUpperCase() === CaseDetailsStates.QA_REVIEW
      ) {
        step = steps.Processing;
      } else if (
        caseState.toUpperCase() === CaseDetailsStates.APPLICATION_COMPLETED
      ) {
        step = steps.Decision;
      }
    }

    return step;
  };

  const SubmissionContent = (
    <>
      <Paragraph style={{ paddingRight: "30px" }}>
        <Card
          size="small"
          style={{
            height: "280px",
            whiteSpace: "initial",
            overflow: "auto",
          }}
        >
          {!applicantPortal?.finance?.payment ? (
            <Paragraph>
              You have <Text strong>not</Text> yet filled out a submission
              necessary for ECFMG | FAIMER to process your application.
            </Paragraph>
          ) : (
            cardText.step.find((x: any) => x.value === getStep())?.submission
              .text
          )}
        </Card>
        <Card
          size="small"
          style={{
            marginTop: "5px",
            whiteSpace: "initial",
          }}
        >
          <Meta
            avatar={`$${applicantPortal?.finance?.payment?.amt}`}
            title="Payment Date:"
            description={`Payment Made on ${
              applicantPortal?.finance?.payment
                ? moment(applicantPortal?.finance?.payment?.transTime)
                    .format("MM/DD/YYYY hh:mm:ss A")
                    .toLocaleLowerCase()
                : ""
            }.`}
          />
        </Card>
      </Paragraph>
    </>
  );
  const ProcessingContent = (
    <>
      <Paragraph style={{ paddingRight: "30px" }}>
        <Card
          size="small"
          style={{
            height: "280px",
            whiteSpace: "initial",
            overflow: "auto",
          }}
        >
          {
            cardText.step.find((x: any) => x.value === getStep())?.processing
              .text
          }
        </Card>
        {getStep() === steps.Processing ? (
          <>
            {/* oet status */}
            {oetStatus?.map((record: any) => {
              return (
                <Card
                  key={record.checklistState}
                  size="small"
                  style={{
                    marginTop: "5px",
                    display: oetStatus !== "" ? "block" : "none",
                    whiteSpace: "initial",
                  }}
                >
                  <Text strong>{record.checklistRecord}:</Text>{" "}
                  {
                    StatusMappingsFactory(applicantPortal?.uiConfig)?.find(
                      (x: any) => x.key === record?.checklistState
                    )?.value
                  }
                </Card>
              );
            })}

            {/* pathway status */}
            {pathwayStatus?.map((record: any) => (
              <Card
                key={record.checklistState}
                size="small"
                style={{
                  marginTop: "5px",
                  display: pathwayStatus?.length !== 0 ? "block" : "none",
                  whiteSpace: "initial",
                }}
              >
                <Text strong>{record.checklistRecord}:</Text>
                <Paragraph>
                  -{" "}
                  {
                    StatusMappingsFactory(applicantPortal?.uiConfig)?.find(
                      (x: any) => x.key === record?.checklistState
                    )?.value
                  }
                </Paragraph>
              </Card>
            ))}

            {/* general status */}
            {generalEligibilityStatus?.map((record: any) => (
              <Card
                key={record.checklistState}
                size="small"
                style={{
                  marginTop: "5px",
                  display:
                    generalEligibilityStatus?.length !== 0 ? "block" : "none",
                  whiteSpace: "initial",
                }}
              >
                <Text strong>{record.checklistRecord}:</Text>
                <Paragraph>
                  -{" "}
                  {
                    StatusMappingsFactory(applicantPortal?.uiConfig)?.find(
                      (x: any) => x.key === record?.checklistState
                    )?.value
                  }
                </Paragraph>
              </Card>
            ))}
          </>
        ) : null}
      </Paragraph>
    </>
  );
  const DecisionContent = (
    <>
      <Paragraph style={{ paddingRight: "40px" }}>
        <Card
          size="small"
          style={{
            height: "280px",
            whiteSpace: "initial",
          }}
        >
          {cardText.step.find((x: any) => x.value === getStep())?.decision.text}
        </Card>
        {/* refund */}
        {finance?.refund ? (
          <Card
            size="small"
            style={{
              marginTop: "5px",
              display: "block",
              whiteSpace: "initial",
            }}
          >
            <Meta
              avatar={`$${finance?.refund?.amt}`}
              title="Refund Date:"
              description={`Refund processed on ${moment(
                finance?.refund?.transTime
              )
                .format("MM/DD/YYYY hh:mm:ss A")
                .toLocaleLowerCase()}`}
            />
          </Card>
        ) : null}
        {/* application outcome - only show when it is decision state*/}
        {getStep() === steps.Decision ? (
          <>
            <Card
              size="small"
              bordered={false}
              style={{
                textAlign: "center",
                marginTop: "5px",
                display: caseDetails?.output !== "" ? "block" : "none",
                whiteSpace: "initial",
              }}
            >
              <Title level={2} style={{ marginTop: "10px", fontWeight: 500 }}>
                Application Outcome
              </Title>
              <Paragraph
                style={{
                  backgroundColor:
                    caseDetails?.output?.toUpperCase() ===
                    "REJECTED".toUpperCase()
                      ? "#D11D5C" //standard error
                      : "#097D5A", //standard success
                  fontSize: "1.25rem",
                  lineHeight: "3rem",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                {caseDetails?.output.toUpperCase()}
              </Paragraph>
              {caseDetails?.output?.toUpperCase() === "REJECTED" ? (
                <Card
                  size="small"
                  style={{
                    marginTop: "5px",
                    display: "block",
                    whiteSpace: "initial",
                  }}
                >
                  <Text strong>Reason:</Text>
                  {rejectReasons.map((reason: any) => (
                    <Paragraph key={_.uniqueId()} style={{ textAlign: "left" }}>
                      - {reason}
                    </Paragraph>
                  ))}
                </Card>
              ) : null}
            </Card>
          </>
        ) : null}
      </Paragraph>
    </>
  );

  const useWindowWidth = () => {
    const [windowWidth, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return windowWidth;
  };
  const width = useWindowWidth();

  return typeof applicantPortal === "undefined" ? (
    <>
      <Paragraph style={{ textAlign: "center", margin: "80px 40px" }}>
        <Spin size="large" />
        <Title level={2}>Loading...</Title>
      </Paragraph>
    </>
  ) : (
    <>
      <Helmet>
        <title>Summary</title>
      </Helmet>
      <Row>
        <Col span={24}>
          <Title level={2}>
            Thank you for submitting your Pathways application.
          </Title>
          <Title level={5}>
            Below is an overview of the application process and an indication of
            where you are in that process. You can log in and return to this
            screen at any time for updates on the status of your application.
          </Title>
        </Col>
      </Row>
      <br />
      <Steps
        direction={width < 1200 ? "vertical" : "horizontal"}
        current={getStep()}
      >
        <Step
          title="Submission"
          subTitle={SubmissionContent}
          style={{ maxWidth: width < 1200 ? "100%" : "33%" }}
        />
        <Step
          title="Processing"
          subTitle={ProcessingContent}
          style={{ maxWidth: width < 1200 ? "100%" : "33%" }}
        />
        <Step
          title="Decision"
          subTitle={DecisionContent}
          style={{ maxWidth: width < 1200 ? "100%" : "33%" }}
        />
      </Steps>
    </>
  );
};

export default QualificationComplete;
