import React, { FC, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Modal, Button, Space, Typography } from "antd";
import ViewPDF from "../ViewPDF";
import PropTypes, { InferProps } from "prop-types";
const { Title, Paragraph, Text } = Typography;
const ReviewExceptionPropTypes = {
  onEditExceptionCallback: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    data: PropTypes.shape({
      requestText: PropTypes.string,
      documents: PropTypes.shape({
        docType: PropTypes.string,
        title: PropTypes.string,
        docId: PropTypes.string,
      }),
    }),
  }),
};

export type ReviewExceptionProps = InferProps<typeof ReviewExceptionPropTypes>;

// Q-E
const ReviewExceptionInformation: FC<ReviewExceptionProps> = ({
  onEditExceptionCallback,
  initialValues,
}) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<string | undefined>(undefined);
  const reason = initialValues?.data?.requestText;
  const documents = initialValues?.data?.documents as any;

  const previewDocument = async (docId: string) => {
    setShowPreviewPdfModal(true);
    setSelectedDoc(docId);
  };

  const viewPdfClosed = async () => {
    setShowPreviewPdfModal(false);
    setSelectedDoc(undefined);
  };
  return (
    <>
      <Paragraph>
        <Space size="middle">
          <Title level={3}>Exception</Title>
          <Button onClick={onEditExceptionCallback}>
            Edit
          </Button>
        </Space>

        <Paragraph>
          Reason:{" "}
          <Paragraph style={{ wordWrap: "break-word" }}>{reason}</Paragraph>
        </Paragraph>
        {documents?.map((doc: any) => {
          return (
            <Paragraph key={doc.docId}>
              Supporting Documents submitted: &nbsp;
              <Button
                icon={<SearchOutlined />}
                shape="round"
                size="small"
                onClick={() => previewDocument(doc.docId)}
              >
                {doc.title}
              </Button>
              <Modal
                visible={showPreviewPdfModal && selectedDoc === doc.docId}
                width="80vw"
                okText="Continue"
                footer={null}
                onCancel={() => viewPdfClosed()}
              >
                {showPreviewPdfModal ? (
                  <ViewPDF filename={doc.title} docId={doc.docId}></ViewPDF>
                ) : (
                  ""
                )}
              </Modal>
            </Paragraph>
          );
        })}
      </Paragraph>
    </>
  );
};

export default {
  Component: ReviewExceptionInformation,
};
