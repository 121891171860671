import React, { FC, useState, useEffect } from "react";
import { Button, Table, List, Card, Modal, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import moment from "moment";
import {
  CEX_STATUS,
  CEX_STATUS_DETAIL,
  getPhysicianName,
  PATHWAY6_DATE_FORMAT,
} from "../constants";
import { PhysicianStatusHistoryModal } from "./physician-status-history-modal";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  minicex: PropTypes.arrayOf(
    PropTypes.shape({
      encounterId: PropTypes.string.isRequired,
      physicianEmail: PropTypes.string.isRequired,
      physicianLastNameProvidedByApplicant: PropTypes.string.isRequired,
      physicianRestOfNameProvidedByApplicant: PropTypes.string.isRequired,
      physicianLastNameProvidedByPhysician: PropTypes.string.isRequired,
      physicianRestOfNameProvidedByPhysician: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      statusDetail: PropTypes.string.isRequired,
      stateModel: PropTypes.arrayOf(
        PropTypes.shape({
          state: PropTypes.string.isRequired,
          timestamp: PropTypes.string.isRequired,
        })
      ),
      allowedActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    })
  ),
};
export interface IProps {
  __typename?: "PhysicianEncounterHistoryListCard";
  minicex?: {
    encounterId: string;
    physicianEmail: string;
    physicianLastNameProvidedByApplicant: string;
    physicianRestOfNameProvidedByApplicant: string;
    physicianLastNameProvidedByPhysician: string;
    physicianRestOfNameProvidedByPhysician: string;
    createdAt: string;
    state: string;
    statusDetail: string;
    stateModel?: {
      state: string;
      timestamp: string;
    }[];
    allowedActions: string[];
  }[];
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const PhysicianEncounterHistoryListCard: FC<ComponentProps> = ({ minicex }) => {
  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  const [stateModel, setStateModel] = useState<any>();
  const [showEncounterHistoryModal, setShowEncounterHistoryModal] =
    useState<boolean>(false);
  const [listData, setListData] = useState<any>([]);

  useEffect(() => {
    if (minicex && minicex.length > 0) {
      let list: any = [];
      minicex?.map((record: any) => {
        list.push({
          title: `${record.encounterId}`,
          record,
        });
      });
      setListData(list);
    }
  }, [minicex]);

  useEffect(() => {
    var filteredStateModel = selectedRow?.stateModel.filter(
      (item: { state: string }) =>
        item.state !== "MINICEX_CREATED_PHYSICIAN_INVITE_PENDING"
    );
    setStateModel(filteredStateModel);
  }, [selectedRow]);

  const historyColumns = [
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (state: any, record: any) => (
        <Paragraph>
          {record.state !== undefined ? (
            <Paragraph>{getStatus(CEX_STATUS, record.state) + " "}</Paragraph>
          ) : (
            <Paragraph></Paragraph>
          )}
        </Paragraph>
      ),
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: any, record: any) => (
        <Paragraph>
          {record.timestamp !== undefined ? (
            <Paragraph>
              {moment(record.timestamp)
                .utc()
                .format(`${PATHWAY6_DATE_FORMAT}, h:mm:ss a`)}
            </Paragraph>
          ) : (
            <Paragraph></Paragraph>
          )}
        </Paragraph>
      ),
    },
  ];

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  const getStatusDetail = (obj: any, record: any) => {
    switch (record.state) {
      case "MINICEX_CREATED_PHYSICIAN_INVITE_SENT":
      case "MINICEX_CREATED_PHYSICIAN_REGISTERED": {
        //Calculate difference in days from now to expiration
        var timeDifferenceInDays = moment(record.expiresAt)
          .utc()
          .diff(moment().utc().toDate().getTime(), "days");

        var textWithInviteDate = obj[record.state].replace(
          "*InviteDate*",
          moment(record.createdAt).utc().format(PATHWAY6_DATE_FORMAT)
        );

        if (timeDifferenceInDays <= 0) {
          return textWithInviteDate.replace("*NumberOfDays*", "less than one");
        } else {
          return textWithInviteDate.replace(
            "*NumberOfDays*",
            timeDifferenceInDays
          );
        }
      }
      case "MINICEX_ACCEPTED": {
        //Calculate how many milliseconds between today and expiration data.
        var expiresAtInMilliseconds =
          +record.expiresAt - moment().toDate().getTime();
        //Convert milliseconds to full days
        var expiresAtInDays = Math.trunc(
          expiresAtInMilliseconds / (60 * 60 * 24 * 1000)
        );
        if (expiresAtInDays < 0) {
          expiresAtInDays = 0;
        }

        return obj[record.state].replace("*NumberOfDays*", expiresAtInDays);
      }
      default: {
        return obj[record.state];
      }
    }
  };

  return (
    <>
      <List
        dataSource={listData}
        itemLayout="vertical"
        pagination={{ pageSize: 10 }}
        renderItem={(item: any) => (
          <List.Item key={item.title}>
            <Card title={null}>
              <Paragraph>
                <Text>ID:</Text> <Text strong>{item?.record?.encounterId}</Text>
              </Paragraph>
              <Paragraph>
                <Text>Name from Applicant: </Text>
                <Text strong>
                  {getPhysicianName(
                    item?.record?.physicianLastNameProvidedByApplicant,
                    item?.record?.physicianRestOfNameProvidedByApplicant
                  )}
                </Text>
              </Paragraph>
              <Paragraph>
                <Text>Name from Physician: </Text>
                <Text strong>
                  {getPhysicianName(
                    item?.record?.physicianLastNameProvidedByPhysician,
                    item?.record?.physicianRestOfNameProvidedByPhysician
                  )}
                </Text>
              </Paragraph>
              <Paragraph>
                <Text>E-mail: </Text>{" "}
                <Text strong>{item?.record?.physicianEmail}</Text>
              </Paragraph>
              <Paragraph>
                <Text>Last Update: </Text>{" "}
                {item?.record?.createdAt !== undefined ? (
                  <Text strong>
                    {moment(item?.record?.createdAt)
                      .utc()
                      .format(PATHWAY6_DATE_FORMAT)}
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </Paragraph>
              <Paragraph>
                <Text>Status: </Text>{" "}
                {item?.record?.state !== undefined ? (
                  <Text strong>
                    {getStatus(CEX_STATUS, item?.record?.state) + " "}
                    <InfoCircleOutlined
                      style={{ fontSize: "18px" }}
                      onClick={(event: any) => {
                        setSelectedRow(item.record);
                        setShowEncounterHistoryModal(true);
                      }}
                    />
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </Paragraph>
              <Paragraph>
                <Text>Status Detail: </Text>{" "}
                {item?.record?.state !== undefined ? (
                  <Paragraph>
                    <Text strong>
                      {getStatusDetail(CEX_STATUS_DETAIL, item?.record) + " "}
                    </Text>
                  </Paragraph>
                ) : (
                  <Paragraph></Paragraph>
                )}
              </Paragraph>
            </Card>
          </List.Item>
        )}
      ></List>

      <PhysicianStatusHistoryModal
        selectedRecord={selectedRow}
        setShowEncounterHistoryModal={setShowEncounterHistoryModal}
        showEncounterHistoryModal={showEncounterHistoryModal}
        statusHistory={stateModel}
        statusHistoryColumns={historyColumns}
      />
    </>
  );
};
export default PhysicianEncounterHistoryListCard;
