import React, { useState, FC, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Checkbox,
  message,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { AdditionalDocumentCardList } from "../additional-document-card-list";
import { UploadDocument } from "../upload-document";
import ViewPDF from "../ViewPDF";
import { useMutation } from "@apollo/client/react";
import PropTypes, { InferProps } from "prop-types";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import {
  ApplicantQualificationIApplicantPortal_PathwayXDocument,
  ExceptionRequest,
} from "../../generated";
const { Title, Paragraph, Text } = Typography;
const ComponentPropTypes = {
  pathwayX: PropTypes.shape({
    data: PropTypes.shape({
      requestText: PropTypes.string,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          docType: PropTypes.string,
          title: PropTypes.string,
          docId: PropTypes.string,
        })
      ),
    }),
  }),
  refetch: PropTypes.func.isRequired,
};

export interface IProps {
  pathwayX?: ExceptionRequest;
  refetch: () => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const QualificationI: FC<ComponentProps> = ({ pathwayX, refetch }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [reason, setReason] = useState<string | undefined>(undefined);
  const [isUnderstood, setIsUnderstood] = useState(false);
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);
  const [selectedDocumentName, setSelectedDocumentName] = useState("");
  const [selectedSasToken, setSelectedSasToken] = useState("");
  const [documents, setDocuments] = useState<any>(undefined);
  const [UpdateException] = useMutation(
    ApplicantQualificationIApplicantPortal_PathwayXDocument
  );

  useEffect(() => {
    if (navigationType === "POP") {
      navigate(location.pathname);
      refetch();
    }
    if (pathwayX) {
      if (pathwayX.data?.documents && typeof documents === `undefined`) {
        setDocuments(pathwayX.data.documents);
        form.setFieldsValue({
          additionalDocuments: pathwayX.data.documents,
        });
      }
      if (pathwayX.data?.requestText && typeof reason === `undefined`) {
        setReason(pathwayX.data.requestText);
        form.setFieldsValue({
          reason: pathwayX.data.requestText,
        });
      }
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of screen on page load
  }, []);

  const reasonOnChangeHandler = (e: any) => {
    setReason(e.target.value);
  };

  const continueQualification = async () => {
    var deepCopyDocs = [];
    if (documents) {
      deepCopyDocs = JSON.parse(JSON.stringify(documents));
    }
    deepCopyDocs.map((doc: any) => {
      delete doc.__typename;
    });
    await UpdateException({
      variables: {
        requestText: reason,
        documents: deepCopyDocs,
      },
    })
      .then(() => {
        navigate(`/Qualification-review`);
      })
      .catch((error: any) => {
        //only grab the first error
        const graphQLErrorReference =
          error?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${error.toString()} - Reference ID : ${graphQLErrorReference}`
        );
      });
  };

  const deleteDocument = async (documentId: string) => {
    const newDocuments = documents.filter(
      (document: { docId: string }) => document.docId !== documentId
    );
    setDocuments(newDocuments);
    form.setFieldsValue({ additionalDocuments: newDocuments });
  };

  const viewPdfClosed = async () => {
    setShowPreviewPdfModal(false);
  };

  //Get the blob name for azure storage by saving in mongo
  var tempDocs: any;
  async function getBlobname(filename: string) {
    var deepCopyDocuments = [];
    if (documents) {
      deepCopyDocuments = JSON.parse(JSON.stringify(documents));
    }
    var uniqueDocIds: any = [];
    deepCopyDocuments.map((doc: any) => {
      uniqueDocIds.push(doc.docId);
      delete doc.__typename;
    });
    const documentToUpload = {
      docType: "Exception Document",
      title: filename,
    };
    deepCopyDocuments.push(documentToUpload);

    return await UpdateException({
      variables: {
        requestText: reason,
        documents: deepCopyDocuments,
      },
    })
      .then((data: any) => {
        tempDocs =
          data?.data?.ApplicantPortal_pathwayX?.data?.pathwayX?.data?.documents;
        var thisDocGuid = "";
        tempDocs.map((doc: any) => {
          //The most recent document won't exist in the uniqueDocIds, so that is the one for this document
          if (!uniqueDocIds.includes(doc.docId)) {
            thisDocGuid = doc.docId;
          }
        });
        form.setFieldsValue({ additionalDocuments: tempDocs });
        return thisDocGuid;
      })
      .catch((error) => {
        console.log(error);
        return "error";
      });
  }

  const addNewDoc = () => {
    setDocuments(tempDocs);
  };

  const [form] = Form.useForm();

  const onCheckingUnderstandChange = async (value: any) => {
    setIsUnderstood(value);
    //silly thing has to be done for required checkboxes
    // if (value === true) {
    //   form.setFieldsValue({ understanding: value });
    // } else {
    //   form.resetFields(["understanding"]);
    // }
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>Pathway Exception Request</Title>
        </Col>
      </Row>
      <Form form={form} onFinish={continueQualification}>
        <Row>
          <Col span={24}>
            <Paragraph>
              ECFMG will consider exceptions to certain Pathways eligibility
              requirements in limited circumstances. In order to be eligible for
              an exception, an individual must meet all other requirements for
              ECFMG Certification and the general eligibility requirements for
              all Pathways. Requests for exception will not be considered in
              advance of your application. Submission of this application does
              not constitute a guarantee that your request for exception will be
              granted. The decision whether to grant an exception is within the
              sole discretion of ECFMG.
            </Paragraph>

            <Paragraph
              style={{
                textAlign: "initial",
                marginLeft: "20px",
                fontSize: "14px",
                color: "rgba(0, 0, 0, 1)",
              }}
            ></Paragraph>
          </Col>
        </Row>
        <Row>
          <Col lg={18} md={18} xs={24}>
            <Text strong>Reason</Text>
            <Paragraph>
              Please provide a detailed description of the exception you are
              requesting and the reason you are requesting this exception:
            </Paragraph>
            <Form.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Please provide a reason.",
                },
              ]}
            >
              <TextArea
                id="reason-textarea"
                allowClear
                onChange={reasonOnChangeHandler}
                value={reason}
                rows={5}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Text strong>Supporting Documents</Text>
            <Paragraph>
              Please upload the required documentation that supports your
              request for exception and documentation required for the Pathway
              to which you are applying. If the documents are not in English,
              please upload an English translation.
            </Paragraph>

            <Form.Item
              valuePropName="documents"
              name="additionalDocuments"
              rules={[
                {
                  required: !documents || documents.length === 0,
                  message: "Please upload at least one document.",
                },
              ]}
            >
              {documents && documents.length > 0 ? (
                <AdditionalDocumentCardList
                  onDelete={(documentId: string) => deleteDocument(documentId)}
                  numberOfCardsPerRow={6}
                  documents={documents ?? undefined}
                />
              ) : (
                <></>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18} style={{ textAlign: "right", margin: "10px 0" }}>
            <UploadDocument
              uploadId="additional-document-upload"
              getGuid={(filename: string) => getBlobname(filename)}
              showIcon={false}
              label="Add New"
              onUploadSuccessCallback={addNewDoc}
            />
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item
              name="understanding"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  transform: (value) => value || undefined,
                  type: "boolean",
                  message: "Please select the checkbox.",
                },
              ]}
            >
              <Checkbox
                id="understand-checkbox"
                checked={isUnderstood}
                onChange={(e) => onCheckingUnderstandChange(e.target.checked)}
              >
                I understand that I am submitting an application to request an
                exception to a specific eligibility requirement for the
                Pathways; that submission of this application does not
                constitute a guarantee that my request for exception will be
                granted; and that the decision whether to grant an exception is
                within the sole discretion of ECFMG. I understand that, if ECFMG
                denies my exception request, my application will be rejected,
                and I will not receive a refund. I further understand that if my
                exception request is granted but ECFMG does not receive
                appropriate documentation to confirm my eligibility for a
                Pathway, my application will not be complete, and I will not
                receive a refund.
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              size="large"
              id="qi-continue-button"
              type="primary"
              htmlType="submit"
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        title={selectedDocumentName}
        visible={showPreviewPdfModal}
        width="80vw"
        okText="Continue"
        footer={null}
        onCancel={() => viewPdfClosed()}
      >
        {showPreviewPdfModal ? (
          <ViewPDF sasToken={selectedSasToken}></ViewPDF>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default {
  Component: QualificationI,
};
