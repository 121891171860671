//This is used for archival. This file should not be modified!
export const REJECTION_REASONS_V2 = [
    //pathway 1
    {
      key: "1-licensureUnrestrictedLicense",
      value:
        "You have not held a license/registration that meets the eligibility requirements for Pathway 1 and you are not eligible to meet the clinical skills requirement for ECFMG Certification through any other pathway.",
    },
    {
      key: "1-licensureIssuedAppropriateAuthority",
      value:
        "You have not held a license/registration that meets the eligibility requirements for Pathway 1 and you are not eligible to meet the clinical skills requirement for ECFMG Certification through any other pathway.",
    },
    {
      key: "1-licensureApplicantLicenseOnPoint",
      value:
        "You have not held a license/registration that meets the eligibility requirements for Pathway 1 and you are not eligible to meet the clinical skills requirement for ECFMG Certification through any other pathway.",
    },
    {
      key: "1-licensureDisciplinaryAction",
      value: "Your license/registration has been subject to disciplinary action.",
    },
    {
      key: "1-licensureNameValidation",
      value:
        "The name on your license/registration does not match the name in your ECFMG record, and you have not provided appropriate documentation to support this discrepancy.",
    },
  
    {
      key: "1-licensureCompleteLegible",
      value:
        "Your license/registration is not legible and/or is incomplete, and you have not provided an acceptable copy.",
    },
    {
      key: "1-licensureTranslationStatus",
      value:
        "Your license/registration is not in English and you have not submitted an English translation that meets ECFMG’s requirements.",
    },
  
    {
      key: "1-verificationAuthorized",
      value:
        "ECFMG did not receive the required verification of your license/registration from the issuing authority.",
    },
    {
      key: "1-verificationPrimarySource",
      value:
        "ECFMG did not receive the required verification of your license/registration directly from the primary source.",
    },
    {
      key: "1-verificationActiveLicense",
      value:
        "You have not held a license/registration that meets the eligibility requirements for Pathway 1 and you are not eligible to meet the clinical skills requirement for ECFMG Certification through any other pathway.",
    },
    {
      key: "1-verificationGoodStanding",
      value:
        "The issuing authority indicates your license/registration has been subject to disciplinary action.",
    },
    //pathway 2
    {
      key: "2-osceExaminationAcceptable",
      value:
        "The clinical skills examination that you passed is not acceptable to ECFMG (Pathway 2), and you are not eligible to meet the clinical skills requirement for ECFMG Certification through any other pathway.",
    },
    {
      key: "2-osceExaminationPassed",
      value:
        "You did not pass a clinical skills examination that is acceptable to ECFMG (Pathway 2), and you are not eligible to meet the clinical skills requirement for ECFMG Certification through any other Pathway.",
    },
    {
      key: "2-osceExaminationAfterJuly1",
      value:
        "You did not pass a clinical skills examination on or after January 1, 2018 (Pathway 2) and you are not eligible to meet the clinical skills requirement for ECFMG Certification through any other pathway.",
    },
    {
      key: "2-osceNameOK",
      value:
        "The name on your document does not match the name in your ECFMG record, and you have not provided appropriate documentation to support this discrepancy.",
    },
    {
      key: "2-osceDocumentCompleteLegible",
      value:
        "Your document is not legible and/or is incomplete, and you have not provided an acceptable copy.",
    },
    {
      key: "2-osceTranslation",
      value:
        "Your document is not in English and you have not submitted an English translation that meets ECFMG’s requirements.",
    },
    {
      key: "2-verificationCompleted",
      value:
        "ECFMG did not receive verification that you passed a clinical skills examination.",
    },
    {
      key: "2-verificationReturned",
      value:
        "ECFMG did not receive verification that you passed a clinical skills examination directly from the primary source.",
    },
    {
      key: "2-verificationPassedExamConfirmed",
      value:
        "The issuing authority indicates that you did not pass a clinical skills examination.",
    },
    {
      key: "2-verificationAcceptableDateConfirmed",
      value:
        "The issuing authority indicates that your examination date was prior to January 1, 2018.",
    },
    //pathway 3
    {
      key: "3-eligibilityMedicalSchool",
      value:
        "ECFMG has confirmed that the medical school from which you graduated or expect to graduate does not meet the eligibility requirements established by ECFMG for the clinical skills pathways. ",
    },
    {
      key: "3-eligibilityGradDateEligible",
      value:
        "ECFMG has confirmed that you graduated from medical school prior to January 1, 2018.",
    },
    {
      key: "3-attestationComplete",
      value:
        "Your medical school has indicated that they are unable to attest to your clinical skills.",
    },
    {
      key: "3-attestationAuthorizedOfficial",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "3-attestationTitleOk",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "3-attestationSignatureMatch",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "3-attestationSealOk",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "3-attestationSignatureDate",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "3-attestationSentPrimarySource",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "3-attestationCompletedByMedicalSchool",
      value:
        "ECFMG has confirmed that the medical school from which you graduated or expect to graduate does not meet the eligibility requirements established by ECFMG for the clinical skills pathways.",
    },
    //pathway4
    {
      key: "4-eligibilityMedicalSchool",
      value:
        "ECFMG has confirmed that the medical school from which you graduated or expect to graduate does not meet the eligibility requirements established by ECFMG for the clinical skills pathways. ",
    },
    {
      key: "4-eligibilityGradDateEligible",
      value:
        "ECFMG has confirmed that you graduated from medical school prior to January 1, 2018.",
    },
    {
      key: "4-attestationComplete",
      value:
        "Your medical school has indicated that they are unable to attest to your clinical skills.",
    },
    {
      key: "4-attestationAuthorizedOfficial",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "4-attestationTitleOk",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "4-attestationSignatureMatch",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "4-attestationSealOk",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "4-attestationSignatureDate",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "4-attestationSentPrimarySource",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "4-attestationCompletedByMedicalSchool",
      value:
        "ECFMG has confirmed that the medical school from which you graduated or expect to graduate does not meet the eligibility requirements established by ECFMG for the clinical skills pathways.",
    },
    //pathway5
    {
      key: "5-eligibilityMedicalSchool",
      value:
        "ECFMG has confirmed that the medical school from which you graduated or expect to graduate does not meet the eligibility requirements established by ECFMG for the clinical skills pathways. ",
    },
    {
      key: "5-eligibilityGradDateEligible",
      value:
        "ECFMG has confirmed that you graduated from medical school prior to January 1, 2018.",
    },
    {
      key: "5-attestationComplete",
      value:
        "Your medical school has indicated that they are unable to attest to your clinical skills.",
    },
    {
      key: "5-attestationAuthorizedOfficial",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "5-attestationTitleOk",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "5-attestationSignatureMatch",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "5-attestationSealOk",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "5-attestationSignatureDate",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "5-attestationSentPrimarySource",
      value:
        "ECFMG did not receive an acceptable Clinical Skills Attestation Form from your medical school.",
    },
    {
      key: "5-attestationCompletedByMedicalSchool",
      value:
        "ECFMG has confirmed that the medical school from which you graduated or expect to graduate does not meet the eligibility requirements established by ECFMG for the clinical skills pathways.",
    },
    //Pathway 6
    {
      key: "6-minicexComplete",
      value:
        "ECFMG did not receive six completed Mini-CEX evaluations in support of your application.",
    },
    {
      key: "6-minicexScoreAcceptable",
      value:
        "You did not obtain an acceptable score in six clinical encounters, as evaluated by a licensed physicians using ECFMG’s Mini-CEX for Pathway 6, and as reviewed by the ECFMG | FAIMER Mini-CEX Review Committee.",
    },
    //Pathway x
    {
      key: "X-applicantIsNotCertified",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-applicantNotValidStep2CSScore",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-applicantHasNotSubmittedAppForCert",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-applicantHasPurchasedToken2021Match",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-applicantNotBarredByUSMLE",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-ecfmgRestrictionsDoNotPreventAppExamination",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-step1PassedWithOneOrNoFails",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-step2CKPassedWithOneOrNoFails",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-exceptionApproved",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-applicantEligiblePathway1",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-pathway1DocsVerified",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-applicantEligiblePathway2",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-pathway2DocsVerified",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-applicantEligiblePathway345",
      value: "Your request for an exception has been denied.",
    },
    {
      key: "X-pathway345DocsVerified",
      value: "Your request for an exception has been denied.",
    },
    //OET
    {
      key: "O-scoreConfirmed",
      value: "You did not meet the English language proficiency requirement.",
    },
    {
      key: "O-scoreAcceptable",
      value: "You did not meet the English language proficiency requirement.",
    },
    {
      key: "O-examDateValidation",
      value: "You did not meet the English language proficiency requirement.",
    },
    {
      key: "O-nameValid",
      value: "You did not meet the English language proficiency requirement.",
    },
    {
      key: "O-documentCompleteLegible",
      value: "You did not meet the English language proficiency requirement.",
    },
    //General Eligibility Failures
    {
      key: "Applicant is restricted",
      value:
        "Your account is currently restricted. Please contact ECFMG for more information.",
    },
    {
      key: "Applicant failed more than once",
      value:
        "You have failed the same USMLE Step or Step Component (Step 1, Step 2 CK, or Step 2 CS) two or more times.",
    },
  ];
  