import React, { useEffect } from "react";
import { Result, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
const { Title, Paragraph, Text } = Typography;

function QualificationG(props: any) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of screen on page load
  }, []);

  const onBackClick = async () => {
    navigate("/QualificationA");
  };
  return (
    <>
      <Result
        status="error"
        title="Sorry, You Are Ineligible."
        subTitle={
          <>
            <Paragraph style={{ display: "inline-block" }}>
              <Paragraph
                style={{
                  maxWidth: "400px",
                  textAlign: "left",
                  display: "inline-block",
                  marginTop: "20px",
                  color: "rgba(0, 0, 0, 0.8)",
                }}
              >
                <Paragraph>
                  Based on the information you have provided in this
                  application, you are not eligible to apply to any of the
                  pathways.
                </Paragraph>
                <Paragraph>
                  Please continue to monitor the ECFMG website, since we are
                  developing longer-term plans for applicants to future Matches.
                  In the interim, you can continue to pursue other requirements
                  for ECFMG Certification, such as passing Step 1 and Step 2 CK.
                </Paragraph>
                <Paragraph>
                  Please contact ECFMG if you have any questions or we can
                  support you in your pursuit of ECFMG Certification and U.S.
                  graduate medical education.
                </Paragraph>
              </Paragraph>
            </Paragraph>
          </>
        }
        extra={[
          <>
            <Paragraph
              style={{
                maxWidth: "400px",
                textAlign: "left",
                display: "inline-block",
                marginTop: "20px",
                color: "rgba(0, 0, 0, 0.8)",
              }}
            >
              If you answered any of the preceding questions incorrectly, you
              can click the back button to return to the beginning of the
              application.
            </Paragraph>
            <br />
            <Button
              id="qg-back-button"
              size="large"
              onClick={() => onBackClick()}
            >
              Go Back
            </Button>
          </>,
        ]}
      />
    </>
  );
}

export default QualificationG;
