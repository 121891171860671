import PropTypes from "prop-types";

export const CaseDetailsPropTypes = {
  caseDetails: PropTypes.shape({
    state: PropTypes.string.isRequired,
    currentPathway: PropTypes.string.isRequired,
    submittedAt: PropTypes.string.isRequired,
    output: PropTypes.string.isRequired,
    outputReason: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

export const Pathway2PropTypes = {
  pathway2: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasPassedCSExamInRecentPast: PropTypes.bool.isRequired,
    }),
  }),
};
