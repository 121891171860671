import { createContext } from 'react';

export interface AuthContextInterface {
    authToken():string, 
    setAuthToken:(token:string) => void, 
    userHasValidToken:() => boolean,
    logout: () => void;
} 

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initialContext = {
    authToken: stub,
    setAuthToken: stub,
    userHasValidToken: stub,
    logout: stub
};
  
const AuthContext = createContext<AuthContextInterface>(initialContext);
  
export default AuthContext;