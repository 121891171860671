import React, { FC, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Modal, Divider, Button, Space, Typography } from "antd";
import ViewPDF from "../ViewPDF";
import moment from "moment";
import PropTypes, { InferProps } from "prop-types";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT } from "../constants";
import { useFeatureFlags } from "../feature-flag-react-lite";
import { Pathway2 } from "../../generated";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  onEditCSExamCallback: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasPassedCSExamInRecentPast: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        data: PropTypes.shape({
          oscExamRef: PropTypes.shape({
            _id: PropTypes.string,
            examCode: PropTypes.string,
            examShortTitle: PropTypes.string,
            examFullName: PropTypes.string,
          }),
          schoolRef: PropTypes.shape({
            _id: PropTypes.number,
            countryName: PropTypes.string,
            name: PropTypes.string,
          }),
          examDate: PropTypes.string,
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
};

export interface IProps {
  __typename?: "ReviewCSExamInformation";
  onEditCSExamCallback: () => void;
  initialValues?: Pathway2;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

// Q-E
const ReviewCSExamInformation: FC<ComponentProps> = ({
  onEditCSExamCallback,
  initialValues,
}) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const { GetFeatureFlagByName } = useFeatureFlags();

  var applicantProvidedData = undefined;
  if (
    initialValues?.applicantProvidedData &&
    initialValues.applicantProvidedData.length > 0
  ) {
    applicantProvidedData = initialValues.applicantProvidedData[0]; //onlye one is submitted by user
  }
  const examName = applicantProvidedData?.data?.oscExamRef?.examFullName ?? "";
  const examDate = applicantProvidedData?.data?.examDate ?? "";
  const hasPassedCSExamInRecentPast =
    initialValues?.userResponse?.hasPassedCSExamInRecentPast;
  const documents = applicantProvidedData?.data?.documents as any;
  const schoolInformation = applicantProvidedData?.data?.schoolRef;
  const previewDocument = async (document: any) => {
    setSelectedDocument(document);
    setShowPreviewPdfModal(true);
  };

  const viewPdfClosed = async () => {
    setShowPreviewPdfModal(false);
  };
  return (
    <>
      {applicantProvidedData ? (
        <Paragraph>
          <Divider plain orientation="left">
            <Space size="middle">
              <Text strong>Pathway 2</Text>
              <Button onClick={onEditCSExamCallback}>
                Edit
              </Button>
            </Space>
          </Divider>
          <Paragraph>
            {hasPassedCSExamInRecentPast ? (
              <Paragraph>
                <Paragraph id="has-passed-CSExam-in-recent-past-text">
                  Did you successfully complete, on or after January 1, 2018, an
                  OSCE required for medical licensure or registration that ECFMG
                  has determined is acceptable for Pathway 2?{" "}
                  <Text strong>
                    {hasPassedCSExamInRecentPast ? "Yes" : "No"}
                  </Text>
                </Paragraph>
                {examName ? (
                  <Paragraph>
                    {" "}
                    Exam name:{" "}
                    <Text strong id="exam-name">
                      {" "}
                      {examName}
                    </Text>
                    <br />
                    <br />
                  </Paragraph>
                ) : null}
                {examDate ? (
                  <Paragraph>
                    Exam date:
                    <Text strong id="exam-date">
                      {" "}
                      {moment(examDate).format(DATE_FORMAT)}
                    </Text>
                    <br />
                    <br />
                  </Paragraph>
                ) : null}
              </Paragraph>
            ) : (
              // ) : (
              //   <Paragraph>I do not meet these requirements.</Paragraph>
              // )
              <>
                <Paragraph>
                  My medical school administers an OSCE specifically required by
                  the medical regulatory authority (MRA) in that country and my
                  graduation date is on or after January 1, 2023.
                </Paragraph>
                <Paragraph>
                  Medical School: <Text strong>{schoolInformation?.name}</Text>
                </Paragraph>
              </>
            )}
          </Paragraph>
          <Paragraph>
            {documents?.map((document: any) => {
              if (document.docType === "Pathway2Document") {
                return (
                  <Paragraph key={document.docId}>
                    {hasPassedCSExamInRecentPast ? (
                      <Paragraph>Exam Documentation:</Paragraph>
                    ) : (
                      <Paragraph>Final Medical Diploma:</Paragraph>
                    )}
                    <Button
                      icon={<SearchOutlined />}
                      shape="round"
                      size="small"
                      onClick={() => previewDocument(document)}
                    >
                      {document.title}
                    </Button>
                    <br />
                    <br />
                  </Paragraph>
                );
              } else if (document.docType === "Pathway2TranslatedDocument") {
                return (
                  <Paragraph key={document.docId}>
                    <Paragraph>English Translation:</Paragraph>
                    <Button
                      icon={<SearchOutlined />}
                      shape="round"
                      size="small"
                      onClick={() => previewDocument(document)}
                    >
                      {document.title}
                    </Button>
                    <br />
                    <br />
                  </Paragraph>
                );
              }
            })}

            <Modal
              visible={showPreviewPdfModal}
              width="80vw"
              okText="Continue"
              footer={null}
              onCancel={() => viewPdfClosed()}
            >
              {showPreviewPdfModal ? (
                <ViewPDF
                  filename={selectedDocument.title}
                  docId={selectedDocument.docId}
                ></ViewPDF>
              ) : (
                ""
              )}
            </Modal>
          </Paragraph>
        </Paragraph>
      ) : null}
    </>
  );
};
export default {
  Component: ReviewCSExamInformation,
};
