import React, { FC, useState, useEffect } from "react";
import { Result, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import PropTypes, { InferProps } from "prop-types";
import {
  EligibilityPreCheckResponseType,
  PastAttemptsType,
} from "../generated";
import RejectionHistory from "./rejection-history";
const { Title, Paragraph, Text, Link } = Typography;

const VerificationEligiblePropTypes = {
  eligibilityPreCheck: PropTypes.shape({
    eligibility: PropTypes.shape({
      isEligibile: PropTypes.bool,
      eligibilityCheckTimestamp: PropTypes.string,
      responseCode: PropTypes.string,
      responseMsg: PropTypes.string,
    }),
    lastScreenName: PropTypes.string,
    isStep2CSFailed: PropTypes.bool,
  }),
};

export interface ComponentProp {
  eligibilityPreCheck?: EligibilityPreCheckResponseType;
  pastAttempts?: PastAttemptsType[];
}

export type VerificationEligibleProps = InferProps<
  typeof VerificationEligiblePropTypes
> &
  ComponentProp;

const VerificationIneligible: FC<VerificationEligibleProps> = ({
  eligibilityPreCheck,
  pastAttempts,
}) => {
  const [eligibility, setEligibility] = useState<string>("");
  let isRendered = React.useRef(false);

  useEffect(() => {
    if (isRendered.current === false) {
      console.log("ineligible page..");
      const eligibilityResponse =
        eligibilityPreCheck?.eligibility?.responseCode?.trim();
      setEligibility(eligibilityResponse ?? "");
    }

    return () => {
      isRendered.current = true;
    };
  }, [eligibilityPreCheck]);

  const limboSubtitle =
    "You do not have passing performances on Step 1 and Step 2 CK that are valid for ECFMG Certification and you are not currently registered for the exam(s) for which you do not have a valid passing performance. Please note that if you recently took an exam, your general eligibility to apply to the pathways cannot be determined until your score report has been released to you.";
  const limboExtraText = (
    <>
      <Paragraph
        style={{
          textAlign: "initial",
          fontSize: "14px",
          color: "rgba(0, 0, 0, 1)",
        }}
      >
        <Paragraph>
          If you submit an application for the exam(s) for which you do not have
          a passing performance that is valid for ECFMG Certification and are
          registered for the exam(s), you may return to this application and
          reapply. Please allow one day after your registration is accepted
          before you reapply. Your eligibility to pursue a pathway will be
          reevaluated.
        </Paragraph>
        <Paragraph>
          If you recently took an exam, please allow one day after your score
          report indicating a passing performance has been released to you
          before you return to this application and reapply. If your score
          report indicates a failing performance (and it was your first attempt
          at that exam), you will need to reapply for that exam and be
          registered for it before you reapply to the pathways. Please allow one
          day after your registration is accepted before you return to this
          application and reapply. Your eligibility to pursue a pathway will be
          reevaluated.
        </Paragraph>
      </Paragraph>
    </>
  );
  let selectMessage = () => {
    let title: string | JSX.Element = "You Are Currently Ineligible to Apply";
    let subTitle: string | JSX.Element = "";
    let extra = <></>;
    switch (eligibility) {
      case "APPLICANT NOT REGISTERED AFTER JAN 1, 2004": //PATHWAY 6
        subTitle = (
          <Paragraph>
            <Paragraph>
              Please{" "}
              <Link
                href={"https://www.ecfmg.org/contact.html"}
                target="_blank"
                rel="noreferrer"
              >
                contact us
              </Link>{" "}
              for assistance.
            </Paragraph>
          </Paragraph>
        );
        break;
      case "LIMBO - STEP 1":
        subTitle = limboSubtitle;
        extra = limboExtraText;
        break;
      case "LIMBO - STEP 2 CK":
        subTitle = limboSubtitle;
        extra = limboExtraText;
        break;
      case "LIMBO - STEP 1 and STEP 2 CK":
        subTitle = limboSubtitle;
        extra = limboExtraText;
        break;
      case "INVALID APP FOR CERT": //3
        subTitle = "You have not applied for ECFMG Certification.";
        extra = (
          <>
            <Paragraph
              style={{
                textAlign: "initial",
                fontSize: "14px",
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              <Paragraph>
                To be eligible to apply to a Pathway, you must first complete an
                Application for ECFMG Certification. See instructions for
                completing an application in the applicable edition of the ECFMG{" "}
                <i>Information Booklet</i>, available on the{" "}
                <Link
                  href={"https://www.ecfmg.org/resources/publications.html"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Publications
                </Link>{" "}
                page of the ECFMG website.
              </Paragraph>
            </Paragraph>
          </>
        );
        break;
      case "STEP2CS PASS": //2
        subTitle =
          "You have a passing performance on USMLE Step 2 CS that is valid for ECFMG Certification.";
        break;
      case "CERTIFICATION FAILED": //8
        subTitle = "You are already certified by ECFMG.";
        break;
      case "RESTRICTION FAILED": //7
        subTitle = (
          <Paragraph>
            <Paragraph>
              Your account is currently restricted. Please{" "}
              <Link
                href={"https://www.ecfmg.org/contact.html"}
                target="_blank"
                rel="noreferrer"
              >
                contact us
              </Link>{" "}
              for more information.
            </Paragraph>
          </Paragraph>
        );
        break;
    }
    return { title: title, subtitle: subTitle, extra: extra };
  };
  return (
    <>
      <Result
        status="error"
        title={selectMessage().title}
        subTitle={
          <Paragraph style={{ display: "inline-block", marginBottom: "-30px" }}>
            <Paragraph
              style={{
                maxWidth: "400px",
                textAlign: "left",
                display: "inline-block",
                marginTop: "20px",
              }}
            >
              {selectMessage().subtitle}
            </Paragraph>
          </Paragraph>
        }
        extra={
          <>
            <Paragraph style={{ display: "inline-block" }}>
              <Paragraph
                style={{
                  maxWidth: "400px",
                  textAlign: "left",
                  display: "inline-block",
                }}
              >
                {selectMessage().extra}
              </Paragraph>
            </Paragraph>
          </>
        }
      />
      <br />
      {pastAttempts && pastAttempts.length > 0 ? (
        <Paragraph
          style={{
            display: "inline-block",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Paragraph
            style={{
              maxWidth: "400px",
              textAlign: "left",
              display: "inline-block",
            }}
          >
            <RejectionHistory pastAttempts={pastAttempts} />
          </Paragraph>
        </Paragraph>
      ) : null}
    </>
  );
};

export default {
  Component: VerificationIneligible,
};
