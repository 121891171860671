import { UiPortalConfigType } from "../../generated";
import { PATHWAY_ELIGIBILITY_STATUS } from "./versions/statusMapping";
import { PATHWAY_ELIGIBILITY_STATUS_V2 } from "./versions/statusMappingV2";

const PAGE_NAME = "application-status-mappings";
const COMPONENT_NAME="application-status-mappings";

const StatusMappingsFactory = (uiConfig: UiPortalConfigType) => {
    //default returnValue to be before versions were created
  var returnValue = PATHWAY_ELIGIBILITY_STATUS;

  if (uiConfig) {
    uiConfig?.config?.pages?.map((page) => {
      if (page?.pageName === PAGE_NAME) {
        page?.components?.map((component) => {
          if (
            component?.componentName ===
            COMPONENT_NAME
          ) {
            switch (component.version) {
              case 1:
                returnValue = PATHWAY_ELIGIBILITY_STATUS;
                break;
              case 2:
              default:
                //Should be latest version as default
                returnValue = PATHWAY_ELIGIBILITY_STATUS_V2;
                break;
            }
          }
        });
      }
    });
  } else {
    //if data fails to load, load latest
    return PATHWAY_ELIGIBILITY_STATUS_V2;
  }

  return returnValue;
};

export default StatusMappingsFactory;
