import React, { FC } from "react";
import { Divider, Button, Space, Typography } from "antd";
import PropTypes, { InferProps } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Pathway2 } from "../../generated";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  onEditCSExamCallback: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasPassedCSExamInRecentPast: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.shape({
          oscExamRef: PropTypes.shape({
            _id: PropTypes.string,
            examCode: PropTypes.string,
            examShortTitle: PropTypes.string,
            examFullName: PropTypes.string,
          }),
          schoolRef: PropTypes.shape({
            _id: PropTypes.number,
            countryName: PropTypes.string,
            name: PropTypes.string,
          }),
          examDate: PropTypes.string,
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
};

export interface IProps {
  __typename?: "ReviewCSExamInformation";
  onEditCSExamCallback: () => void;
  initialValues?: Pathway2;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

// Pathway greater than 2
const ReviewCSExamInformation: FC<ComponentProps> = ({
  onEditCSExamCallback,
  initialValues,
}) => {
  var applicantProvidedData = undefined;
  var userResponse = initialValues?.userResponse;

  return (
    <>
      {userResponse?.hasPassedFromQualifiedSchoolInRecentPast === false ? (
        <Paragraph>
          <Divider plain orientation="left">
            <Space size="middle">
              <Text strong>Pathway 2</Text>
              <Button onClick={onEditCSExamCallback}>
                Edit
              </Button>
            </Space>
          </Divider>
          <Paragraph>
            <Paragraph>I do not meet the requirements for Pathway 2.</Paragraph>
          </Paragraph>
        </Paragraph>
      ) : null}
    </>
  );
};
export default {
  Component: ReviewCSExamInformation,
};
