import moment from "moment";

export const DATE_PICKER_PLACEHOLDER = "Select a date";
export const DATE_FORMAT = "MM/DD/YYYY";
export const PATHWAY6_DATE_FORMAT = "DD-MMM-YYYY";
export const LICENSURE_DOCUMENT_STATUS = {
  requestedSentToECFMG: "requestedSentToECFMG",
  requestedWillSend: "requestedWillSend",
  issuedInLast90Days: "issuedInLast90Days",
  doNotHaveCannotObtain: "doNotHaveCannotObtain",
};
export const AUTH_TOKEN = "auth-token";

export const PATHWAYS = {
  Pathway1: "PATHWAY1",
  Pathway2: "PATHWAY2",
  Pathway3: "PATHWAY3",
  Pathway4: "PATHWAY4",
  Pathway5: "PATHWAY5",
  Pathway6: "PATHWAY6",
  PathwayX: "PATHWAYX",
};

export enum PHYSICIAN_ENC_ALLOWED_ACTIONS {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export const CEX_STATUS = {
  MINICEX_CREATED_PHYSICIAN_INVITE_PENDING: "Request submission pending",
  MINICEX_REJECTED_PHYSICIAN_INVITE_FAILED:
    "Request rejected – e-mail undeliverable",
  MINICEX_CREATED_PHYSICIAN_INVITE_SENT: "Request sent",
  MINICEX_CREATED_PHYSICIAN_REGISTERED: "Request sent",
  MINICEX_REJECTED_BY_PHYSICIAN: "Request rejected by evaluator",
  MINICEX_ACCEPTED: "Request accepted",
  MINICEX_COMPLETED: "Mini-CEX complete",
  MINICEX_EXPIRED: "Request rejected – no response from evaluator",
  MINICEX_WITHDRAWN: "Request removed by applicant",
  MINICEX_REJECTED_BY_SYSTEM: "Request withdrawn",
  MINICEX_REJECTED_BY_STAFF: "Request withdrawn",
  MINICEX_CANCELED_BY_STAFF: "Cancelled",
};

export const CEX_STATUS_DETAIL = {
  MINICEX_CREATED_PHYSICIAN_INVITE_PENDING:
    "Invitation has not yet been sent. Please allow 24 hours. The status will update when it is delivered.",
  MINICEX_CREATED_PHYSICIAN_INVITE_SENT:
    "Invitation sent *InviteDate*; Evaluator has *NumberOfDays* day(s) remaining to accept the request.",
  MINICEX_CREATED_PHYSICIAN_REGISTERED:
    "Invitation sent *InviteDate*; Evaluator has *NumberOfDays* day(s) remaining to accept the request.",
  MINICEX_ACCEPTED:
    "Evaluator has *NumberOfDays* day(s) remaining to complete the Mini-CEX",
  MINICEX_EXPIRED:
    "The Mini-CEX was not completed within the required time. Remove this evaluator and add a new evaluator.",
  MINICEX_COMPLETED: "Mini-CEX complete",
  MINICEX_WITHDRAWN: "You removed this physician evaluator request.",
  MINICEX_REJECTED_BY_PHYSICIAN:
    "The evaluator has rejected the request to complete a Mini-CEX on your behalf.",
  MINICEX_REJECTED_PHYSICIAN_INVITE_FAILED:
    "The e-mail request could not be delivered. Please remove this evaluator, confirm that the e-mail address for the evaluator is accurate, and re-enter the information; or enter a new evaluator.",
  MINICEX_REJECTED_BY_SYSTEM:
    "The request has been withdrawn by the system. Please contact ECFMG for more information.",
  MINICEX_REJECTED_BY_STAFF:
    "The request has been withdrawn by the system. Please contact ECFMG for more information.",
};

export enum RoutingFlow {
  // not pathway6
  Login_NotPw6,
  // 03307790
  Login_Pw6, // step2CSFailed = true
  // 10164861
  Login_Pw12_2b, // step2CSFailed = false, schoolPathway = PATHWAY2, isYearEligible = true
  // 03572310
  Login_Pw12_2b_6, // step2CSFailed = false, schoolPathway = PATHWAY2, isYearEligible = false
  // 09849340
  Login_Pw12_2b_345, // step2CSFailed = false, schoolPathway = PATHWAY3 or PATHWAY4 or PATHWAY5, isYearEligible = true
  // 06517056, 08048639
  Login_Pw12_2b_345_6, // step2CSFailed = false, schoolPathway != PATHWAY3 or PATHWAY4 or PATHWAY5 || isYearEligible = false
}

export const getRoutingFlow = (eligibilityResult: any, graduation: any) => {
  if (eligibilityResult?.eligibility?.isEligible) {
    if (eligibilityResult.isStep2CSFailed) {
      return RoutingFlow.Login_Pw6;
    }
    if (graduation?.schoolEligibility?.schoolPathway === "PATHWAY2") {
      if (graduation?.isYearEligible) {
        return RoutingFlow.Login_Pw12_2b;
      }
      return RoutingFlow.Login_Pw12_2b_6;
    }
    if (
      graduation?.schoolEligibility?.schoolPathway === "PATHWAY3" ||
      graduation?.schoolEligibility?.schoolPathway === "PATHWAY4" ||
      graduation?.schoolEligibility?.schoolPathway === "PATHWAY5"
    ) {
      if (graduation?.isYearEligible) {
        return RoutingFlow.Login_Pw12_2b_345;
      }
      return RoutingFlow.Login_Pw12_2b_345_6;
    }
    return RoutingFlow.Login_Pw12_2b_345_6;
  }
};

export const getPhysicianName = (
  lastName: string | undefined | null,
  restOfName?: string | undefined | null
) => {
  if (!lastName && !restOfName) {
    return "";
  }
  if (lastName && !restOfName) {
    return lastName;
  }
  if (lastName && restOfName) {
    return `${lastName}, ${restOfName}`;
  }
};

export const TAB_STATES = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const RESPONSE_TYPE = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
};

export const CaseDetailsStates = {
  APPLICATION_SUBMITTED: "APPLICATIONSUBMITTED",
  APPLICATION_COMPLETED: "APPLICATIONCOMPLETED",
  ELIGIBILITY_REVIEW: "ELIGIBILITYREVIEW",
  QA_READY: "QAREADY",
  QA_REVIEW: "QAREVIEW",
};

export const getDaysUntilExpiration = (date: any) => {
  //Get difference in miliseconds
  var expiresAtInMilliseconds =
    moment(date).toDate().getTime() - moment().toDate().getTime();

  //Convert miliseconds to days
  var expiresAtInDays = Math.trunc(
    expiresAtInMilliseconds / (60 * 60 * 24 * 1000)
  );

  return expiresAtInDays;
};

export const ERROR_MESSAGES = [
  {
    key: "MINICEX.CREATE.PHYSICIAN_ACCOUNT_BLOCKED",
    value:
      "The physician evaluator you requested is unable to receive requests. Please contact your physician evaluator for more information. Please enter a different physician evaluator.",
  },
  {
    key: "MINICEX.CREATE.PHYSICIAN_LIMIT_FOR_APPLICANT_COUNT_REACHED",
    value:
      "The physician evaluator you requested is unable to receive requests. Please contact your physician evaluator for more information. Please enter a different physician evaluator.",
  },
  {
    key: "MINICEX.CREATE.PHYSICIAN_OPTED_OUT",
    value:
      "The physician evaluator you requested is unable to receive requests. Please contact your physician evaluator for more information. Please enter a different physician evaluator.",
  },
  {
    key: "Each evaluator may observe you in no more than two encounters. Please enter a different evaluator.", //MINICEX.CREATE.MAX_ALLOWED_MINICEX_FOR_PHYSICIAN_LIMIT_REACHED
    value:
      "Each evaluator may observe you in no more than two encounters. Please enter a different evaluator.",
  },
  {
    key: "The evaluator you entered has declined to participate in completing Mini-CEX evaluations for Pathway 6. Please enter a different evaluator.", //"MINICEX.CREATE.PHYSICIAN_OPTED_OUT",
    value:
      "The physician evaluator you requested is unable to receive requests. Please contact your physician evaluator for more information. Please enter a different physician evaluator.",
  },
];
