import {FeatureFlagConfig} from '../components/feature-flag-react-lite';
import defaultValues from './feature-flag-default-values.json';
import {GetFeatureFlagEnvironment} from '../util/environmentVariables';

var featureFlagConfig : FeatureFlagConfig = {
    cache: 30 * 1000,
    url: GetFeatureFlagEnvironment(),
    fallbackFlagValues: defaultValues,
    axiosRequestConfig: undefined
}
export default featureFlagConfig;