import React, { FC } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  ApolloClient,
} from "@apollo/client";

import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { useFeatureFlags } from "./components/feature-flag-react-lite";

import useAuth from "./components/auth/use-auth";

const apolloUrl =
  process.env.REACT_APP_FUNCTION_ENDPOINT !== null &&
  typeof process.env.REACT_APP_FUNCTION_ENDPOINT !== "undefined"
    ? `${process.env.REACT_APP_FUNCTION_ENDPOINT}api/graphql`
    : "http://localhost:7071/api/graphql";

const Index2: FC<any> = () => {
  const { authToken } = useAuth();
  const { GetFeatureFlagByName } = useFeatureFlags();

  var httpLink;

  if (GetFeatureFlagByName("ENABLE_APOLLO_BATCH_APPLICANT") === "true") {
    httpLink = new BatchHttpLink({
      uri: apolloUrl,
      batchMax:
        parseInt(GetFeatureFlagByName("APOLLO_BATCH_MAX_APPLICANT")) ?? 1, //by default disable batching
      batchInterval:
        parseInt(GetFeatureFlagByName("APOLLO_BATCH_INTERVAL_APPLICANT")) ?? 0,
    });
  } else {
    httpLink = createHttpLink({
      uri: apolloUrl,
    });
  }

  const authLink = new ApolloLink((operation, forward) => {
    // Retrieve the authorization token from local storage.
    //const token = authToken();
    var getToken = () => {
      var token = authToken();
      console.log("INDEX2: Getting Token", token);
      //console.log(operation);
      var header = token ? `Bearer ${token}` : null;
      console.log("header", header);
      return header;
    };

    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        authorization: getToken(),
        portal: "Applicant",
        //authorization: token ? 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.VbDR8MsgovwzpJNobSAi0LPBUnglJkNQNYhU-jLNKdE' : ''
      },
    });
    // Call the next link in the middleware chain.
    return forward(operation);
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};
// If you want your app to work offline and load faster, you can change
export default Index2;
