import React, { FC } from "react";
import { Result, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import useAuth from "../auth/use-auth";
const { Paragraph, Link } = Typography;

const WarningPage: FC<any> = (props) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const logoutClick = () => {
    logout();
    navigate(`/`);
  };

  return (
    <Result
      status="warning"
      title="ECFMG is no longer accepting applications to Pathways for ECFMG Certification for 2024 Match."
      subTitle={[
        <Paragraph key="warning-paragraph">
          Please monitor the ECFMG website at{" "}
          <Link href="https://www.ecfmg.org" target="_blank" rel="noreferrer" key="ecfmg-link">
            www.ecfmg.org
          </Link>{" "}
          for information on meeting the requirements for ECFMG Certification
          for participation in the 2025 Match.
        </Paragraph>,
      ]}
      extra={[
        <Button onClick={logoutClick} size='large' type="primary" key="console">
          Return to Login Page
        </Button>,
      ]}
    />
  );
};

export default WarningPage;
