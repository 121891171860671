import { AUTH_TOKEN } from '../constants';


export default class auth {

    authToken = sessionStorage.getItem(AUTH_TOKEN)

    public logout():void{
        sessionStorage.removeItem(AUTH_TOKEN)
    }

    public isAuthenticated():boolean{
        return (this.authToken !== null && typeof this.authToken !== 'undefined');
    }
}