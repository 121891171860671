import React, {useState} from 'react';
import AuthContext from './auth-context';
import { AUTH_TOKEN } from '../constants';
import Auth from './auth';
import moment from 'moment';
import * as jose from 'jose';

const AuthProvider:any = (params:any):JSX.Element => {

    let userHasValidToken = () => {
        if ((authToken === null || typeof authToken === 'undefined' || authToken === '') && sessionStorage.getItem(AUTH_TOKEN) !== null) {
            console.log(`AUTHPROVIDER: Setting token from session storage since state token doesn't exist`);
            setAuthToken(sessionStorage.getItem(AUTH_TOKEN)??'');
        } 
        
        if(authToken !== null && typeof authToken !== 'undefined' && authToken !== ''){
            try {
                const token = jose.decodeJwt(authToken);
                var tokenExpiration = parseInt((token as any).exp);
                var minutesRemining =  moment.unix(tokenExpiration).diff(new Date(),'minutes')
                return (minutesRemining > 0)
            } catch (error) {
                console.log(error);
                return false;
            }
        }
        return false;
    }


            /*
        var decodedToken=jwt.decode(token, {complete: true});
var dateNow = new Date();

if(decodedToken.exp < dateNow.getTime())
    isExpired = true;
        return (authToken !== null && typeof authToken !== 'undefined' && authToken !== '');
        */
    const [auth] = useState<Auth>(new Auth());
    const [authToken, setAuthToken] = useState<string>('');

    let setToken = (token:string) => {
        console.log(`AUTHPROVIDER: setting token`,token);
        sessionStorage.setItem(AUTH_TOKEN, token);
        setAuthToken(token);
    }
    let getToken = ():string =>{
        return authToken
    }
    let logout =  () =>
    {
        console.log(`AUTHPROVIDER: logging out`,authToken);
        setAuthToken(''); 
        sessionStorage.removeItem(AUTH_TOKEN)
        console.log(`AUTHPROVIDER: logged out`,authToken);
    }
    
    return (
        <AuthContext.Provider 
            value={{
                authToken: getToken, 
                setAuthToken:setToken, 
                userHasValidToken:userHasValidToken,
                logout: (): void =>  {logout()} 
            }}>
            {params.children}
        </AuthContext.Provider>
    )
}
export default AuthProvider;