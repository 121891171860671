import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs, Outline } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { Button, Spin, Typography } from "antd";
import { useQuery } from "@apollo/client/react";
import { ApplicantViewPdfDownloadDocumentDocument } from "../generated";
const { Title, Paragraph, Text } = Typography;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//pass in filename for props
function ViewPDF(props: any) {
  const [filepath, setFilepath] = useState<string | undefined>(undefined);
  const [currentPageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const {
    loading: getSasLoading,
    error: getSasError,
    data: getSasData,
  } = useQuery(ApplicantViewPdfDownloadDocumentDocument, {
    variables: { filename: props.docId },
  });

  const showPDF = () => {
    setFilepath(getSasData?.downloadDocument?.token?.uri);
  };

  const onDocumentLoadSuccess = (numPages: PDFDocumentProxy) => {
    console.log("OnDocLoadSuccess");
    setNumPages(numPages.numPages);
  };

  const decreasePage = () => {
    if (currentPageNumber - 1 > 0) {
      setPageNumber(currentPageNumber - 1);
    }
  };

  const increasePage = () => {
    if (currentPageNumber + 1 <= numPages) {
      setPageNumber(currentPageNumber + 1);
    }
  };

  const useWindowWidth = () => {
    const [windowWidth, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return windowWidth;
  };
  const width = useWindowWidth();

  if (getSasLoading) {
    return <Spin></Spin>;
  }
  return (
    <Paragraph>
      <Title level={3}>{props.filename}</Title>
      {getSasData && !filepath ? showPDF() : null}
      <Document
        file={filepath}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Title level={2}>Loading...</Title>}
        noData={""}
        renderMode={"canvas"}
      >
        <Outline></Outline>
        {numPages > 1 && (
          <Paragraph style={{ textAlign: "center" }}>
            <Button onClick={() => decreasePage()}>Prev</Button>
            <Text style={{ padding: "0 10px" }}>
              Page {currentPageNumber} of {numPages}
            </Text>
            <Button onClick={() => increasePage()}>Next</Button>
          </Paragraph>
        )}
        <hr />
        <Page
          pageNumber={currentPageNumber}
          renderMode={"canvas"}
          width={Math.min(width * 0.9 - 100, 600)}
        />
        <hr />
        {numPages > 1 && (
          <Paragraph style={{ textAlign: "center" }}>
            <Button onClick={() => decreasePage()}>Prev</Button>
            <Text style={{ padding: "0 10px" }}>
              Page {currentPageNumber} of {numPages}
            </Text>
            <Button onClick={() => increasePage()}>Next</Button>
          </Paragraph>
        )}
      </Document>
    </Paragraph>
  );
}

export default ViewPDF;
