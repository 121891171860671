import React, { FC } from "react";
import PropTypes, { InferProps } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Divider, Space, Button, Typography } from "antd";
import { Pathway6 } from "../../generated";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  onEditPathway6Callback: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasAgreedToProvidePhysicianInfo: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        data: PropTypes.shape({
          acknowledgedTimestamp: PropTypes.string,
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
};

export interface IProps {
  __typename?: "ReviewPathway6Information";
  onEditPathway6Callback: () => void;
  initialValues?: Pathway6;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const ReviewPathway6Information: FC<ComponentProps> = ({
  onEditPathway6Callback,
  initialValues,
}) => {
  return (
    <>
      {initialValues?.userResponse ? (
        <Paragraph>
          <Divider plain orientation="left">
            <Space size="middle">
              <Text strong>Pathway 6</Text>
              <Button onClick={onEditPathway6Callback}>
                Edit
              </Button>
            </Space>
          </Divider>

          <Paragraph>
            I am applying to Pathway 6. I certify that I have read and
            understand all requirements for Pathway 6.
          </Paragraph>
        </Paragraph>
      ) : null}
    </>
  );
};

export default {
  Component: ReviewPathway6Information,
};
