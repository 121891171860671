import React, { FC, useState, useEffect } from 'react';
import {
  Select,
  Row,
  Col,
  DatePicker,
  Radio,
  Form,
  Button,
  Input,
  Space,
  Spin,
  Typography,
} from 'antd';
import moment from 'moment';
import PropTypes, { InferProps } from 'prop-types';
import { useQuery } from '@apollo/client/react';
import _ from 'lodash';
import { DATE_FORMAT } from '../constants';
import { ApplicantQualificationC1RegulatoryAuthorityDocument } from '../../generated';
const { Option } = Select;
const { Title, Paragraph, Text, Link } = Typography;

type formResult = {
  _id: string;
  regulatoryAuthorityRef: {
    _id: number;
    orgName: string;
    countryName: string;
  };
  licenseIssueDate: string;
  isCurrentlyLicensed: boolean;
  licenseExpiryDate: string;
  hasDisciplinaryAction: boolean;
};

var authority_list: any = [];
var countryList: any = [];

const ComponentPropTypes = {
  onFormCancel: PropTypes.func,
  onFormSubmitSuccess: PropTypes.func,
  initialValues: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    data: PropTypes.shape({
      regulatoryAuthorityRef: PropTypes.shape({
        _id: PropTypes.number.isRequired,
        orgName: PropTypes.string.isRequired,
        countryName: PropTypes.string.isRequired,
      }).isRequired,
      licenseIssueDate: PropTypes.string.isRequired,
      licenseExpiryDate: PropTypes.string,
      isCurrentlyLicensed: PropTypes.bool.isRequired,
      hasDisciplinaryAction: PropTypes.bool.isRequired,
      licensureDocumentStatus: PropTypes.string,
    }).isRequired,
  }),
};

export interface IProps {
  __typename?: 'QualificationC1';
  onFormCancel?: () => void;
  onFormSubmitSuccess?: (formValues: any) => void;
  initialValues: {
    _id: string;
    data: {
      regulatoryAuthorityRef: {
        _id: number;
        orgName: string;
        countryName: string;
      };
      licenseIssueDate: string;
      licenseExpiryDate?: string;
      isCurrentlyLicensed: boolean;
      hasDisciplinaryAction: boolean;
      licensureDocumentStatus?: string;
    };
  };
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const QualificationC1: FC<ComponentProps> = ({
  onFormCancel,
  onFormSubmitSuccess,
  initialValues,
}) => {
  const { loading, error, data } = useQuery(
    ApplicantQualificationC1RegulatoryAuthorityDocument
  );

  const _id = initialValues?._id;

  const [regulatoryAuthorityId, setRegulatoryAuthorityId] = useState<
    number | undefined
  >(initialValues?.data?.regulatoryAuthorityRef?._id ?? undefined);

  const [countryName, setCountryName] = useState<string | undefined>(
    initialValues?.data?.regulatoryAuthorityRef.countryName ?? undefined
  );

  const [orgName, setOrgName] = useState<string | undefined>(
    initialValues?.data?.regulatoryAuthorityRef.orgName ?? undefined
  );
  const [licenseIssueDate, setDateLicensed] = useState<string | undefined>(
    initialValues?.data?.licenseIssueDate ?? undefined
  );

  const [isCurrentlyLicensed, setIsCurrentlyLicensed] = useState<
    boolean | undefined
  >(initialValues?.data?.isCurrentlyLicensed ?? undefined);
  const [licenseExpiryDate, setLicenseExpiryDate] =
    useState<string | undefined>(initialValues?.data?.licenseExpiryDate) ??
    undefined;
  const [hasDisciplinaryAction, setIsSubjectToDisciplinaryAction] = useState<
    boolean | undefined
  >(initialValues?.data?.hasDisciplinaryAction ?? undefined);

  const [form] = Form.useForm();

  type AuthOrg = {
    _id: string;
    orgName: string;
  };

  const populateAuthOrgList = (countryname: string) => {
    var tempAuthOrg: AuthOrg[] = [];
    authority_list.map((record: any) => {
      //console.log(record);
      if (record.countryName === countryname) {
        const orgID = {
          _id: record._id,
          orgName: record.orgName,
        } as AuthOrg;
        if (!tempAuthOrg.includes(orgID)) {
          tempAuthOrg.push(orgID);
        }
      }
    });
    return tempAuthOrg.sort((a, b) => a.orgName.localeCompare(b.orgName));
  };

  const [authOrgNameList, setAuthOrgNameList] = useState<any>([]);

  useEffect(() => {
    if (data) {
      //get the regulatoryAuthority list
      authority_list = data.regulatoryAuthority;

      //populate country list
      if (authority_list) {
        authority_list.map((record: any) => {
          if (!countryList.includes(record.countryName)) {
            countryList.push(record.countryName);
          }
        });
      }
      countryList.sort();

      setAuthOrgNameList(
        initialValues?.data?.regulatoryAuthorityRef
          ? populateAuthOrgList(
              initialValues?.data?.regulatoryAuthorityRef.countryName
            )
          : []
      );
    }
  }, [data]);

  const onFinish = async (values: any) => {
    console.log(licenseIssueDate);
    if (onFormSubmitSuccess) {
      onFormSubmitSuccess({
        _id: _id,
        regulatoryAuthorityRef: {
          _id: regulatoryAuthorityId,
          countryName: countryName,
          orgName: orgName,
        },
        licenseIssueDate: licenseIssueDate,
        isCurrentlyLicensed: isCurrentlyLicensed,
        licenseExpiryDate: licenseExpiryDate,
        hasDisciplinaryAction: hasDisciplinaryAction,
      } as formResult);
    }
  };

  const onCancel = async () => {
    if (onFormCancel) {
      onFormCancel();
    }
  };

  const checkIsDateInPast = (rule: any, value: any) => {
    if (value && !moment(value).isBefore(new Date())) {
      return Promise.reject('Date must be in the past');
    }
    return Promise.resolve();
  };

  //populate auth org name list base on country selected
  const onCountrySelect = (value: any) => {
    console.log('qualification-c1 - onCountrySelect');
    //reset the orgs
    var tempAuthOrg: any = populateAuthOrgList(value);
    setAuthOrgNameList(tempAuthOrg);
    form.setFieldsValue({
      authority: undefined,
    });
    setCountryName(value);
    setOrgName(undefined);
  };
  const onOrgNameChange = async (value: any) => {
    const id = _.find(authority_list, {
      orgName: value,
      countryName: countryName,
    })._id;
    console.log(id);
    setOrgName(value);
    setRegulatoryAuthorityId(id);
  };

  const disabledDateLicensed = (current: any) => {
    return current > moment().endOf('day');
  };

  const disabledDateLicenseExpired = (current: any) => {
    return (
      current > moment().endOf('day') ||
      current < moment(licenseIssueDate).startOf('day')
    );
  };

  if (loading) {
    return <Spin></Spin>;
  }

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Form.Item style={{ display: 'none' }}>
          <Input name="id" value={_id}></Input>
        </Form.Item>
        <Row>
          <Col span={24}>
            <Title level={4} style={{ fontWeight: 600 }}>
              License/Registration Country
            </Title>
            <Paragraph>
              Country in which you are/were licensed/registered:
            </Paragraph>
            <Form.Item
              name="country"
              rules={[{ required: true, message: 'Please select a country' }]}
              initialValue={countryName}
            >
              <Select
                id="licensed-country-select"
                showSearch={true}
                placeholder="Select Country"
                onChange={(e) => onCountrySelect(e)}
                defaultValue={countryName}
              >
                {countryList.map((countryItem: any) => (
                  <Option key={countryItem._id} value={countryItem}>
                    {countryItem}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4} style={{ fontWeight: 600 }}>
              License/Registration Authority
            </Title>
            <Paragraph>
              Name of the authority with which you are/were licensed/registered:
            </Paragraph>
            <Form.Item
              name="authority"
              rules={[
                { required: true, message: 'Please select an authority' },
              ]}
              initialValue={orgName}
            >
              <Select
                id="licensing-authority-select"
                showSearch={true}
                placeholder="Select Authority"
                onChange={(value) => onOrgNameChange(value)}
                defaultValue={orgName}
                value={orgName}
                disabled={countryName ? false : true}
              >
                {authOrgNameList.map((authorityItem: any) => (
                  <Option key={authorityItem._id} value={authorityItem.orgName}>
                    {authorityItem.orgName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4} style={{ fontWeight: 600 }}>
              Date Licensed/Registered
            </Title>
            <Paragraph>
              Date on which you were initially licensed/registered with this
              authority:{' '}
            </Paragraph>
            <Form.Item
              name="dateLicensed"
              rules={[
                { required: true, message: 'Please select the licensure date' },
                { validator: checkIsDateInPast },
              ]}
              initialValue={
                licenseIssueDate ? moment(licenseIssueDate) : undefined
              }
            >
              <DatePicker
                id="date-licensed-datepicker"
                onChange={(date) =>
                  date
                    ? setDateLicensed(date.toString())
                    : setDateLicensed(undefined)
                }
                format={DATE_FORMAT}
                disabledDate={disabledDateLicensed}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4} style={{ fontWeight: 600 }}>
              License/Registration Current?
            </Title>
            <Paragraph>Is this license/registration currently valid?</Paragraph>
          </Col>
        </Row>
        <Form.Item
          name="isCurrentlyLicensed"
          rules={[
            { required: true, message: 'Please select the appropriate answer' },
          ]}
          initialValue={
            isCurrentlyLicensed === undefined
              ? undefined
              : isCurrentlyLicensed === true
              ? 'Yes'
              : 'No'
          }
        >
          <Radio.Group
            onChange={(e) => setIsCurrentlyLicensed(e.target.value === 'Yes')}
            defaultValue={
              isCurrentlyLicensed === undefined
                ? undefined
                : isCurrentlyLicensed === true
                ? 'Yes'
                : 'No'
            }
          >
            <Row>
              <Col span={23} offset={1}>
                <Radio id="is-currently-licensed-yes-option" value={'Yes'}>
                  <Text strong>Yes</Text>
                </Radio>
              </Col>
            </Row>
            <Row>
              <Col span={23} offset={1}>
                <Radio id="is-currently-licensed-no-option" value={'No'}>
                  <Text strong>No</Text>
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
        {isCurrentlyLicensed === false ? (
          <Row>
            <Col span={23} offset={1}>
              <Title level={4} style={{ fontWeight: 600 }}>
                Expiration Date
              </Title>
              <Paragraph>
                Date on which the license/registration expired:
              </Paragraph>
              <Form.Item
                name="dateExpired"
                rules={[
                  {
                    required: true,
                    message: 'Please select the date licensure expired',
                  },
                  { validator: checkIsDateInPast },
                ]}
                initialValue={
                  licenseExpiryDate ? moment(licenseExpiryDate) : undefined
                }
              >
                <DatePicker
                  id="date-license-expired-datepicker"
                  onChange={(date) =>
                    date
                      ? setLicenseExpiryDate(date.toString())
                      : setLicenseExpiryDate(undefined)
                  }
                  defaultValue={
                    licenseExpiryDate ? moment(licenseExpiryDate) : undefined
                  }
                  format={DATE_FORMAT}
                  disabledDate={disabledDateLicenseExpired}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Row>
          <Col span={24}>
            <Title level={4} style={{ fontWeight: 600 }}>
              Disciplinary Action?
            </Title>
            <Paragraph>
              Has this license/registration been subject to disciplinary action?
            </Paragraph>
            <Form.Item
              name="isSubjectToDisciplinaryAction"
              rules={[
                {
                  required: true,
                  message: 'Please select the approprate answer',
                },
              ]}
              initialValue={
                hasDisciplinaryAction === undefined
                  ? undefined
                  : hasDisciplinaryAction === true
                  ? 'Yes'
                  : 'No'
              }
            >
              <Radio.Group
                onChange={(e) =>
                  setIsSubjectToDisciplinaryAction(e.target.value === 'Yes')
                }
                defaultValue={
                  hasDisciplinaryAction === undefined
                    ? undefined
                    : hasDisciplinaryAction === true
                    ? 'Yes'
                    : 'No'
                }
              >
                <Radio id="disciplinary-action-yes-option" value={'Yes'}>
                  <Paragraph
                    style={{ whiteSpace: 'normal', display: 'inline' }}
                  >
                    <Text strong>Yes</Text>
                    {hasDisciplinaryAction === true && (
                      <Paragraph
                        style={{
                          paddingLeft: '25px',
                          fontSize: '14px',
                          color: 'rgba(0, 0, 0, 1)',
                        }}
                      >
                        <Paragraph>
                          Please note that you may continue with this
                          application; however, if ECFMG determines that you are
                          ineligible for this Pathway as a result of
                          disciplinary action on your license, ECFMG will
                          contact you regarding next steps.
                        </Paragraph>
                      </Paragraph>
                    )}
                  </Paragraph>
                </Radio>
                <Radio id="disciplinary-action-no-option" value={'No'}>
                  <Text strong>No</Text>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Space direction="horizontal">
              <Button
                size="large"
                id="qc1-cancel-button"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                id="qc1-continue-button"
                type="primary"
                htmlType="submit"
              >
                Continue
              </Button>
            </Space>
          </Col>
        </Row>

        {/* <Col span={24} style={{ textAlign: "left" }}>
            <Space direction="horizontal">
              <Button type="primary" htmlType="submit">
                Continue
              </Button>

              <Button onClick={() => onCancel()}>Cancel</Button>
            </Space>
          </Col> */}
      </Form>
    </>
  );
};

QualificationC1.defaultProps = {
  onFormCancel: () => {},
  onFormSubmitSuccess: () => {},
};
