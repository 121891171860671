import React, { FC } from "react";
import { AdditionalDocumentCard } from "./additional-document-card";
import PropTypes, { InferProps } from "prop-types";
import { Row, Col, Typography } from "antd";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  onDelete: PropTypes.func,
  numberOfCardsPerRow: PropTypes.number.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      docId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      docType: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export interface IProps {
  __typename?: "AdditionalDocumentCardList";
  onDelete?: (documentID: string) => void;
  numberOfCardsPerRow: number;
  documents: {
    docId: string;
    title: string;
    docType: string;
  }[];
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const AdditionalDocumentCardList: FC<ComponentProps> = ({
  onDelete,
  numberOfCardsPerRow,
  documents,
}) => {
  const cardList = documents?.map((document) => (
    <Col
      key={document.docId}
      lg={24 / numberOfCardsPerRow}
      md={24 / (numberOfCardsPerRow + 1)}
      xs={24}
    >
      <AdditionalDocumentCard
        key={document.docId}
        displayValues={document}
        onDelete={() => onDelete && onDelete(document.docId)}
      />
    </Col>
  ));

  return (
    <Paragraph
      className="space-align-container"
      style={{ backgroundColor: "#f7f7f7" }}
    >
      {cardList}
    </Paragraph>
  );
};

AdditionalDocumentCard.defaultProps = {
  onDelete: () => {},
};
