import { FC } from "react";
import { Typography } from "antd";
import _ from "lodash";
import { InferProps } from "prop-types";
import { PastAttemptsType } from "../generated";
const { Paragraph } = Typography;

const ComponentPropTypes = {};
export interface IProps {
  __typename?: "RejectionHistory";
  pastAttempts?: PastAttemptsType[];
}
export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const RejectionHistory: FC<ComponentProps> = ({ pastAttempts }) => {
 return (
   <>
     {pastAttempts && pastAttempts?.length > 0 ? (
       <Paragraph>
         {" "}
         Our records indicate you submitted a previous Application for Pathways for ECFMG
         Certification and that this application was rejected.
       </Paragraph>
     ) : null}
   </>
 );
};

export default RejectionHistory;
