import React, { FC, useState } from "react";
import { Button, Table, Modal, Col, Row, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import moment from "moment";
import {
  PHYSICIAN_ENC_ALLOWED_ACTIONS,
  CEX_STATUS,
  CEX_STATUS_DETAIL,
  PATHWAY6_DATE_FORMAT,
  getPhysicianName,
} from "../constants";
import { A_CaseDetails } from "../../generated";
import { getDaysUntilExpiration } from "../constants";
import { PhysicianStatusHistoryModal } from "./physician-status-history-modal";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  minicex: PropTypes.arrayOf(
    PropTypes.shape({
      dataVersion: PropTypes.number.isRequired,
      encounterId: PropTypes.string.isRequired,
      physicianEmail: PropTypes.string.isRequired,
      physicianLastNameProvidedByApplicant: PropTypes.string.isRequired,
      physicianRestOfNameProvidedByApplicant: PropTypes.string.isRequired,
      physicianLastNameProvidedByPhysician: PropTypes.string.isRequired,
      physicianRestOfNameProvidedByPhysician: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      expiresAt: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      statusDetail: PropTypes.string.isRequired,
      stateModel: PropTypes.arrayOf(
        PropTypes.shape({
          state: PropTypes.string.isRequired,
          timestamp: PropTypes.string.isRequired,
        })
      ),
      allowedActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    })
  ),
  caseDetails: PropTypes.shape({
    output: PropTypes.string,
  }),
  addCEXEncounter: PropTypes.func.isRequired,
  removeCEXEncounter: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export interface IProps {
  __typename?: "PhysicianEncounterListTable";
  minicex: {
    dataVersion: number;
    encounterId: string;
    physicianEmail: string;
    physicianLastNameProvidedByApplicant: string;
    physicianRestOfNameProvidedByApplicant: string;
    physicianLastNameProvidedByPhysician: string;
    physicianRestOfNameProvidedByPhysician: string;
    createdAt: string;
    expiresAt: string;
    state: string;
    statusDetail: string;
    stateModel: {
      state: string;
      timestamp: string;
    }[];
    allowedActions: string[];
  }[];
  caseDetails?: A_CaseDetails;
  addCEXEncounter: () => void;
  removeCEXEncounter: (index: any) => void;
  loading: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const PhysicianEncounterListTable: FC<ComponentProps> = ({
  minicex,
  caseDetails,
  addCEXEncounter,
  removeCEXEncounter,
  loading,
}) => {
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [showEncounterHistoryModal, setShowEncounterHistoryModal] =
    useState<boolean>(false);
  const [statusHistory, setStatusHistory] = useState<any[]>([]);

  const renderButtons = (record: any, index: any) => {
    if (
      record.allowedActions.includes(PHYSICIAN_ENC_ALLOWED_ACTIONS.REMOVE) &&
      caseDetails?.state !== "ApplicationCompleted"
    ) {
      return (
        <Paragraph>
          <Button
            type="primary"
            danger
            style={{ width: "80px", paddingLeft: "13px" }}
            onClick={() => removeCEXEncounter(index)}
          >
            Remove
          </Button>
        </Paragraph>
      );
    } else if (
      record.allowedActions.includes(PHYSICIAN_ENC_ALLOWED_ACTIONS.ADD)
    ) {
      return (
        <Paragraph>
          <Button
            type="primary"
            style={{ width: "80px" }}
            onClick={() => addCEXEncounter()}
          >
            Add
          </Button>
        </Paragraph>
      );
    }
  };

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  const getStatusDetail = (obj: any, record: any) => {
    switch (record.state) {
      case "MINICEX_CREATED_PHYSICIAN_INVITE_SENT":
      case "MINICEX_CREATED_PHYSICIAN_REGISTERED": {
        //Calculate difference in days from now to expiration
        var timeDifferenceInDays = moment(record.expiresAt)
          .utc()
          .diff(moment().utc().toDate().getTime(), "days");

        var textWithInviteDate = obj[record.state].replace(
          "*InviteDate*",
          moment(record.createdAt).utc().format(PATHWAY6_DATE_FORMAT)
        );

        if (timeDifferenceInDays <= 0) {
          return textWithInviteDate.replace("*NumberOfDays*", "less than one");
        } else {
          return textWithInviteDate.replace(
            "*NumberOfDays*",
            timeDifferenceInDays
          );
        }
      }
      case "MINICEX_ACCEPTED": {
        var expiresAtInDays = getDaysUntilExpiration(record.expiresAt);

        if (expiresAtInDays <= 0) {
          return obj[record.state].replace("*NumberOfDays*", "less than one");
        } else {
          return obj[record.state].replace("*NumberOfDays*", expiresAtInDays);
        }
      }
      default: {
        return obj[record.state];
      }
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "encounterId",
      key: "encounterId",
      width: "auto",
    },
    {
      title: "Name from Applicant",
      key: "physicianNameProvidedByApplicant",
      width: "auto",
      render: (physicianNameProvidedByApplicant: any, record: any) => (
        <Text>
          {getPhysicianName(
            record?.physicianLastNameProvidedByApplicant,
            record?.physicianRestOfNameProvidedByApplicant
          )}
        </Text>
      ),
    },
    {
      title: "Name from Physician",
      key: "physicianNameProvidedByPhysician",
      width: "auto",
      render: (physicianNameProvidedByPhysician: any, record: any) => (
        <Text>
          {getPhysicianName(
            record?.physicianLastNameProvidedByPhysician,
            record?.physicianRestOfNameProvidedByPhysician
          )}
        </Text>
      ),
    },
    {
      title: "E-mail",
      dataIndex: "physicianEmail",
      key: "physicianEmail",
      width: "auto",
    },
    {
      title: "Date Requested",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "auto",
      render: (createdAt: any, record: any) => (
        <>
          {record.createdAt !== undefined ? (
            <Text style={{ whiteSpace: 'nowrap'}}>
              {moment(record.createdAt).utc().format(PATHWAY6_DATE_FORMAT)}
            </Text>
          ) : (
            <Text></Text>
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      width: "auto",
      render: (status: any, record: any) => (
        <>
          {record.state !== undefined ? (
            <Row wrap={false}>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <InfoCircleOutlined
                  style={{ fontSize: "18px" }}
                  onClick={() => showEncounterHistory(record)}
                />
              </Col>
              <Col offset={1}>
                <Text>{getStatus(CEX_STATUS, record.state)}</Text>
              </Col>
            </Row>
          ) : (
            <Text></Text>
          )}
        </>
      ),
    },
    {
      title: "Status Detail",
      dataIndex: "statusDetail",
      key: "statusDetail",
      width: "auto",
      render: (statusDetail: any, record: any) => (
        <>
          {record.state !== undefined ? (
            <Text>{getStatusDetail(CEX_STATUS_DETAIL, record)}</Text>
          ) : (
            <Text></Text>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "allowedActions",
      key: "allowedActions",
      render: (allowedActions: any, record: any, index: any) =>
        renderButtons(record, index),
    },
  ];

  const statusHistoryColumns = [
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (state: any, record: any) => (
        <>
          {record.state !== undefined ? (
            <Text>{getStatus(CEX_STATUS, record.state) + " "}</Text>
          ) : (
            <Text></Text>
          )}
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: any, record: any) => (
        <>
          {record.timestamp !== undefined ? (
            <Text>
              {moment(record.timestamp)
                .utc()
                .format(`${PATHWAY6_DATE_FORMAT}, h:mm:ss a`)}
            </Text>
          ) : (
            <Text></Text>
          )}
        </>
      ),
    },
  ];
  const showEncounterHistory = (record: any) => {
    setSelectedRecord(record);
    //Don't show pending invite statuses in status history
    var filteredStateModel = record.stateModel.filter(
      (item: { state: string }) =>
        item.state !== "MINICEX_CREATED_PHYSICIAN_INVITE_PENDING"
    );
    setStatusHistory(filteredStateModel);
    setShowEncounterHistoryModal(true);
  };

  return (
    <>
      <Table
        columns={
          caseDetails?.state === "ApplicationCompleted"
            ? columns.filter((item) => item.title !== "Action")
            : columns
        }
        dataSource={minicex as any}
        size="middle"
        loading={loading}
      ></Table>
      <PhysicianStatusHistoryModal
        selectedRecord={selectedRecord}
        setShowEncounterHistoryModal={setShowEncounterHistoryModal}
        showEncounterHistoryModal={showEncounterHistoryModal}
        statusHistory={statusHistory}
        statusHistoryColumns={statusHistoryColumns}
      />
      {/* <Modal
        title={"Status History"}
        visible={showEncounterHistoryModal}
        closable={false}
        footer={[
          <Button
            size="large"
            key="close"
            onClick={() => setShowEncounterHistoryModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <Paragraph>
          <Text>Physician:</Text>{" "}
          <Text strong>
            {getPhysicianName(
              selectedRecord?.physicianLastNameProvidedByApplicant,
              selectedRecord?.physicianRestOfNameProvidedByApplicant
            )}
          </Text>
          <br />
          <Text>Encounter ID:</Text>{" "}
          <Text strong>{selectedRecord?.encounterId}</Text>
          <br />
          <br />
          <Table
            columns={statusHistoryColumns}
            dataSource={statusHistory}
          ></Table>
          <Paragraph>
            All times are calculated using Coordinated Universal Time (UTC).
          </Paragraph>
        </Paragraph>
      </Modal> */}
    </>
  );
};
export default PhysicianEncounterListTable;
