import React, { FC, useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Radio,
  Form,
  Space,
  message,
  Collapse,
  Typography,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import PropTypes, { InferProps } from "prop-types";
import { UploadDocument } from "../upload-document";
import { useMutation } from "@apollo/client/react";
import _ from "lodash";
import { LICENSURE_DOCUMENT_STATUS } from "../constants";
import { IAuthority } from "../component-types/common-types";
import {
  ApplicantQualificationC2ApplicantPortal_Pathway1aDocument,
  ApplicantQualificationC2ApplicantPortal_Pathway1bDocument,
  License,
} from "../../generated";
const { Panel } = Collapse;
const { Title, Paragraph, Text, Link } = Typography;
type formResult = {
  _id: string;
  licensureDocumentStatus: string;
};

const documentTypes = {
  LicenseDocument: "LicenseDocument",
  TranslatedLicenseDocument: "TranslatedLicenseDocument",
  NotGoodStandingCertificate: "NotGoodStandingCertificate",
  TranslatedNotGoodStandingCertificate: "TranslatedNotGoodStandingCertificate",
};
var uploadDocuments: any = [{}, {}];

const AuthorityPropType = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    regulatoryAuthorityRef: PropTypes.shape({
      _id: PropTypes.number.isRequired,
      orgName: PropTypes.string.isRequired,
      countryName: PropTypes.string.isRequired,
    }).isRequired,
    licenseIssueDate: PropTypes.string.isRequired,
    licenseExpiryDate: PropTypes.string,
    isCurrentlyLicensed: PropTypes.bool.isRequired,
    hasDisciplinaryAction: PropTypes.bool.isRequired,
    licensureDocumentStatus: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        docId: PropTypes.string,
        docType: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }).isRequired,
});

const ComponentPropTypes = {
  screenName: PropTypes.string,
  isCurrentlyLicensed: PropTypes.bool,
  wasLicensedInRecentPast: PropTypes.bool,
  savedAuthorities: PropTypes.arrayOf(AuthorityPropType),
  currentID: PropTypes.number,
  onFormCancel: PropTypes.func,
  onFormSubmitSuccess: PropTypes.func,
  initialValues: PropTypes.shape({
    AuthorityPropType,
    certificationName: PropTypes.string,
    translatedCertificationName: PropTypes.string,
    notGoodStandingCertificationName: PropTypes.string,
    translatedNotGoodStandingCertificationName: PropTypes.string,
  }),
};

export interface IProps {
  __typename?: "QualificationC2";
  screenName?: string;
  isCurrentlyLicensed?: boolean;
  wasLicensedInRecentPast?: boolean;
  savedAuthorities?: {
    _id: string;
    data: IAuthority;
  }[];
  currentID?: number;
  onFormCancel?: () => void;
  onFormSubmitSuccess?: (formValues: any) => void;
  initialValues: {
    _id: string;
    data: IAuthority;

    certificationName?: string;
    translatedCertificationName?: string;
    notGoodStandingCertificationName?: string;
    translatedNotGoodStandingCertificationName?: string;
  };
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const QualificationC2: FC<ComponentProps> = ({
  screenName,
  isCurrentlyLicensed,
  wasLicensedInRecentPast,
  savedAuthorities,
  currentID,
  onFormCancel,
  onFormSubmitSuccess,
  initialValues,
}) => {
  var uploadedDocuments: any = initialValues?.data?.documents;
  const [licensureDocumentStatus, setLicensureDocumentStatus] = useState<
    string | undefined
  >(initialValues?.data?.licensureDocumentStatus ?? undefined);
  const [id, setId] = useState<string | undefined>(
    initialValues?._id ?? undefined
  );

  const [certificationName, setCertificationName] =
    useState<string | undefined>(undefined);
  const [translatedCertificationName, setTranslatedCertificationName] =
    useState<string | undefined>(undefined);
  const [
    notGoodStandingCertificationName,
    setNotGoodStandingCertificationName,
  ] = useState<string | undefined>(undefined);
  const [
    translatedNotGoodStandingCertificationName,
    setTranslatedNotGoodStandingCertificationName,
  ] = useState<string | undefined>(undefined);
  const [form] = Form.useForm();
  const [UpdateAuthorities1a] = useMutation(
    ApplicantQualificationC2ApplicantPortal_Pathway1aDocument
  );
  const [UpdateAuthorities1b] = useMutation(
    ApplicantQualificationC2ApplicantPortal_Pathway1bDocument
  );

  useEffect(() => {
    uploadDocuments = uploadedDocuments;
    //TODO clean up hardcoded hacks. Should be using docType to set values [0] [1] are no good
    form.setFieldsValue({
      licensureDocumentStatus: licensureDocumentStatus,
    });
    if (initialValues?.data?.licensureDocumentStatus) {
      if (
        initialValues?.data?.licensureDocumentStatus ===
        LICENSURE_DOCUMENT_STATUS.issuedInLast90Days
      ) {
        if (uploadedDocuments) {
          form.setFieldsValue({
            certificationName: uploadedDocuments[0].title,
          });
          setCertificationName(uploadedDocuments[0].title);
          if (uploadedDocuments[1]) {
            form.setFieldsValue({
              translatedCertificationName: uploadedDocuments[1].title,
            });
            setTranslatedCertificationName(uploadedDocuments[1].title);
          }
        }
      } else if (
        initialValues?.data?.licensureDocumentStatus ===
        LICENSURE_DOCUMENT_STATUS.doNotHaveCannotObtain
      ) {
        if (uploadedDocuments) {
          form.setFieldsValue({
            notGoodStandingCertificationName: uploadedDocuments[0].title,
          });
          setNotGoodStandingCertificationName(uploadedDocuments[0].title);
          if (uploadedDocuments[1]) {
            form.setFieldsValue({
              translatedNotGoodStandingCertificationName:
                uploadedDocuments[1].title,
            });
            setTranslatedNotGoodStandingCertificationName(
              uploadedDocuments[1].title
            );
          }
        }
      }
    }
  }, []);

  const onFinish = async () => {
    if (onFormSubmitSuccess) {
      //TODO: FIX THIS! This is a hack and a quickfix. Proper way is fixing the hacks throughout this page
      for (var i = 0; i < uploadDocuments.length; i++) {
        if (isEmpty(uploadDocuments[i])) {
          uploadDocuments.splice(i);
        }
      }
      onFormSubmitSuccess({
        _id: id,
        licensureDocumentStatus: licensureDocumentStatus,
        documents: uploadDocuments,
      } as formResult);
    }
  };

  function isEmpty(obj: object) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
  const onCancel = async () => {
    if (onFormCancel) {
      onFormCancel();
    }
  };

  //update the file names accordingly when one of 2 last options is selected
  const onSelectionChange = async (e: any) => {
    uploadDocuments = [{}, {}];
    setLicensureDocumentStatus(e.target.value);
    setCertificationName(undefined);
    setTranslatedCertificationName(undefined);
    setNotGoodStandingCertificationName(undefined);
    setTranslatedNotGoodStandingCertificationName(undefined);

    form.setFieldsValue({
      certificationName: undefined,
      translatedCertificationName: undefined,
      notGoodStandingCertificationName: undefined,
      translatedNotGoodStandingCertificationName: undefined,
    });
  };

  const getGuid = (
    applicantProvidedData: License[],
    beforeUpdateDocIds: string[]
  ) => {
    var guid: any;
    let curDocIds = [];
    if (applicantProvidedData) {
      for (const providedData of applicantProvidedData) {
        if (providedData?.data?.documents) {
          for (const doc of providedData.data.documents) {
            curDocIds.push(doc?.docId);
          }
        }
      }
      guid = _.find(curDocIds, (d: any) => {
        return !beforeUpdateDocIds.includes(d);
      });
    }

    return guid;
  };

  async function getBlobname(fileName: string, docType: string) {
    console.log("get blob name");

    if (
      docType === documentTypes.TranslatedLicenseDocument ||
      docType === documentTypes.TranslatedNotGoodStandingCertificate
    ) {
      if (_.isEmpty(uploadDocuments[0])) {
        message.error(
          "Please upload your certification first before uploading the translated one."
        );
        return;
      }
    }

    if (docType === documentTypes.LicenseDocument) {
      setCertificationName(fileName);
      form.setFieldsValue({ certificationName: fileName });
    } else if (docType === documentTypes.TranslatedLicenseDocument) {
      setTranslatedCertificationName(fileName);
      form.setFieldsValue({ translatedCertificationName: fileName });
    } else if (docType === documentTypes.NotGoodStandingCertificate) {
      setNotGoodStandingCertificationName(fileName);
      form.setFieldsValue({ notGoodStandingCertificationName: fileName });
    } else if (docType === documentTypes.TranslatedNotGoodStandingCertificate) {
      setTranslatedNotGoodStandingCertificationName(fileName);
      form.setFieldsValue({
        translatedNotGoodStandingCertificationName: fileName,
      });
    }
    const supportingData = JSON.parse(JSON.stringify(savedAuthorities));

    //get reference (changes on this object is reflected onto the array)
    const selectedAuth = supportingData.find(
      (authority: { _id: string }) => authority._id === currentID?.toString()
    );

    selectedAuth.data.licensureDocumentStatus = licensureDocumentStatus;

    const documentToUpload = {
      docType: docType,
      title: fileName,
    };

    if (
      docType === documentTypes.LicenseDocument ||
      docType === documentTypes.NotGoodStandingCertificate
    ) {
      var doc1 = JSON.parse(JSON.stringify(documentToUpload));
      uploadDocuments[0] = doc1;
    } else if (
      docType === documentTypes.TranslatedLicenseDocument ||
      docType === documentTypes.TranslatedNotGoodStandingCertificate
    ) {
      var doc2 = JSON.parse(JSON.stringify(documentToUpload));
      uploadDocuments[1] = doc2;
    }

    Object.assign(selectedAuth.data, { documents: uploadDocuments });

    let beforeUpdateDocIds: string[] = [];
    for (let i = 0; i < supportingData.length; i++) {
      for (let j = 0; j < supportingData[i].data.documents.length; j++) {
        beforeUpdateDocIds.push(supportingData[i].data.documents[j].docId);
      }
    }

    if (supportingData.length > 0) {
      supportingData.map((datum: any) => {
        delete datum._id;
        delete datum.__typename;
        delete datum.data.__typename;
        if (datum.data.regulatoryAuthorityRef) {
          delete datum.data.regulatoryAuthorityRef.__typename;
        }
        if (
          datum.data.licensureDocumentStatus ===
            LICENSURE_DOCUMENT_STATUS.requestedSentToECFMG ||
          datum.data.licensureDocumentStatus ===
            LICENSURE_DOCUMENT_STATUS.requestedWillSend
        ) {
          datum.data.documents = [];
        }
        // if (datum.data.documents && datum.data.documents.length > 0)
        else {
          if (_.isEmpty(datum.data.documents[1])) {
            datum.data.documents = [datum.data.documents[0]]; //remove the translated document element if it is not provided
          }
          datum.data.documents.map((doc: any) => {
            delete doc.__typename;
          });
        }
      });
    }

    if (screenName === "Pathway-1a") {
      return UpdateAuthorities1a({
        variables: {
          // screenName: screenName,
          isCurrentlyLicensed: isCurrentlyLicensed!,
          supportingData: supportingData,
        },
      })
        .then((data) => {
          var guid = getGuid(
            data.data?.ApplicantPortal_pathway1a?.data?.pathway1
              ?.applicantProvidedData as License[],
            beforeUpdateDocIds
          );

          if (
            docType === documentTypes.LicenseDocument ||
            docType === documentTypes.NotGoodStandingCertificate
          ) {
            uploadDocuments[0].docId = guid;
          } else if (
            docType === documentTypes.TranslatedLicenseDocument ||
            docType === documentTypes.TranslatedNotGoodStandingCertificate
          ) {
            uploadDocuments[1].docId = guid;
          }

          return guid;
        })
        .catch(() => {
          return "error";
        });
    } else {
      return UpdateAuthorities1b({
        variables: {
          // screenName: screenName,
          wasLicensedInRecentPast: wasLicensedInRecentPast!,
          supportingData: supportingData,
        },
      })
        .then((data) => {
          var guid = getGuid(
            data.data?.ApplicantPortal_pathway1b?.data?.pathway1
              ?.applicantProvidedData as License[],
            beforeUpdateDocIds
          );

          if (
            docType === documentTypes.LicenseDocument ||
            docType === documentTypes.NotGoodStandingCertificate
          ) {
            uploadDocuments[0].docId = guid;
          } else if (
            docType === documentTypes.TranslatedLicenseDocument ||
            docType === documentTypes.TranslatedNotGoodStandingCertificate
          ) {
            uploadDocuments[1].docId = guid;
          }

          return guid;
        })
        .catch((error) => {
          message.error(error.toString());
          return "error";
        });
    }
  }

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Form.Item style={{ display: "none" }}>
          <Input name="id" value={id} />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Title level={4} style={{ fontWeight: 600 }}>
              Licensure Documents
            </Title>
            <Collapse ghost>
              <Panel
                header={
                  <Space>
                    <InfoCircleOutlined /> Instructions
                  </Space>
                }
                key="instructions"
              >
                <Paragraph
                  style={{
                    textAlign: "initial",
                    marginLeft: "20px",
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 0.8)",
                  }}
                >
                  <Paragraph>
                    As demonstration of your licensure/registration to practice
                    medicine, ECFMG requires appropriate documentation from the
                    authority that issued your license/registration. A
                    Certificate or Letter of Good Standing/Current Professional
                    Status, or equivalent document, submitted directly to ECFMG
                    by the authority that issued it will meet this requirement.
                    The document must be issued by the authority within 90 days
                    of the date on which you submit your Pathways application.
                  </Paragraph>
                  <Paragraph>
                    If the Certificate or Letter of Good Standing/Current
                    Professional Status is not sent to ECFMG directly from the
                    authority that issued it, you may submit the document
                    yourself. If you submit the document, it must have been
                    issued to you within the last 90 days. ECFMG will
                    primary-source verify this document with the issuing
                    authority.
                  </Paragraph>
                  <Paragraph>
                    If you do not have, or are unable to obtain, a Certificate
                    or Letter of Good Standing/Current Professional Status, you
                    may submit a copy of your license/registration to practice
                    medicine. ECFMG will primary-source verify this document
                    with the issuing authority and request additional
                    information about any history of disciplinary action. Please
                    note that while ECFMG will make every effort to verify your
                    documentation, there is no guarantee that the verification
                    will be completed, as certain authorities may have an
                    established process for issuing a Certificate or Letter of
                    Good Standing and may not respond to ECFMG’s verification
                    requests. You are strongly urged to refer to your regulatory
                    authority's process for reporting on good
                    standing/professional status.
                  </Paragraph>
                  <Paragraph>
                    Before indicating below how the documentation will be
                    submitted, you must review the{" "}
                    <Link
                      href={
                        "https://www.ecfmg.org/certification-pathways/pathway-1.html#info"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      Important Information on Documentation Submitted in
                      Support of Your Application
                    </Link>{" "}
                    on the{" "}
                    <Link
                      href={
                        "https://www.ecfmg.org/certification-pathways/pathway-1.html"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pathway 1
                    </Link>{" "}
                    page of the ECFMG website.
                  </Paragraph>
                </Paragraph>
              </Panel>
            </Collapse>
            <Paragraph>
              Click the appropriate box below to indicate how you will provide
              documentation of this license/registration to ECFMG:
            </Paragraph>
            <Form.Item
              name="licensureDocumentStatus"
              rules={[
                {
                  required: true,
                  message: "Please select the approprate answer",
                },
              ]}
              initialValue={licensureDocumentStatus}
            >
              <Radio.Group
                onChange={(e) => onSelectionChange(e)}
                defaultValue={licensureDocumentStatus}
              >
                <Radio
                  id="requested-sent-to-ECFMG-option"
                  value={LICENSURE_DOCUMENT_STATUS.requestedSentToECFMG}
                >
                  <Paragraph
                    style={{ whiteSpace: "normal", display: "inline" }}
                  >
                    <Text strong>
                      Authority will send Certificate of Good Standing
                    </Text>
                    <Paragraph style={{ paddingLeft: "25px" }}>
                      <Paragraph>
                        I am requesting, or have requested, that the authority
                        send a Certificate or Letter of Good Standing/Current
                        Professional Status directly to ECFMG.
                      </Paragraph>
                    </Paragraph>
                  </Paragraph>
                </Radio>

                <Radio
                  id="issued-in-last-90-days-option"
                  value={LICENSURE_DOCUMENT_STATUS.issuedInLast90Days}
                >
                  <Paragraph
                    style={{ whiteSpace: "normal", display: "inline" }}
                  >
                    <Text strong>Upload Certificate of Good Standing</Text>
                    <Paragraph style={{ paddingLeft: "25px" }}>
                      <Paragraph>
                        I have a Certificate or Letter of Good Standing/Current
                        Professional Status issued within the last 90 days and
                        am uploading it here.
                      </Paragraph>
                      {licensureDocumentStatus ===
                        LICENSURE_DOCUMENT_STATUS.issuedInLast90Days && (
                        <>
                          <Paragraph>
                            <em>
                              If Certificate or Letter of Good Standing is not
                              in English, please attach an English translation
                              of the document.
                            </em>
                          </Paragraph>
                          <Row>
                            <Col
                              xs={{ span: 23, offset: 1 }}
                              lg={{ span: 11, offset: 1 }}
                            >
                              <Text strong>
                                Certificate or Letter of Good Standing
                              </Text>
                              <Form.Item
                                name="certificationName"
                                valuePropName="uploadedFileName"
                                rules={[
                                  {
                                    validator(_, __) {
                                      console.log(certificationName);
                                      if (certificationName) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        "Please upload your license/certification"
                                      );
                                    },
                                  },
                                ]}
                                //initialValue={certificationName}
                              >
                                <UploadDocument
                                  uploadId="in-last-90-days-document-upload"
                                  label="Select File To Upload"
                                  uploadedFileName={certificationName}
                                  getGuid={(filename: string) =>
                                    getBlobname(
                                      filename,
                                      documentTypes.LicenseDocument
                                    )
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 23, offset: 1 }} lg={{ span: 12 }}>
                              <Text strong>
                                English Translation (If Needed)
                              </Text>
                              <Form.Item
                                name="translatedCertificationName"
                                valuePropName="uploadedFileName"
                              >
                                <UploadDocument
                                  uploadId="in-last-90-days-translation-upload"
                                  label="Select File To Upload"
                                  uploadedFileName={translatedCertificationName}
                                  getGuid={(filename: string) =>
                                    getBlobname(
                                      filename,
                                      documentTypes.TranslatedLicenseDocument
                                    )
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Paragraph>
                  </Paragraph>
                </Radio>

                <Radio
                  id="do-not-have-can-not-obtain-option"
                  value={LICENSURE_DOCUMENT_STATUS.doNotHaveCannotObtain}
                >
                  <Paragraph
                    style={{ whiteSpace: "normal", display: "inline" }}
                  >
                    <Text strong>
                      Cannot Provide Certificate of Good Standing
                    </Text>
                    <Paragraph style={{ paddingLeft: "25px" }}>
                      <Paragraph>
                        I do not have a Certificate or Letter of Good
                        Standing/Current Professional Status and am unable to
                        obtain one. I am uploading my license/registration here.
                      </Paragraph>
                    </Paragraph>
                    {licensureDocumentStatus ===
                      LICENSURE_DOCUMENT_STATUS.doNotHaveCannotObtain && (
                      <>
                        <Paragraph>
                          <em>
                            If License/Registration is not in English, please
                            attach an English translation of the document.
                          </em>
                        </Paragraph>
                        <Row>
                          <Col
                            xs={{ span: 23, offset: 1 }}
                            lg={{ span: 11, offset: 1 }}
                          >
                            <Text strong>License/Registration</Text>
                            <Form.Item
                              name="notGoodStandingCertificationName"
                              valuePropName="uploadedFileName"
                              rules={[
                                {
                                  validator(_, __) {
                                    console.log(
                                      notGoodStandingCertificationName
                                    );
                                    if (notGoodStandingCertificationName) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      "Please upload your license/certification"
                                    );
                                  },
                                },
                              ]}
                            >
                              <UploadDocument
                                uploadId="not-good-standing-certification-upload"
                                label="Select File To Upload"
                                uploadedFileName={
                                  notGoodStandingCertificationName
                                }
                                getGuid={(filename: string) =>
                                  getBlobname(
                                    filename,
                                    documentTypes.NotGoodStandingCertificate
                                  )
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 23, offset: 1 }} lg={{ span: 12 }}>
                            <Text strong>English Translation (If Needed)</Text>
                            <Form.Item
                              name="translatedNotGoodStandingCertificationName"
                              valuePropName="uploadedFileName"
                            >
                              <UploadDocument
                                uploadId="not-good-standing-certification-translation-upload"
                                label="Select File To Upload"
                                uploadedFileName={
                                  translatedNotGoodStandingCertificationName
                                }
                                getGuid={(filename: string) =>
                                  getBlobname(
                                    filename,
                                    documentTypes.TranslatedNotGoodStandingCertificate
                                  )
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Paragraph>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space direction="horizontal">
              <Button
                size="large"
                id="qc2-cancel-button"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                id="qc2-continue-button"
                type="primary"
                htmlType="submit"
              >
                Continue
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

QualificationC2.defaultProps = {
  isCurrentlyLicensed: undefined,
  wasLicensedInRecentPast: undefined,
  savedAuthorities: undefined,
  currentID: undefined,
  onFormCancel: () => {},
  onFormSubmitSuccess: () => {},
};
