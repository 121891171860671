import { UserOutlined } from "@ant-design/icons";
import { Button, Col, Result, Row, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { InferProps } from "prop-types";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { PastAttemptsType } from "../generated";
import RejectionHistory from "./rejection-history";
const { Title, Paragraph, Text, Link } = Typography;
const ComponentPropTypes = {};

export interface IProps {
  __typename?: "VerificationEligible";

  pastAttempts?: PastAttemptsType[];
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const VerificationEligible: FC<ComponentProps> = ({ pastAttempts }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const continueQualification = async () => {
    navigate(`/QualificationA`);
  };

  return (
    <>
      <Helmet>
        <title>Verification Eligible</title>
      </Helmet>
      <Result
        status="error"
        icon={<UserOutlined style={{ color: "#61C8D7" }} />}
        title="You May Be Eligible"
        subTitle={
          <>
            <Paragraph
              style={{
                margin: "auto",
                fontSize: "14px",
                color: "rgba(0, 0, 0, 1)",
                width: screens.lg ? "50%" : "",
              }}
            >
              <RejectionHistory pastAttempts={pastAttempts} />
              <br />
              Please answer a series of questions to help determine your
              eligibility to pursue a 2025 Pathway.{" "}
            </Paragraph>
          </>
        }
        extra={[
          <Button
            size="large"
            type="primary"
            onClick={() => continueQualification()}
          >
            Continue
          </Button>,
        ]}
      />
      <Row>
        <Col span={24}>
          <Paragraph
            style={{
              textAlign: "initial",
              margin: "auto",
              fontSize: "14px",
              color: "rgba(0, 0, 0, 1)",
              width: screens.lg ? "45%" : "100%",
            }}
          >
            <Paragraph>
              ECFMG has determined that you currently meet the general
              eligibility requirements to pursue a Pathway. Please continue with
              the application. Please note that if your eligibility changes
              after you submit this application, your application will be
              rejected, and you will not receive a refund.
            </Paragraph>
            <Paragraph>
              To determine your eligibility to apply to one of the Pathways,
              please answer the questions that follow and provide all requested
              information. Please be prepared to upload any required supporting
              documentation, including an acceptable English translation of any
              document not in English, as described in the{" "}
              <Link
                href={"https://www.ecfmg.org/certification-pathways/"}
                target="_blank"
                rel="noreferrer"
              >
                Requirements for 2025 Pathways for ECFMG Certification.
              </Link>
            </Paragraph>
            <Paragraph>
              Please ensure that all the information you provide is complete and
              correct. Failure to provide complete and correct information can
              result in rejection of your application and/or a finding of{" "}
              <Link
                href={"https://www.ecfmg.org/resources/irregular-behavior.html"}
                target="_blank"
                rel="noreferrer"
              >
                irregular behavior.
              </Link>
            </Paragraph>
          </Paragraph>
        </Col>
      </Row>
    </>
  );
};

export default {
  Component: VerificationEligible,
};
