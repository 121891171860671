import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { ApolloClient } from "@apollo/client";
import { BrowserRouter, useRoutes } from "react-router-dom";
import AuthProvider from "./components/auth/auth-provider";
import Index2 from "./index2";
//import {FeatureFlagProvider} from "./util/FeatureFlags/featureFlags";
import FeatureFlagProvider from "./components/feature-flag-react-lite";
import featureFlagConfig from "./config/feature-flag-config";
import GA4React from "ga-4-react";
import { CachePurgeProvider } from "./contexts/components/CachePurgeContext";

const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID as string;
const ga4react = new GA4React(gaMeasurementId, {
  debug_mode: process.env.NODE_ENV !== "production",
});

(async () => {
  try {
    await ga4react.initialize();
  } catch (error) {
    console.error(error);
  }

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <FeatureFlagProvider config={featureFlagConfig}>
            <CachePurgeProvider>
              <Index2 />
            </CachePurgeProvider>
          </FeatureFlagProvider>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
