import React, { FC, useState } from "react";
import { Row, Col, Form, Button, Checkbox, Typography } from "antd";
import PropTypes, { InferProps } from "prop-types";
const { Text } = Typography;
const ComponentPropTypes = {
  onFormCancel: PropTypes.func.isRequired,
  deleteEncounter: PropTypes.func.isRequired,
  removeEncounterLoading: PropTypes.bool.isRequired,
};

export interface IProps {
  __typename?: "RemoveCEXEncounter";
  onFormCancel: () => void;
  deleteEncounter: () => void;
  removeEncounterLoading: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const RemoveCEXEncounter: FC<ComponentProps> = ({
  onFormCancel,
  deleteEncounter,
  removeEncounterLoading,
}) => {
  const [confirmCheckboxChecked, setConfirmCheckboxChecked] =
    useState<boolean>(false);

  const onCancel = () => {
    setConfirmCheckboxChecked(false);
    onFormCancel();
  };

  const onFinish = () => {
    setConfirmCheckboxChecked(false);
    deleteEncounter();
  };

  return (
    <Form onFinish={() => onFinish()}>
      <Row>
        <Col>
          <Text strong>
            You must confirm that the clinical encounter did not take place in
            order to remove this encounter.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item
            name="confirmCheckbox"
            rules={[
              {
                required: !confirmCheckboxChecked,
                message:
                  "You must confirm that the encounter did not take place in order to remove this encounter.",
              },
            ]}
          >
            <Checkbox
              checked={confirmCheckboxChecked}
              onChange={(e: any) => setConfirmCheckboxChecked(e.target.checked)}
            >
              This encounter did not take place.
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button size='large' style={{ margin: 3 }} onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            type="primary"
            size='large'
            danger
            style={{
              margin: 3,
            }}
            htmlType="submit"
            loading={removeEncounterLoading}
          >
            Remove
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
