type applicantPortal = {
    pathway1: {
      userResponse: { 
        isCurrentlyLicensed: string,
        wasLicensedInRecentPast: string
      }
    },
    pathway2: {
      userResponse: {
        hasPassedCSExamInRecentPast: string
      },
      applicantProvidedData: [{
        data: {
          schoolRef: {
            name: string
          }
        }
      }]
    },
    pathway345: {
      applicantProvidedData: [{
        data: {
          schoolRef: {
            name: string
          }
        }
      }]
    },
    graduation: {
      isYearEligible: string
    }
  
  }
  
  export function pageNavigation(screenName: string, applicantPortalData: applicantPortal) {
    switch (screenName) {
      case "Pathway-1a":
        //if isCurrently licensed is true
        //then user has submitted for pathway1
        //take them to language assessment
        if (applicantPortalData?.pathway1?.userResponse?.isCurrentlyLicensed) {
          return "/Qualification-review";
        }
        //if it is false, user submitted data and left off at 1b
        else {
          return "/QualificationB";
        }
      case "Pathway-1b":
        //if wasLicensedInRecentPast licensed is true
        //then user has submitted for pathway1
        //take them to language assessment
        if (
          applicantPortalData?.pathway1?.userResponse?.wasLicensedInRecentPast
        ) {
          return "/Qualification-review";
        }
        //if it is false, user submitted data and left off at pathway 2
        else {
          return "/QualificationE";
        }
      // case "Pathway-2a":
      //   //if any of these two items are true, user has submitted for either one - redirect to language assessment
      //   if (
      //     applicantPortalData?.pathway2?.userResponse?.hasPassedCSExamInRecentPast
      //   ) {
      //     return "/qualification-review";
      //   }
      //   //otherwise, take them to pathway345
      //   else {
      //     return "/QualificationE";
      //   }
      case "Pathway-2b":
        if (
          applicantPortalData?.pathway2?.applicantProvidedData?.[0]?.data
            ?.schoolRef?.name
        ) {
          return "/Qualification-review";
        } else if (!applicantPortalData?.graduation?.isYearEligible) {
          return "/Qualification6";
        } else {
          return "/QualificationF";
        }
      case "Pathway-345":
        if (
          applicantPortalData?.pathway345?.applicantProvidedData?.[0]?.data
            ?.schoolRef?.name
        ) {
          return "/Qualification-review";
        } else {
          return "/Qualification6";
        }
      case "Pathway-6":
        return "/Qualification-review";
  
      case "ExceptionRequest":
        return "/QualificationI";
      default:
        return "";
    }
  }
  