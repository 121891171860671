import React, { useState, useEffect } from "react";
import { Alert } from "antd";
import { AUTH_TOKEN } from "./constants";
import * as jose from 'jose';
import moment from "moment";

const SECONDS_TO_DISPLAY = 300;

const SessionAlert: any = (props: any) => {
  const [timeLeft, setTimeLeft] = useState<number | undefined>(undefined);
  const [timeInterval, setTimeInterval] = useState(30000);
  var authToken = sessionStorage.getItem(AUTH_TOKEN);

  useEffect(() => {
    if (timeLeft !== 0) {
      setTimeLeft(moment.unix(tokenExpiration).diff(new Date(), "seconds"));

      if (timeInterval !== 1000 && timeLeft && timeLeft <= SECONDS_TO_DISPLAY) {
        //update every second when time left is 5 mins or less
        setTimeInterval(1000);
      }
    }

  }, [timeLeft]);
  var timeFunction = setTimeout(function () {
    setTimeLeft(moment.unix(tokenExpiration).diff(new Date(), "seconds"));
  }, timeInterval);

  const sessionTimeout = () => {
    clearTimeout(timeFunction);
    setTimeLeft(0);
  }

  if (authToken) {
    const decodedJWT: any = jose.decodeJwt(authToken);
    var tokenExpiration = parseInt(decodedJWT?.exp?.toString());

    if (timeLeft && timeLeft <= SECONDS_TO_DISPLAY) {
      if(timeLeft <= 0){
        sessionTimeout();
      }
      var minutes = Math.floor(timeLeft / 60);
      var seconds: any = timeLeft % 60;
      if(seconds.toString().length < 2){
        seconds = `0${seconds}`;
      }
      return <Alert type="warning" message={`Your session will expire in ${minutes}:${seconds}. Log in again to refresh the session.`} />;
    }
  }

  return <></>;
};
export default SessionAlert;
