import React, { FC } from "react";
import { Row, Col, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
const { Title, Paragraph } = Typography;

const Qualification6AddEncounters: FC<any> = () => {
  const navigate = useNavigate();

  const onContinue = async () => {
    navigate(`/Qualification-review`);
  };

  return (
    <>
      <Title level={2}>Clinical Encounters</Title>
      <Paragraph>List of encounters goes here</Paragraph>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button
            id="q6-continue-button"
            size='large'
            type="primary"
            onClick={() => {
              onContinue();
            }}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default {
  Component: Qualification6AddEncounters,
};
