export //Pathway case status lookup values
const PATHWAY_ELIGIBILITY_STATUS = [
  //pathway 1
  {
    key: "1-license-review-in-process",
    value: "Application review in process",
  },
  {
    key: "1-awaiting-applicant-resolution-license",
    value: "Waiting for applicant action on license/registration",
  },
  {
    key: "1-awaiting-applicant-resolution-translation",
    value: "Waiting for applicant action on English Translation",
  },
  {
    key: "1-exception-review-escalation-required",
    value: "Application review in process",
  },
  {
    key: "1-awaiting-licensure-document-from-issuing-authority",
    value: "Waiting for licensure/registration document from authority",
  },
  {
    key: "1-awaiting-licensure-document-from-applicant",
    value: "Waiting for licensure/registration document from applicant",
  },
  {
    key: "1-sent-for-verification",
    value: "License/registration sent for verification",
  },
  {
    key: "1-resent-for-verification",
    value: "License/registration resent for verification",
  },
  {
    key: "1-verification-received-in-review",
    value: "License/registration verification received; in review",
  },
  {
    key: "1-verification-rejected-and-resent",
    value: "License/registration verification rejected and request resent",
  },
  {
    key: "1-verification-review-complete",
    value: "License/registration verification review complete",
  },
  //pathway 2
  // { key: "2-osce-review-in-process", value: "Application review in process" },
  // {
  //   key: "2-awaiting-applicant-resolution-osce",
  //   value: "Waiting for applicant action ",
  // },
  {
    key: "2-exception-review-escalation-reqired",
    value: "Application review in process",
  },
  {
    key: "2-awaiting-attestation-document",
    value: "Waiting for attestation form",
  },
  {
    key: "2-sent-for-verification",
    value: "Sent for verification",
  },
  {
    key: "2-resent-for-verification",
    value: "Resent for verification",
  },
  {
    key: "2-verification-received-in-review",
    value: "Verification received; in review",
  },
  {
    key: "2-verification-rejected-and-resent",
    value: "Verification rejected and request resent",
  },
  {
    key: "2-verification-review-complete",
    value: "Verification review complete",
  },
  {
    key: "2-attestation-received-in-review",
    value: "Attestation form received; in review",
  },
  {
    key: "2-attestation-rejected-and-resent",
    value: "Attestation form rejected and request resent",
  },
  {
    key: "2-attestation-review-complete",
    value: "Attestation form review complete",
  },
  //pathway3
  {
    key: "3-license-eligibility-review-in-process",
    value: "Application review in process",
  },
  {
    key: "3-license-awaiting-applicant-resolution",
    value: "Waiting for applicant action",
  },
  {
    key: "3-license-exception-review-escalation-reqired",
    value: "Application review in process",
  },
  {
    key: "3-license-attestation-request-sent-to-school",
    value: "Attestation request sent to medical school",
  },
  {
    key: "3-license-attestation-rejected-by-school",
    value: "Application review in process",
  },
  {
    key: "3-license-awaiting-attestation-document",
    value: "Waiting for attestation form",
  },
  {
    key: "3-license-attestation-received-in-review",
    value: "Attestation form received; in review",
  },
  {
    key: "3-license-attestation-rejected-and-resent",
    value: "Attestation form rejected and request resent",
  },
  {
    key: "3-license-attestation-review-complete",
    value: "Attestation review complete",
  },
  //pathway 4
  {
    key: "4-license-eligibility-review-in-process",
    value: "Application review in process",
  },
  {
    key: "4-license-awaiting-applicant-resolution",
    value: "Waiting for applicant action",
  },
  {
    key: "4-license-exception-review-escalation-reqired",
    value: "Application review in process",
  },
  {
    key: "4-license-attestation-request-sent-to-school",
    value: "Attestation request sent to medical school",
  },
  {
    key: "4-license-attestation-rejected-by-school",
    value: "Application review in process",
  },
  {
    key: "4-license-awaiting-attestation-document",
    value: "Waiting for attestation form",
  },
  {
    key: "4-license-attestation-received-in-review",
    value: "Attestation form received; in review",
  },
  {
    key: "4-license-attestation-rejected-and-resent",
    value: "Attestation form rejected and request resent",
  },
  {
    key: "4-license-attestation-review-complete",
    value: "Attestation review complete",
  },
  //pathway 5
  {
    key: "5-license-eligibility-review-in-process",
    value: "Application review in process",
  },
  {
    key: "5-license-awaiting-applicant-resolution",
    value: "Waiting for applicant action",
  },
  {
    key: "5-license-exception-review-escalation-reqired",
    value: "Application review in process",
  },
  {
    key: "5-license-attestation-request-sent-to-school",
    value: "Attestation request sent to medical school",
  },
  {
    key: "5-license-attestation-rejected-by-school",
    value: "Application review in process",
  },
  {
    key: "5-license-awaiting-attestation-document",
    value: "Waiting for attestation form",
  },
  {
    key: "5-license-attestation-received-in-review",
    value: "Attestation form received; in review",
  },
  {
    key: "5-license-attestation-rejected-and-resent",
    value: "Attestation form rejected and request resent",
  },
  {
    key: "5-license-attestation-review-complete",
    value: "Attestation review complete",
  },
  //Pathway 6
  { key: "6-pending", value: "Waiting on completion of all Mini-CEX" },
  {
    key: "6-minicex-review-complete",
    value: "All Mini-CEX received and review complete",
  },
  {
    key: "6-committee-review-required",
    value: "All Mini-CEX received; review in progress",
  },
  {
    key: "6-committee-review-in-progress",
    value: "All Mini-CEX received; review in progress",
  },
  {
    key: "6-committee-review-complete-in-appeal-period",
    value:
      "All Mini-CEX received and review complete; awaiting applicant action",
  },
  {
    key: "6-appeal-requested",
    value: "All Mini-CEX received; review in progress",
  },
  {
    key: "6-appeal-in-progress",
    value: "All Mini-CEX received; review in progress",
  },
  //Pathway X
  {
    key: "X-exception-exception-request-in-process",
    value: "Exception request review in process",
  },
  {
    key: "X-awaiting-applicant-resolution",
    value: "Waiting for applicant action",
  },
  {
    key: "X-exception-request-under-review-with-operations",
    value: "Exception request review in process",
  },
  {
    key: "X-exception-request-under-review-with-enterprise",
    value: "Exception request review in process",
  },
  {
    key: "X-exception-review-complete",
    value: "Exception request review complete",
  },
  {
    key: "X-pathway-exception-request-in-process",
    value: "Exception request review in process",
  },
  {
    key: "X-pathway-awaiting-applicant-resolution",
    value: "Waiting for applicant action",
  },
  {
    key: "X-pathway-awaiting-verification-licensure-document",
    value: "Waiting for verification of licensure document",
  },
  // {
  //   key: "X-pathway-awaiting-verification-osce-document",
  //   value: "Exam documentation sent for verification",
  // },
  {
    key: "X-pathway-awaiting-attestation-pathway-2",
    value: "Waiting for attestation form",
  },
  {
    key: "X-pathway-awaiting-attestation-pathway-345",
    value: "Waiting for attestation form",
  },
  {
    key: "X-pathway-document-received-in-review",
    value: "Pathway documentation received; in review",
  },
  {
    key: "X-pathway-verification-rejected-and-resent",
    value: "Pathway documentation rejected and request resent",
  },
  {
    key: "X-pathway-verification-review-complete",
    value: "Pathway documentation review complete",
  },
  //OET
  { key: "not-started", value: "Not Started" },
  { key: "approved", value: "Approved" },
  { key: "rejected", value: "Rejected" },
  { key: "score-not-yet-received", value: "Score not yet received" },
  { key: "score-received", value: "Score Received from OET" },
  { key: "awaiting-applicant-feedback", value: "Awaiting applicant feedback" },
  { key: "score-review-in-process", value: "Score Review in process" },
  {
    key: "exception-review-escalation-reqired",
    value: "Application review in process",
  },
  { key: "score-review-complete", value: "Score Review complete" },
  //General Eligibility
  {
    key: "Pending - STEP 1 exam not passed",
    value: "Pending Step 1 Passing Performance",
  },
  {
    key: "Pending - STEP 2 CK exam not passed",
    value: "Pending Step 2 CK Passing Performance",
  },
  {
    key: "Pending - STEP 1 and STEP 2 CK exams not passed",
    value: "Pending Step 1 and Step 2 CK Passing Performance",
  },
  {
    key: "Pending - null",
    value: "Pending",
  },
  {
    key: "Pending - undefined",
    value: "Pending",
  },
];
