import React, { FC } from "react";
import moment from "moment";
import PropTypes, { InferProps } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Divider, Space, Button, Typography } from "antd";
import { DATE_FORMAT } from "../constants";
import { A_CaseDetails, Pathway345 } from "../../generated";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  onEditEducationCallback: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    userResponse: PropTypes.shape({
      isGradSchoolEligible: PropTypes.bool,
      isGradDateInAcceptableRange: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        data: PropTypes.shape({
          schoolRef: PropTypes.shape({
            _id: PropTypes.number,
            name: PropTypes.string,
            countryName: PropTypes.string,
          }),
          gradDate: PropTypes.string,
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
  caseDetails: PropTypes.shape({
    currentPathway: PropTypes.string,
  }),
};

export interface IProps {
  __typename?: "ReviewEducationInformation";
  onEditEducationCallback: () => void;
  initialValues?: Pathway345;
  caseDetails?: A_CaseDetails;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;
//Q-F
const ReviewEducationInformation: FC<ComponentProps> = ({
  onEditEducationCallback,
  initialValues,
  caseDetails,
}) => {
  const isGradSchoolEligible =
    initialValues?.userResponse?.isGradSchoolEligible;
  var applicantProvidedData = undefined;
  if (
    initialValues?.applicantProvidedData &&
    initialValues.applicantProvidedData.length > 0
  ) {
    applicantProvidedData = initialValues.applicantProvidedData[0]; //onlye one is submitted by user
  }
  var gradDate = applicantProvidedData?.data?.gradDate ?? "";
  gradDate = gradDate ? moment(gradDate).format(DATE_FORMAT) : "";

  const convertPathwayNumber = (pathway: any) => {
    switch (pathway) {
      case "PATHWAY3":
        return "3";
      case "PATHWAY4":
        return "4";
      case "PATHWAY5":
        return "5";
      default:
        return "";
    }
  };
  return (
    <>
      {initialValues?.userResponse ? (
        <Paragraph>
          <Divider plain orientation="left">
            <Space size="middle">
              <Text strong>Pathway 3, 4, or 5</Text>
              <Button onClick={onEditEducationCallback}>
                Edit
              </Button>
            </Space>
          </Divider>

          {isGradSchoolEligible ? (
            <Paragraph>
              My medical school is eligible for Pathway{" "}
              {convertPathwayNumber(caseDetails?.currentPathway)}, and my
              graduation date is on or after January 1, 2023.
              <br />
              <br />
            </Paragraph>
          ) : (
            <Paragraph></Paragraph>
          )}
        </Paragraph>
      ) : (
        <Paragraph></Paragraph>
      )}
    </>
  );
};

export default {
  Component: ReviewEducationInformation,
};
