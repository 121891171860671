import React, { FC, useState } from "react";
import {
  Descriptions,
  Card,
  Row,
  Col,
  Tooltip,
  Button,
  Space,
  Modal,
  Popconfirm,
  Typography,
} from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import PropTypes, { InferProps } from "prop-types";
import ViewPDF from "./ViewPDF";

const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  onDelete: PropTypes.func,
  displayValues: PropTypes.shape({
    docId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    docType: PropTypes.string.isRequired,
  }).isRequired,
};

export interface IProps {
  __typename?: "AdditionalDocumentCard";
  onDelete?: () => void;
  displayValues: {
    docId: string;
    title: string;
    docType: string;
  };
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const AdditionalDocumentCard: FC<ComponentProps> = ({
  onDelete,
  displayValues,
}) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);
  return (
    <Paragraph className="space-align-block">
      <Card style={{ height: "132", width: "132" }}>
        <Descriptions title="" size="small" layout="vertical">
          <Descriptions.Item>
            <Tooltip title={displayValues.title}>
              <Paragraph strong ellipsis={{ rows: 2 }}>
                {displayValues.title}
              </Paragraph>
            </Tooltip>
          </Descriptions.Item>
        </Descriptions>
        <Row>
          <Col span="24" style={{ textAlign: "right" }}>
            <Space>
              <Tooltip title="Preview">
                <Button
                  id={`${displayValues.docId}_preview-button`}
                  shape="circle"
                  icon={<SearchOutlined />}
                  onClick={() => setShowPreviewPdfModal(true)}
                />
              </Tooltip>

              <Popconfirm
                title="Are you sure you want to delete this?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDelete && onDelete()}
              >
                <Tooltip title="Delete">
                  <Button
                    id="delete-button"
                    shape="circle"
                    danger
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </Space>
          </Col>
        </Row>
      </Card>

      <Modal
        title={displayValues.title}
        visible={showPreviewPdfModal}
        width="80vw"
        okText="Continue"
        footer={null}
        onCancel={() => setShowPreviewPdfModal(false)}
      >
        {showPreviewPdfModal ? (
          <ViewPDF docId={displayValues.docId}></ViewPDF>
        ) : (
          ""
        )}
      </Modal>
    </Paragraph>
  );
};

AdditionalDocumentCard.defaultProps = {
  onDelete: () => {},
};
