import React, { useState } from "react";
//import * as FileType from "file-type";
import { Button, Upload, Typography } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import axios from "axios";
import { ApplicantDocumentUploaderUploadDocumentDocument } from "../generated";
const { Title, Paragraph, Text } = Typography;

const { Dragger } = Upload;

function DocumentUploader() {
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadDocument] = useMutation(ApplicantDocumentUploaderUploadDocumentDocument);

  const DraggerProps = {
    beforeUpload(file: any, fileList: any) {
      console.log("BEFORE UPLOAD");
      setFileName(file.name);
      setFileData(file);
      return false;
    },
    accept: ".pdf",
  };

  async function handleUpload() {
    const results = await uploadDocument({
      variables: {
        fileName: fileName,
      },
    });
    const sasURI = results.data?.uploadDocument?.token?.uri!;
    console.log(sasURI);
    axios
      .put(sasURI, new Blob([fileData], { type: "application/pdf" }), {
        headers: {
          "x-ms-blob-type": "BlockBlob",
        },
      })
      .then(
        (res) => {
          console.log(res);
          if (res.status === 201) {
            alert("Upload successfully!");
          } else {
            alert("There are problems with the uploading.");
          }
        },
        (err) => {
          console.log(err);
          alert("Upload failed!");
        }
      );
  }

  return (
    <Paragraph>
      <Dragger {...DraggerProps}>
        <Paragraph className="ant-upload-text">Click or drag file to this area to upload</Paragraph>
        <Paragraph className="ant-upload-drag-icon">
          <InboxOutlined />
        </Paragraph>
      </Dragger>
      <Button type="default" onClick={handleUpload}>
        Upload
      </Button>
    </Paragraph>
  );
}

export default DocumentUploader;
