import React, { FC, useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  message,
  Collapse,
  Spin,
  Typography,
  Space,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client/react";
import PropTypes, { InferProps } from "prop-types";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  ApplicantQualificationFGraduationDocument,
  ApplicantQualificationFApplicantPortal_Pathway345Document,
  Pathway345,
} from "../../generated";
const { Title, Paragraph, Text, Link } = Typography;
const { Panel } = Collapse;

const ComponentPropTypes = {
  pathway345: PropTypes.shape({
    userResponse: PropTypes.shape({
      isGradSchoolEligible: PropTypes.bool,
      isGradDateInAcceptableRange: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        data: PropTypes.shape({
          gradDate: PropTypes.string,
          schoolRef: PropTypes.shape({
            _id: PropTypes.number,
            name: PropTypes.string,
            countryName: PropTypes.string,
          }),
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
  refetch: PropTypes.func.isRequired,
};

export interface IProps {
  __typename?: "QualificationF";
  pathway345: Pathway345;
  refetch: () => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const QualificationF: FC<ComponentProps> = ({ pathway345, refetch }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [pathwayBreakoutScreen, setPathwayBreakoutScreen] =
    useState<string>("");
  const [graduationDetails, setGraduationDetails] = useState<any>(undefined);

  const {
    loading: loadingGraduation,
    error: errorGraduation,
    data: graduationData,
  } = useQuery(ApplicantQualificationFGraduationDocument);

  const [UpdateEducation] = useMutation(
    ApplicantQualificationFApplicantPortal_Pathway345Document
  );

  const BREAKOUT_OPTIONS = {
    PATHWAY6_INVALID_SCHOOL: "PATHWAY6_INVALID_SCHOOL",
    PATHWAY6_INVALID_YEAR: "PATHWAY6_INVALID_YEAR",
    PATHWAY345: "PATHWAY345",
  };

  //update UI
  useEffect(() => {
    //Handles if back button is pushed. Reload the page
    if (navigationType === "POP") {
      navigate(location.pathname);
      refetch();
    }
  }, [pathway345]);

  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of screen on page load
  }, []);

  useEffect(() => {
    //Set which screens to show depending on school pathway and eligble year
    setGraduationDetails(graduationData?.applicantPortal?.graduation);

    if (
      graduationData?.applicantPortal?.graduation?.schoolEligibility
        ?.schoolPathway === "PATHWAY3" ||
      graduationData?.applicantPortal?.graduation?.schoolEligibility
        ?.schoolPathway === "PATHWAY4" ||
      graduationData?.applicantPortal?.graduation?.schoolEligibility
        ?.schoolPathway === "PATHWAY5"
    ) {
      if (graduationData?.applicantPortal?.graduation?.isYearEligible) {
        setPathwayBreakoutScreen(BREAKOUT_OPTIONS.PATHWAY345);
      } else {
        setPathwayBreakoutScreen(BREAKOUT_OPTIONS.PATHWAY6_INVALID_YEAR);
      }
    } else {
      setPathwayBreakoutScreen(BREAKOUT_OPTIONS.PATHWAY6_INVALID_SCHOOL);
    }
  }, [graduationData]);

  const onFinishPathway345 = async () => {
    return UpdateEducation()
      .then((data) => {
        navigate(`/Qualification-review`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference =
          error?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${error.toString()} - Reference ID : ${graphQLErrorReference}`
        );
      });
  };

  const onFinishPathway6 = async () => {
    return UpdateEducation()
      .then((data) => {
        navigate(`/Qualification6`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference =
          error?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${error.toString()} - Reference ID : ${graphQLErrorReference}`
        );
      });
  };

  const convertPathwayNumber = (pathway: any) => {
    switch (pathway) {
      case "PATHWAY3":
        return "3";
      case "PATHWAY4":
        return "4";
      case "PATHWAY5":
        return "5";
      default:
        return "";
    }
  };

  const QualificationText = () => (
    <>
      {pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY345 ? (
        <>
          <Paragraph>
            According to our records, your medical school,{" "}
            {graduationDetails?.school}, meets the eligibility requirements for
            Pathway{" "}
            {convertPathwayNumber(
              graduationDetails?.schoolEligibility?.schoolPathway
            )},
            and your graduation date is on or after January 1, 2023. Therefore,
            you may apply to Pathway{" "}
            {convertPathwayNumber(
              graduationDetails?.schoolEligibility?.schoolPathway
            )}
            .
          </Paragraph>
          <Paragraph>
            In order to complete your application, your medical school must
            attest to your clinical skills. Instructions for meeting this
            requirement will be available once you have submitted your
            application.
          </Paragraph>
        </>
      ) : pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY6_INVALID_YEAR ? (
        <>
          <Paragraph>
            According to our records, your medical school,{" "}
            {graduationDetails?.school}, meets the eligibility requirements for
            Pathway{" "}
            {convertPathwayNumber(
              graduationDetails?.schoolEligibility?.schoolPathway
            )}
            ; however, you graduated prior to January 1, 2023. Therefore, you
            are not eligible to apply to Pathway{" "}
            {convertPathwayNumber(
              graduationDetails?.schoolEligibility?.schoolPathway
            )}
            . If you believe this information is incorrect, please{" "}
            <Link
              href="https://www.ecfmg.org/contact.html"
              target="_blank"
              rel="noreferrer"
            >
              contact us
            </Link>
            .
          </Paragraph>

          <Paragraph>
            In order to meet the clinical and communication skills requirements
            for ECFMG Certification, you are <Text strong>required</Text> to
            apply to Pathway 6: Evaluation of Clinical Patient Encounters by
            Licensed Physicians. Before proceeding with this application, you
            must review the{" "}
            <Link
              href="https://www.ecfmg.org/certification-pathways/pathway-6.html"
              target="_blank"
              rel="noreferrer"
            >
              eligibility requirements and application information for Pathway 6
            </Link>
            .
          </Paragraph>
        </>
      ) : (
        <>
          <Paragraph>
            According to our records, your medical school,{" "}
            {graduationDetails?.school}, does not meet the eligibility
            requirements for Pathway 3, 4, or 5. If you believe this information
            is incorrect, please{" "}
            <Link
              href="https://www.ecfmg.org/contact.html"
              target="_blank"
              rel="noreferrer"
            >
              contact us
            </Link>
            .
          </Paragraph>

          <Paragraph>
            In order to meet the clinical and communication skills requirements
            for ECFMG Certification, you are <Text strong>required</Text> to
            apply to Pathway 6: Evaluation of Clinical Patient Encounters by
            Licensed Physicians. Before proceeding with this application, you
            must review the{" "}
            <Link
              href="https://www.ecfmg.org/certification-pathways/pathway-6.html"
              target="_blank"
              rel="noreferrer"
            >
              eligibility requirements and application information for Pathway 6
            </Link>
            .
          </Paragraph>
        </>
      )}
    </>
  );

  if (loadingGraduation) {
    return <Spin></Spin>;
  }

  return (
    <>
      <Helmet>
        <title>Pathway 3-5</title>
      </Helmet>
      <Row>
        <Col span={24}>
          <Title level={4}>
            Pathway 3-5: Medical School Meets Eligibility Requirements
          </Title>
        </Col>
      </Row>
      <Collapse ghost>
        <Panel
          header={
            <Space>
              <InfoCircleOutlined /> Additional Information
            </Space>
          }
          key="instructions"
        >
          <Paragraph
            style={{
              textAlign: "initial",
              fontSize: "14px",
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <Paragraph>
              Pathway 3: Medical School Accredited by Agency Recognized by World
              Federation for Medical Education (WFME)
            </Paragraph>

            <Paragraph>
              Pathway 4: Medical School Accredited by Agency that Has Received a
              Determination of Comparability by National Committee on Foreign
              Medical Education and Accreditation (NCFMEA)
            </Paragraph>

            <Paragraph>
              Pathway 5: Medical School Issues Degree Jointly with a U.S.
              Medical School Accredited by Liaison Committee on Medical
              Education (LCME)
            </Paragraph>
          </Paragraph>
        </Panel>
      </Collapse>
      <QualificationText />
      <Row>
        <Col style={{ textAlign: "right" }} span={24}>
          {pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY345 ? (
            <Button
              size='large'
              id="qf-continue-pw345-button"
              type="primary"
              onClick={() => {
                onFinishPathway345();
              }}
            >
              Continue
            </Button>
          ) : pathwayBreakoutScreen ===
              BREAKOUT_OPTIONS.PATHWAY6_INVALID_SCHOOL ||
            pathwayBreakoutScreen === BREAKOUT_OPTIONS.PATHWAY6_INVALID_YEAR ? (
            <Button
              size='large'
              id="qf-continue-pw6-button"
              type="primary"
              onClick={() => {
                onFinishPathway6();
              }}
            >
              Continue
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default {
  Component: QualificationF,
};
