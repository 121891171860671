import React, { FC, useState, useEffect } from "react";
import { Button, Table, List, Card, Modal, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import moment from "moment";
import {
  PHYSICIAN_ENC_ALLOWED_ACTIONS,
  CEX_STATUS,
  CEX_STATUS_DETAIL,
  PATHWAY6_DATE_FORMAT,
  getPhysicianName,
} from "../constants";
import _ from "lodash";
import { A_CaseDetails } from "../../generated";
import { getDaysUntilExpiration } from "../constants";
import { PhysicianStatusHistoryModal } from "./physician-status-history-modal";
const { Title, Paragraph, Text } = Typography;

const ComponentPropTypes = {
  minicex: PropTypes.arrayOf(
    PropTypes.shape({
      dataVersion: PropTypes.number.isRequired,
      encounterId: PropTypes.string.isRequired,
      physicianEmail: PropTypes.string.isRequired,
      physicianLastNameProvidedByApplicant: PropTypes.string.isRequired,
      physicianRestOfNameProvidedByApplicant: PropTypes.string.isRequired,
      physicianLastNameProvidedByPhysician: PropTypes.string.isRequired,
      physicianRestOfNameProvidedByPhysician: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      expiresAt: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      statusDetail: PropTypes.string.isRequired,
      stateModel: PropTypes.arrayOf(
        PropTypes.shape({
          state: PropTypes.string.isRequired,
          timestamp: PropTypes.string.isRequired,
        })
      ),
      allowedActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    })
  ),
  caseDetails: PropTypes.shape({
    output: PropTypes.string,
  }),
  addCEXEncounter: PropTypes.func.isRequired,
  removeCEXEncounter: PropTypes.func.isRequired,
};

export interface IProps {
  __typename?: "PhysicianEncounterListCard";
  minicex?: {
    dataVersion: number;
    encounterId: string;
    physicianEmail: string;
    physicianLastNameProvidedByApplicant: string;
    physicianRestOfNameProvidedByApplicant: string;
    physicianLastNameProvidedByPhysician: string;
    physicianRestOfNameProvidedByPhysician: string;
    createdAt: string;
    expiresAt: string;
    state: string;
    statusDetail: string;
    stateModel?: {
      state: string;
      timestamp: string;
    }[];
    allowedActions: string[];
  }[];
  caseDetails?: A_CaseDetails;
  addCEXEncounter: () => void;
  removeCEXEncounter: (index: any) => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;
const PhysicianEncounterListCard: FC<ComponentProps> = ({
  minicex,
  caseDetails,
  addCEXEncounter,
  removeCEXEncounter,
}) => {
  const [showEncounterHistoryModal, setShowEncounterHistoryModal] =
    useState<boolean>(false);
  const [statusHistory, setStatusHistory] = useState<any[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [listData, setListData] = useState<any>([]);

  useEffect(() => {
    if (minicex && minicex.length > 0) {
      let list: any = [];
      let index: number = 0;
      minicex?.map((record: any) => {
        list.push({
          title: `${record.encounterId}`,
          index: index++,
          record,
        });
      });
      setListData(list);
    }
  }, [minicex]);

  const renderButtons = (item: any) => {
    if (
      item?.record?.allowedActions.includes(
        PHYSICIAN_ENC_ALLOWED_ACTIONS.REMOVE
      ) &&
      caseDetails?.state !== "ApplicationCompleted"
    ) {
      return (
        <Paragraph>
          <Button
            style={{ width: "80px" }}
            type="primary"
            danger
            onClick={() => removeCEXEncounter(item?.index)}
          >
            Remove
          </Button>
        </Paragraph>
      );
    } else if (
      item?.record?.allowedActions.includes(PHYSICIAN_ENC_ALLOWED_ACTIONS.ADD)
    ) {
      return (
        <Paragraph>
          <Button
            type="primary"
            style={{ width: "80px" }}
            onClick={() => addCEXEncounter()}
          >
            Add
          </Button>
        </Paragraph>
      );
    } else {
      return <Paragraph></Paragraph>;
    }
  };

  const statusHistoryColumns = [
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (state: any, record: any) => (
        <Paragraph>
          {record.state !== undefined ? (
            <Paragraph>{getStatus(CEX_STATUS, record.state) + " "}</Paragraph>
          ) : (
            <Paragraph></Paragraph>
          )}
        </Paragraph>
      ),
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: any, record: any) => (
        <Paragraph>
          {record.timestamp !== undefined ? (
            <Paragraph>
              {moment(record.timestamp).utc().format("DD-MMM-YYYY, h:mm:ss a")}
            </Paragraph>
          ) : (
            <Paragraph></Paragraph>
          )}
        </Paragraph>
      ),
    },
  ];

  const showEncounterHistory = (record: any) => {
    setSelectedRecord(record);
    //Don't show pending invite statuses in status history
    var filteredStateModel = record.stateModel.filter(
      (item: { state: string }) =>
        item.state !== "MINICEX_CREATED_PHYSICIAN_INVITE_PENDING"
    );
    setStatusHistory(filteredStateModel);
    setShowEncounterHistoryModal(true);
  };

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  const getStatusDetail = (obj: any, record: any) => {
    switch (record.state) {
      case "MINICEX_CREATED_PHYSICIAN_INVITE_SENT":
      case "MINICEX_CREATED_PHYSICIAN_REGISTERED": {
        //Calculate difference in days from now to expiration
        var timeDifferenceInDays = moment(record.expiresAt)
          .utc()
          .diff(moment().utc().toDate().getTime(), "days");

        var textWithInviteDate = obj[record.state].replace(
          "*InviteDate*",
          moment(record.createdAt).utc().format(PATHWAY6_DATE_FORMAT)
        );

        if (timeDifferenceInDays <= 0) {
          return textWithInviteDate.replace("*NumberOfDays*", "less than one");
        } else {
          return textWithInviteDate.replace(
            "*NumberOfDays*",
            timeDifferenceInDays
          );
        }
      }
      case "MINICEX_ACCEPTED": {
        var expiresAtInDays = getDaysUntilExpiration(record.expiresAt);

        if (expiresAtInDays <= 0) {
          return obj[record.state].replace("*NumberOfDays*", "less than one");
        } else {
          return obj[record.state].replace("*NumberOfDays*", expiresAtInDays);
        }
      }
      default: {
        return obj[record.state];
      }
    }
  };

  return (
    <>
      <List
        dataSource={listData}
        itemLayout="vertical"
        pagination={{ pageSize: 10 }}
        renderItem={(item: any) => (
          <List.Item key={item.title}>
            <Card title={null}>
              <Paragraph>
                <Text>ID:</Text> <Text strong>{item?.record?.encounterId}</Text>
              </Paragraph>
              <Paragraph>
                <Text>Name from Applicant: </Text>
                <Text strong>
                  {getPhysicianName(
                    item?.record?.physicianLastNameProvidedByApplicant,
                    item?.record?.physicianRestOfNameProvidedByApplicant
                  )}
                </Text>
              </Paragraph>
              <Paragraph>
                <Text>Name from Physician: </Text>
                <Text strong>
                  {getPhysicianName(
                    item?.record?.physicianLastNameProvidedByPhysician,
                    item?.record?.physicianRestOfNameProvidedByPhysician
                  )}
                </Text>
              </Paragraph>
              <Paragraph>
                <Text>E-mail: </Text>{" "}
                <Text strong>{item?.record?.physicianEmail}</Text>
              </Paragraph>
              <Paragraph>
                <Text>Date Requested: </Text>{" "}
                {item?.record?.createdAt !== undefined ? (
                  <Text strong>
                    {moment(item?.record?.createdAt)
                      .utc()
                      .format("DD-MMM-YYYY")}
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </Paragraph>
              <Paragraph>
                <Text>Status: </Text>{" "}
                {item?.record?.state !== undefined ? (
                  <Text strong>
                    {getStatus(CEX_STATUS, item?.record?.state) + " "}
                    <InfoCircleOutlined
                      style={{ fontSize: "18px" }}
                      onClick={() => showEncounterHistory(item?.record)}
                    />
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </Paragraph>
              <Paragraph>
                <Text>Status Detail: </Text>{" "}
                {item?.record?.state !== undefined ? (
                  <Text strong>
                    {getStatusDetail(CEX_STATUS_DETAIL, item?.record)}
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </Paragraph>
              <br />
              {caseDetails?.output?.toUpperCase() === "REJECTED" ||
              caseDetails?.output?.toUpperCase() === "APPROVED"
                ? null
                : renderButtons(item)}
            </Card>
          </List.Item>
        )}
      ></List>

      <PhysicianStatusHistoryModal
        selectedRecord={selectedRecord}
        setShowEncounterHistoryModal={setShowEncounterHistoryModal}
        showEncounterHistoryModal={showEncounterHistoryModal}
        statusHistory={statusHistory}
        statusHistoryColumns={statusHistoryColumns}
      />

      {/* <Modal
        title={"Status History"}
        visible={showEncounterHistoryModal}
        closable={false}
        footer={[
          <Button
            size="large"
            key="close"
            onClick={() => setShowEncounterHistoryModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <Paragraph>
          <Text strong>Physician:</Text>{" "}
          {getPhysicianName(
            selectedRecord?.physicianLastNameProvidedByApplicant,
            selectedRecord?.physicianRestOfNameProvidedByApplicant
          )}
          <br />
          <Text strong>Encounter ID: </Text>
          {selectedRecord?.encounterId}
          <br />
          <br />
          <Table
            columns={statusHistoryColumns}
            dataSource={statusHistory}
          ></Table>
          <Paragraph>
            All times are calculated using Coordinated Universal Time (UTC).
          </Paragraph>
        </Paragraph>
      </Modal> */}
    </>
  );
};
export default PhysicianEncounterListCard;
