import { UiPortalConfigType } from "../../generated";
import { COMPONENT_NAME, PAGE_NAME } from "../version-mappings";
import { REJECTION_REASONS } from "./versions/rejection-reasons-v1";
import { REJECTION_REASONS_V2 } from "./versions/rejection-reasons-v2";
import { REJECTION_REASONS_V3 } from "./versions/rejection-reasons-v3";
import { REJECTION_REASONS_V4 } from "./versions/rejection-reasons-v4"; 
import { REJECTION_REASONS_V5 } from "./versions/rejection-reasons-v5"; 

const RejectionReasonsFactory = (uiConfig: UiPortalConfigType) => {
  var returnValue;

  if (uiConfig) {
    uiConfig?.config?.pages?.map((page) => {
      if (page?.pageName === PAGE_NAME.APPLICANT_REJECTION_REASONS) {
        page?.components?.map((component) => {
          if (
            component?.componentName ===
            COMPONENT_NAME.APPLICANT_REJECTION_REASONS
          ) {
            switch (component.version) {
              case 1:
                returnValue = REJECTION_REASONS;
                break;
              case 2:
                returnValue = REJECTION_REASONS_V2;
                break;
              case 3:
                returnValue = REJECTION_REASONS_V3;
                break;
              case 4:
                returnValue = REJECTION_REASONS_V4;
                break;
              case 5:
              default:
                returnValue = REJECTION_REASONS_V5;
                break;
            }
          }
        });
      }
    });
  } else {
    //if data fails to load, return te latest version as default
    return REJECTION_REASONS_V5;
  }

  return returnValue;
};

export default RejectionReasonsFactory;
